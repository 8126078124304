import INewClientRegistration from "../../../models/client/newRegisterAgent/INewClientRegistration";
import Total from "./total/Total";
import TermsOfService from "./termsOfService/TermsOfService";
import SelectedBusinessAddressService from "./selectedBusinessAddressService/SelectedBusinessAddressServices";
import SelectedRegistrationOptionalServicesProps from "./selectedRegistrationOptionalServices/SelectedRegistrationOptionalServices";
import { useState } from "react";
import { _postClientInformation } from "../../../APIs/client/ClientApi";
import { _mapToNewClientPayload } from "../../../services/client/ClientServices";
import "./orderSummary.scss"
import SelectedchangingRegisteredAgents from "./selectedchangingRegisteredAgents/SelectedchangingRegisteredAgents";
import Coupon from "./coupon/Coupon";

interface IOrderSummaryProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    changeStep: Function
    submitDisabled: boolean
    errorMessage: string
    showSuccessMessage:boolean
    getAffiniPayToken:() => Promise<void>
    affiniPayGeneratingTokenError:string
    affiniPayCvvError:string
    affiniPayCardError:string
    areTouchedFieldsWithErrors:boolean
    areRequiredFieldsUndefined:boolean
    hasDifferentBillingErrors : boolean
}

const OrderSummary = (orderSummaryProps: IOrderSummaryProps) => {
    const handleStateClick = (goTo:string) => {orderSummaryProps.changeStep(goTo)}
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [showModalTermsFiles, setShowModalTermsFiles] = useState<boolean>(false);
    const successMessage = "Your order was successfully processed, enjoy our services!"
    return (
        <div className="form">
            <div className="form__title">Order Summary</div>  
            <form>
                <SelectedchangingRegisteredAgents newClientRegistration={orderSummaryProps.newClientRegistration} />
                <SelectedBusinessAddressService newClientRegistration={orderSummaryProps.newClientRegistration} />
                <SelectedRegistrationOptionalServicesProps newClientRegistration={orderSummaryProps.newClientRegistration} />
                <Coupon totalAmount={totalAmount} setNewClientRegistration={orderSummaryProps.setNewClientRegistration} setTotalAmount={setTotalAmount} newClientRegistration={orderSummaryProps.newClientRegistration}/>
                <Total totalAmount={totalAmount} setTotalAmount={setTotalAmount} newClientRegistration={orderSummaryProps.newClientRegistration}/>
                <TermsOfService setShowModalTermsFiles={setShowModalTermsFiles}/>
            </form>
            <div className="submitStageButtons border-top pt-1rem">
                <button
                    type="button"
                    className={`primaryButton w-30 h-37${
                        orderSummaryProps.submitDisabled ||  
                        orderSummaryProps.areRequiredFieldsUndefined ||
                        orderSummaryProps.areTouchedFieldsWithErrors ||
                        orderSummaryProps.affiniPayCardError.length > 0 ||
                        orderSummaryProps.affiniPayCvvError.length > 0 ||
                        orderSummaryProps.affiniPayGeneratingTokenError.length > 0 ||
                        orderSummaryProps.hasDifferentBillingErrors}
                        ? 'disabled' : ''}`
                    }
                    onClick={()=>{orderSummaryProps.getAffiniPayToken()}}
                    disabled={
                        orderSummaryProps.submitDisabled||
                        orderSummaryProps.areRequiredFieldsUndefined ||
                        orderSummaryProps.areTouchedFieldsWithErrors ||
                        orderSummaryProps.affiniPayCardError.length > 0 ||
                        orderSummaryProps.affiniPayCvvError.length > 0 ||
                        orderSummaryProps.affiniPayGeneratingTokenError.length > 0 ||
                        orderSummaryProps.hasDifferentBillingErrors
                    } 
                >
                    Submit
                </button>
                {orderSummaryProps.showSuccessMessage && (
                    <div className="successMessage">{successMessage}</div>
                )}     
                <div className="errorMessage"> {orderSummaryProps.errorMessage}</div>
                <p className="errorMessage">{orderSummaryProps.affiniPayGeneratingTokenError}</p>
                <button type="button"  className={`greyButton  w-30 h-37${orderSummaryProps.submitDisabled ? ' disabled' : ''  } `} 
                    disabled={orderSummaryProps.submitDisabled }  
                    onClick={()=>{handleStateClick("back")}}>Back</button>       
            </div>  
            <div className={`modal fade ${showModalTermsFiles ? 'show' : ''}`} id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showModalTermsFiles ? 'block' : 'none' }}>
                    <div className="modal-content mh-100 modal-dialog modal-fullscreen">
                        <div className="modal-body ">
                            <iframe
                                title="PDF Viewer"
                                src="/termsAndServices.pdf"
                                width="800"
                                height="600"
                            ></iframe>            
                        </div>        
                        <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>setShowModalTermsFiles(false)}>Close</button>
                        </div>
                    </div>
            </div>
        </div>
    );
};
export default OrderSummary;


