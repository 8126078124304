import axios from "axios";
import { IClientDocumentDto } from "../../models/clientDocument/IClientDocumentDto";

const baseUrl = "client/document"

async function _getClientDocuments(): Promise<IClientDocumentDto[]> {
  let response = await axios.get(`${baseUrl}`)
  return response.data;
}

async function _searchClientDocuments(companyName:string): Promise<IClientDocumentDto[]> {
  let response = await axios.get(`${baseUrl}/search?companyName=`+companyName)
  return response.data;
}

export  { _getClientDocuments,_searchClientDocuments,};