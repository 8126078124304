import { Navigate } from 'react-router-dom'
import SessionUser from '../../models/user/SessionUser';
import { _getSession, _getSessionUser } from '../../services/session/SessionService';

type ProviderSuperUserRoutesProps = {
  children: JSX.Element;
  rolesAllowed: string[];
};

const RoleRoutes = ({ children, rolesAllowed }: ProviderSuperUserRoutesProps) => {
  let session = _getSession();
  if(session === null) {
    return <Navigate to='/login'/>;
  }
  let user: SessionUser = _getSessionUser(session!);

  let isRoleAllowed = false;
  if(rolesAllowed === undefined){
    isRoleAllowed = true;
  }
  else if(user.role !== undefined && user.role !== null) // in this condition rolesAllowed is defined as well ( it didn't fall in previous if)
  {
    isRoleAllowed = rolesAllowed.includes(user.role);
  }

  return isRoleAllowed ?  children : <Navigate to='../home'/>;
};
export default RoleRoutes;
