import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface IFirstNameProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const FirstName = (firstNameProps: IFirstNameProps) => {
    const maxLength = 50;

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = firstNameProps.signUpErrors.contactDetailsErrors.firstName.getValidationErrors(e.target.value);
        setError(error);
    }

    const setError = (error:string) => {
        firstNameProps.setSignUpErrors(prevState => ({
            ...prevState,
            contactDetailsErrors: {
                ...prevState.contactDetailsErrors,
                firstName: {
                    ...prevState.contactDetailsErrors.firstName,
                    message: error
                }
            }
        }));
    }

    const handleOnChange = (e: React.FocusEvent<HTMLInputElement>) => {
            if(e.target.value.length<=maxLength){
                firstNameProps.setNewClientRegistration({
                    ...firstNameProps.newClientRegistration,
                    contactDetails: {
                        ...firstNameProps.newClientRegistration.contactDetails,
                        firstName: e.target.value
                    }
                });
                setError('');
            }
    }
    
    return (
        <div className="mb-3 twoInputInOneRow-input">
            <input 
                type="text"
                value={firstNameProps.newClientRegistration.contactDetails.firstName}
                className={`form-control ${firstNameProps.signUpErrors.contactDetailsErrors.firstName.message ? 'input-error' : ''}`} 
                placeholder="First Name"
                maxLength={maxLength}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
            />  
            <p className="errorMessage">{firstNameProps.signUpErrors.contactDetailsErrors.firstName.message} </p> 
        </div>
    );
};
export default FirstName;