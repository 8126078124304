import IPaymentInformation from "../../../../../../models/client/newRegisterAgent/paymentInformation/IPaymentInformation";
import { IPaymentInformationError } from "../../../../../signUp/paymenyInformation/validation/IPaymentInformationError";

interface AddonPostalCodeProps {
    addonsErrors: IPaymentInformationError;
    setAddonsErrors: React.Dispatch<React.SetStateAction<IPaymentInformationError>>;
    setPaymentInformation:React.Dispatch<React.SetStateAction<IPaymentInformation>>
    paymentInformation:IPaymentInformation
}

const AddonPostalCode = (addonPostalCodeProps:AddonPostalCodeProps) => {
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        var error = addonPostalCodeProps.addonsErrors.postalCode.getValidationErrors(e.target.value);
        addonPostalCodeProps.setAddonsErrors(prevState => ({
                    ...prevState,
                    postalCode: {
                        ...prevState.postalCode,
                        message: error
                    }
                }));
        }
        const setError = (error: string) => {
            addonPostalCodeProps.setAddonsErrors(prevState => ({
                ...prevState,
                paymentInformationError: {
                    ...prevState,
                    postalCode: {
                        ...prevState.postalCode,
                        message: error
                    }
                }
            }));
        }

    return (
        <div className="twoInputInOneRow-input addon-heigth">
                <input 
                    value={addonPostalCodeProps.paymentInformation.postalCode}
                    type="text" 
                    className={`addon-form-control ${addonPostalCodeProps.addonsErrors.postalCode.message ? 'input-error' : ''}`} 
                    placeholder="Postal Code" 
                    maxLength={50}
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                        addonPostalCodeProps.setPaymentInformation({
                                ...addonPostalCodeProps.paymentInformation,
                                postalCode: e.target.value
                    });
                    setError("");
                }}/>   
            <div className="addon-errorMessage">{addonPostalCodeProps.addonsErrors.postalCode.message} </div> 
        </div>
    );
};
export default AddonPostalCode;