import { Outlet } from "react-router-dom";
import './provider.scss';
import ProvidersHeader from "./ProvidersHeader";
import { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContextProvider";

const Provider = () => {
  const { isSidebarOpen } = useContext(SidebarContext); 

  return (
    <div className={` ${isSidebarOpen ? "provider provider__close" : "provider" }`}>
        <ProvidersHeader/>
        <Outlet/>
    </div>
  )
};

export default Provider;
