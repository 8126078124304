import { _getDocument } from "../../APIs/documents/Documents";

class RejectedResponseType {
  id: number;
  details: string;
  constructor(
    id: number = 0,
    details: string = ''
  ){
    this.id = id;
    this.details = details;
  }
}

class DetailedResponse {
  details: string;
  constructor(
    details: string = ''
  ){
    this.details = details;
  }
}

interface ViewDocumentResponse {
  id: number;
  urlFile: string;
}

async function _downloadSuccesfullAndRejectedResults(ids: number[]): Promise<{succesfullyDownloadIds: number[], failedDownloadResponses: RejectedResponseType[]}> {
  const downloadDocumentPromises = ids.map(id => _downloadDocument(id));
  var result = await Promise.allSettled(downloadDocumentPromises)
  let fulfilledResults = result.filter(promise => promise.status === 'fulfilled');
  let rejectedResults = result.filter(promise => promise.status === 'rejected');
  let succesfullyDownloadIds = fulfilledResults.map(promiseSettledResult => { 
    return(promiseSettledResult as PromiseFulfilledResult<number>).value; 
  });
  let failedDownloadResponses = rejectedResults.map(promiseSettledResult => { 
    return (promiseSettledResult as PromiseRejectedResult).reason as RejectedResponseType
  });
  return { succesfullyDownloadIds, failedDownloadResponses };
}

//#region application required code
async function _downloadDocument(id: number): Promise<number | RejectedResponseType> {
  try {
    var response = await _getDocument(id, false);
    var contentDispositionHeader = response.request.getResponseHeader('Content-Disposition');
    var filename = getFileName(contentDispositionHeader);
    createLinkAndClickForDownloadOrView(response.data, filename, false, response.data.type);
    return Promise.resolve(id);
  }
  catch (ex) {
    let blobResponse = ex as Blob;
    let rejectedResponse = await handleGetDocumentException(blobResponse, id);
    return rejectedResponse;
  }
}

async function _viewDocument(id: number): Promise<ViewDocumentResponse | RejectedResponseType> {
  try {
    var response = await _getDocument(id, true);
    const urlFile= getUrlLink(response.data, response.data.type)
    const result: ViewDocumentResponse = { id, urlFile};
    return result;
  }
  catch (ex) {
    const blobResponse = ex as Blob;
    let blobText = await blobResponse.text();
    let serializedResult = JSON.parse(blobText) as DetailedResponse;
    var rejectedResponse = new RejectedResponseType(id, serializedResult.details);
    return rejectedResponse;
  }
}

const getUrlLink = (data: Blob, dataType:string) => {
  const blob = new Blob([data], { type: dataType});
  const url = window.URL.createObjectURL(blob); 
  return url
}

const getFileName = (contentDispositionHeader: string): string => {
  return contentDispositionHeader.split("filename=")[1];
};

// temporary approach: create an anchor a element <a>, mount the content on it, and click it to trigger download and open in a new browser;
const createLinkAndClickForDownloadOrView = (data: Blob, filename: string, newTab: boolean = true, dataType:string) => {
  const urlFile= getUrlLink(data, dataType)
  const aElement = document.createElement('a');
  newTab? null: aElement.setAttribute('download', filename)
  let target = newTab ? "_blank" : "";
  aElement.setAttribute('target', target);
  aElement.setAttribute('rel', 'noopener noreferrer');
  aElement.href = urlFile;
  aElement.click();
  window.URL.revokeObjectURL(urlFile);
}

export { _downloadDocument, _viewDocument , _downloadSuccesfullAndRejectedResults, RejectedResponseType }

async function handleGetDocumentException(blobResponse: Blob, id: number) {
  let blobText = await blobResponse.text();
  let serializedResult = JSON.parse(blobText) as DetailedResponse;
  var rejectedResponse = new RejectedResponseType(id, serializedResult.details);
  return Promise.reject(rejectedResponse);
}