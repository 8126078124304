import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";

interface SelectedBusinessAddressServiceProps {
  newClientRegistration:INewClientRegistration
}

const SelectedchangingRegisteredAgents = (selectedBusinessAddressService :SelectedBusinessAddressServiceProps) => {
    return (
        <div >
        {selectedBusinessAddressService.newClientRegistration.companyInformation.changingRegisteredAgents === true? (
          <div className="mb-3 selectedServices">
            <label>{selectedBusinessAddressService.newClientRegistration.companyInformation.changingRegisteredAgentsSubscription.name}</label>
            <b>${selectedBusinessAddressService.newClientRegistration.companyInformation.changingRegisteredAgentsSubscription.amount}</b>
          </div>
        ) : null}
      </div>
    );
};
export default SelectedchangingRegisteredAgents;