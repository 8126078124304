import axios from "axios";
import ILoginUser from "../../models/user/ILoginUser";
import IAuthenticatedUserResponse from './IAuthenticatedUserResponse';
import jwt_decode from "jwt-decode";
import SessionUser from "../../models/user/SessionUser";

const baseUrl: string = "authentication";

class Credentials {
    constructor(email:string, password:string){
        this.email = email;
        this.password = password;
    }
    email: string;
    password: string;
}
const _login = async (email: string, password:  string): Promise<IAuthenticatedUserResponse> => {
    let credentials = new Credentials(email, password);
    let response = await axios.post(`${baseUrl}/login`, credentials);
    return response.data;
}

const _getLoginUser = (token: string): ILoginUser => {
    return jwt_decode<ILoginUser>(token);
}

const _getSessionUserFromLoginUser = (loginUser: ILoginUser) : SessionUser => {
    return new SessionUser(loginUser.id, loginUser.email, loginUser.clientId, loginUser.agentId, loginUser.role, loginUser.companyName, loginUser.firstName, loginUser.lastName, loginUser.exp, loginUser.iss, loginUser.aud );
}

const _getUserType = (user: ILoginUser): string => {
    let userType: string = "undefined";
    if(user.clientId.length>0) {
        userType = "client";
    }else if( user.agentId.length>0){
        userType = "agent";
    }
    return userType
}

export { _login, _getLoginUser, _getUserType, _getSessionUserFromLoginUser };
