import { IClientDto } from "../../../../../models/client/newRegisterAgent/IClientDto";

interface IServicesCardProps {
    clienSelectedInformation: IClientDto;
}

const ServicesCard = (servicesCardProps:IServicesCardProps) => {

    const handleClick = (serviceId: number) => {
    };  

    return (
        <div className="servicesCard table-responsive-md">
        <div className="cardTitles">
            <div className="cardTitles__title">Services</div>
            {/* <button className="primaryButton basic-font h-10">View All</button> */}
        </div>
        <table className="table align-middle">
           
            <tbody >
                {servicesCardProps.clienSelectedInformation.clientServices.map((service)=> 
                <tr 
                    key={service.id}
                >
                    <td>{service.service.name}</td>
                    <td>
                        <span className={ service.service.active ? 'greenButton' : 'redButton' }>
                            {service.service.active ? 'active' : 'inactive'}
                        </span>
                    </td>                        
                    <td className="link-td" onClick={() => handleClick(service.id)}>
                        View Details
                    </td>    
                </tr>)}
            </tbody>
        </table>
    </div>        
    );
};
export default ServicesCard;
