import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";

interface ISelectedRegistrationOptionalServicesProps {
    newClientRegistration:INewClientRegistration
}

const SelectedRegistrationOptionalServicesProps = (selectedRegistrationOptionalServicesProps :ISelectedRegistrationOptionalServicesProps) => {

    return (
        <div>
            {selectedRegistrationOptionalServicesProps.newClientRegistration.optionalRegistrationServices.serviceSubscriptions.map((selectedOptionalRegistrationService) => (
                    <div key={selectedOptionalRegistrationService.servicePackageId} className="mb-3 selectedServices">
                        <label>{selectedOptionalRegistrationService.serviceName}</label>
                        <b>${selectedOptionalRegistrationService.servicePackageAmount}</b>
                    </div>
                ))}
        </div>
    );
};
export default SelectedRegistrationOptionalServicesProps;