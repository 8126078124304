import INewClientRegistration from "../../../models/client/newRegisterAgent/INewClientRegistration";
import City from "./city/City";
import FirstName from "./firstName/FirstName";
import LastName from "./lastName/LastName";
import Phone from "./phone/Phone";
import Country from "./country/Country";
import ZipAndPostal from "./zip/postal/ZipAndPostal";
import StateProvince from "./stateProvince/StateProvince";
import { IContactDetailsErrors } from "./validations/IContactDetailsErrors";
import { useEffect, useState } from "react";
import { ISignUpErrors } from "../validation/ISignUpErrors";
import IContactDetails from "../../../models/client/newRegisterAgent/contactDetails/IContactDetails";
import Address from "./address/Adress";
import { ICountryDto } from "../../../models/country/ICountryDto";

interface ContactDetailsProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    changeStep:Function
    countries: ICountryDto[]
    signUpErrors: ISignUpErrors
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>
}

const ContactDetails = (contactDetailsProps: ContactDetailsProps) => { 
    const handleStateClick = (goTo:string) => {
        contactDetailsProps.changeStep(goTo)
    }
    const [areRequiredFieldsUndefined, setAreRequiredFieldsUndefined] = useState<boolean>(true);
    const [areTouchedFieldsWithErrors, setAreTouchedFieldsWithErrors] = useState<boolean>(true);

    useEffect(() => {
        let areRequiredFieldsUndefined  = evaluateRequireUndefinedFields(contactDetailsProps.signUpErrors.contactDetailsErrors, contactDetailsProps.newClientRegistration.contactDetails)
        setAreRequiredFieldsUndefined(areRequiredFieldsUndefined)
    }, [contactDetailsProps.newClientRegistration.contactDetails, contactDetailsProps.signUpErrors.contactDetailsErrors]);

    useEffect(() => {
        let areTouchedFieldsWithErrors  = evaluateTouchedFieldsWithErrors(contactDetailsProps.signUpErrors.contactDetailsErrors)
        setAreTouchedFieldsWithErrors(areTouchedFieldsWithErrors)
    }, [contactDetailsProps.signUpErrors.companyInformationErrors, contactDetailsProps.signUpErrors.contactDetailsErrors]);

    return (
        <div className="form">
            <div className="form__title">Contact Details</div>  
            <form>
                <div className="form__twoInputInOneRow">
                    <FirstName 
                        newClientRegistration={contactDetailsProps.newClientRegistration} 
                        setNewClientRegistration={contactDetailsProps.setNewClientRegistration} 
                        signUpErrors={contactDetailsProps.signUpErrors}
                        setSignUpErrors={contactDetailsProps.setSignUpErrors}
                    />
                    <LastName 
                        newClientRegistration={contactDetailsProps.newClientRegistration} 
                        setNewClientRegistration={contactDetailsProps.setNewClientRegistration}
                        signUpErrors={contactDetailsProps.signUpErrors}
                        setSignUpErrors={contactDetailsProps.setSignUpErrors}
                    />
                </div>               
                <div className="form__twoInputInOneRow">
                    <Phone 
                        newClientRegistration={contactDetailsProps.newClientRegistration} 
                        setNewClientRegistration={contactDetailsProps.setNewClientRegistration}
                        signUpErrors={contactDetailsProps.signUpErrors}
                        setSignUpErrors={contactDetailsProps.setSignUpErrors}
                    />
                    <Country 
                        newClientRegistration={contactDetailsProps.newClientRegistration} 
                        setNewClientRegistration={contactDetailsProps.setNewClientRegistration}
                        countries={contactDetailsProps.countries}
                    />          
                </div>  
                <Address 
                    newClientRegistration={contactDetailsProps.newClientRegistration} 
                    setNewClientRegistration={contactDetailsProps.setNewClientRegistration}
                    signUpErrors={contactDetailsProps.signUpErrors}
                    setSignUpErrors={contactDetailsProps.setSignUpErrors}
                    />
                <City 
                    newClientRegistration={contactDetailsProps.newClientRegistration} 
                    setNewClientRegistration={contactDetailsProps.setNewClientRegistration}
                    signUpErrors={contactDetailsProps.signUpErrors}
                    setSignUpErrors={contactDetailsProps.setSignUpErrors}/>
                <StateProvince 
                    newClientRegistration={contactDetailsProps.newClientRegistration} 
                    setNewClientRegistration={contactDetailsProps.setNewClientRegistration}
                    signUpErrors={contactDetailsProps.signUpErrors}
                    setSignUpErrors={contactDetailsProps.setSignUpErrors}
                    countries={contactDetailsProps.countries}
                    />
                <ZipAndPostal 
                    newClientRegistration={contactDetailsProps.newClientRegistration} 
                    setNewClientRegistration={contactDetailsProps.setNewClientRegistration}
                    signUpErrors={contactDetailsProps.signUpErrors}
                    setSignUpErrors={contactDetailsProps.setSignUpErrors}/>
            </form>
            <div className="form__stageButtons">
                <button type="button" className="greyButton" onClick={()=>{handleStateClick("back")}}>Back</button>
                <button 
                type="button"  
                className={`primaryButton w-35 h-37${ areTouchedFieldsWithErrors || areRequiredFieldsUndefined ? 'disabled' : ''}`} 
                disabled={areTouchedFieldsWithErrors || areRequiredFieldsUndefined}
                onClick={()=>{handleStateClick("next")}}>
                    Next
                </button>
            </div>               
        </div>
    );
};
export default ContactDetails;

const evaluateRequireUndefinedFields = (errors: IContactDetailsErrors, contactDetails: IContactDetails): boolean => {
    let areRequiredFieldsUndefined = false;
    const keys = Object.keys(contactDetails) as (keyof typeof contactDetails)[];
    var isAnyRequireUndefinedField = keys.find(key => (contactDetails[key] === undefined || (contactDetails[key]!.toString().length === 0)) && errors[key].required === true);
    areRequiredFieldsUndefined = isAnyRequireUndefinedField !== undefined;
    return areRequiredFieldsUndefined;
};
 
const evaluateTouchedFieldsWithErrors = (errors: IContactDetailsErrors): boolean => {
    let areTouchedFieldsWithErrors = false;
    let errorFields = Object.values(errors);
    let erroredFields = errorFields.filter(errorField => errorField.hasOwnProperty("message"));
    var isAnyFieldsWithErrors =  erroredFields.find(error => error.message?.length > 0);
    areTouchedFieldsWithErrors = isAnyFieldsWithErrors !== undefined;
    return areTouchedFieldsWithErrors;
};