import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BsPlusSquareDotted } from "react-icons/bs";
import { _addFile } from "../../../../APIs/documents/Documents";
import { IClientDocumentDto } from "../../../../models/clientDocument/IClientDocumentDto";
import { IClientDto } from "../../../../models/client/newRegisterAgent/IClientDto";

interface ModalUploadDocumentProps {
    selectedClient:number
    setClientDocuments:Function
    clienSelectedInformation:IClientDto
}

export const ModalUploadDocument = (modalUploadDocumentProps:ModalUploadDocumentProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]); 
    const [showError, setShowError ] = useState<boolean>(false); 
    const [showLoadingButton, setShowLoadingButton ] = useState<boolean>(false); 
    const allowedExtensions = JSON.parse(process.env.REACT_APP_UPLOAD_ALLOWED_EXTENSIONS!);
    const getRejectedExtensionsErrorMessaje = () => {
        const extensionsArray: [string, string][]=  Object.values(allowedExtensions)
        const secondNameExtensions: string[] = extensionsArray.map(subarray => subarray[1]);
        const joinedExtensions: string = secondNameExtensions.join(' ');
        return joinedExtensions
    };
    const rejectedExtensionsErrorMessaje = `Only ${getRejectedExtensionsErrorMessaje()} files are allowed`
    
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles, rejectedFiles) => {
            if (rejectedFiles.length > 0) {
                setShowError(true);
            } else {
                setUploadedFiles(acceptedFiles);
                setShowError(false); 
            }        
        },
        accept:allowedExtensions
    });
   
    const processFilesUpload =async () => {
        setShowLoadingButton(true)
        handleClose();
        const documentUploadResults = await uploadFiles()
        restoreVariables()
        setShowLoadingButton(false)
        sortUploadedDocuments(documentUploadResults)
    };

    const sortUploadedDocuments = async (documentUploadResults: IClientDocumentDto[]) => {
        const uploadedDocuments = [
            ...modalUploadDocumentProps.clienSelectedInformation.documents,
            ...documentUploadResults.sort((a, b) => a.id - b.id)
        ];
        modalUploadDocumentProps.setClientDocuments(uploadedDocuments);
        };

    const uploadFiles = async () => {
    var documentUploadResults = await Promise.all(
        uploadedFiles.map(uploadFile)
    );
    return(documentUploadResults)
    };

    const getFormData  = async (file:File) => {
        const fileType = file.type;
        const fileName = file.name;
        const arrayBuffer = await file.arrayBuffer();
        const blob = new Blob([(arrayBuffer)], { type: fileType });
        const form = new FormData();
        form.append('file', blob, fileName);
        return(form)
     };

    const uploadFile = async (file:File) => {
        const form = await getFormData (file)
        const addedFile = await _addFile(modalUploadDocumentProps.selectedClient, form );
        const documentUploaded: IClientDocumentDto = addedFile.data;
        return documentUploaded;
    };

    const handleShow = () => {
        setShowModal(true);
    };
    const handleClose = () => {
        setShowModal(false);
    };

    const restoreVariables = () => {
        setUploadedFiles([])
        setShowError(false); 
    };

    return (
        <div>
            {showLoadingButton ? 
            <button className="primaryButton  basic-font w-150px h-100" type="button" disabled>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span role="status">Uploading...</span>
            </button>
            :
            <button 
                className="w-100 primaryButton basic-font h-100" 
                onClick={() => handleShow()}>
                    Upload Document
            </button>}
            <Modal show={showModal} onHide={()=>{handleClose(), restoreVariables()}}>
                <ModalBody className="basic-scroll">
                <div {...getRootProps()}>
                <input {...getInputProps()}/>
                <p>Drag and drop files here or click to browse.</p>
                <BsPlusSquareDotted  size={"60"}/>
                <ul>
                    {uploadedFiles.map((file) => (
                    <li key={file.name}>{file.name}</li>
                    ))}
                </ul>
                {showError && <p className="errorMessage">{ rejectedExtensionsErrorMessaje}</p>    }
                </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="primaryButton basic-font w-8rem h-37"
                        onClick={() => {processFilesUpload()}}
                        disabled={uploadedFiles.length >0?false:true}
                        >
                            Upload 
                    </button>     
                </ModalFooter> 
            </Modal>
        </div>
    );
};
