import { IAffinipayPaymentToken } from "../../models/affinipayPayment/affinipayPaymentToken/IAffinipayPaymentToken";
import { INewClientPayload } from "../../models/client/newRegisterAgent/IClientDto";
import INewClientRegistration from "../../models/client/newRegisterAgent/INewClientRegistration";
import IClientHomeIcons from "../../models/client/resourcesStatusesDto/IClientHomeIcons";
import { IResourcesStatusesDto } from "../../models/client/resourcesStatusesDto/IResourcesStatusesDto";

const _mapToNewClientPayload = (newClientRegistration: INewClientRegistration, affinipayPaymentDetailsToken?:IAffinipayPaymentToken ): INewClientPayload =>{
    const serviceSubscriptions = newClientRegistration.optionalRegistrationServices.serviceSubscriptions.concat(newClientRegistration.companyInformation.serviceSubscriptions!)
    const newClientPayload = {
        clientInformation: {
            companyName: newClientRegistration.companyInformation.companyName!.trim(),
            businessType: newClientRegistration.companyInformation.businessEntityType?.id!,
            attorneyEmail: newClientRegistration.companyInformation.attorneyEmail!,
            notifyAttorney:  newClientRegistration.companyInformation.attorneyLawsuit!,
            changeAgent:  newClientRegistration.companyInformation.changingRegisteredAgents!,
            useAgentAddress: Boolean(newClientRegistration?.companyInformation?.serviceSubscriptions?.length !== 0),
            firstName: newClientRegistration.contactDetails.firstName.trim(),
            lastName:  newClientRegistration.contactDetails.lastName.trim(),
            phone: newClientRegistration.contactDetails.phone!.trim(),
            countryId: Number(newClientRegistration.contactDetails.country),
            address: newClientRegistration.contactDetails.address.trim(),
            city: newClientRegistration.contactDetails.city.trim(),
            stateProvince: newClientRegistration.contactDetails.stateProvince.trim(),
            zipcode: newClientRegistration.contactDetails.zipPostal.trim(),
            agentId: null,
            leadGeneratedBy: newClientRegistration.leadGeneratedBy,
            },
            userInformation: {
            email:  newClientRegistration.acountSetup.email.trim(),
            password: newClientRegistration.acountSetup.password.trim(),
            },
            serviceSubscriptions: serviceSubscriptions,
            affinipayPaymentDetails: {token: affinipayPaymentDetailsToken?.id ?? ""},
            couponId : newClientRegistration.couponId
        }; 

    return newClientPayload;
}
export { _mapToNewClientPayload };
    
const _mapStatusCardsInformation = (clientStatus: IResourcesStatusesDto , icons: IClientHomeIcons) => [
    {
        title: "Unread Documents",
        unreadMessages: clientStatus.unreadDocuments,
        icon : icons.unreadDocumentsIcon,
        redirectTo: "documents"
    },
    // {
    //     title: "Unpaid Invoices",
    //     unreadMessages: clientStatus.unpaidInvoices,
    //     icon: icons.unpaidInvoicesIcon,
    //     redirectTo: "invoices"
    // },
    // {
    //     title: "Services Requiring Attention",
    //     unreadMessages: clientStatus.servicesRequiringAttention,
    //     icon: icons.ServicesAttentionIcon,
    //     redirectTo: "home"
    // },
    // {
    //     title: "Orders Requiring Attention",
    //     unreadMessages: clientStatus.ordersRequiringAttention,
    //     icon: icons.ordersAttentionIcon,
    //     redirectTo: "home"
    // },
    {
        title: "Add Other Services and Products",
        unreadMessages: null,
        icon: icons.addOnsIcon,
        redirectTo: "addons"
    },
];
export default _mapStatusCardsInformation 
 
