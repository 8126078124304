import { useNavigate } from "react-router-dom";
import IClientHomeStatusCard from "../../../models/client/resourcesStatusesDto/IClientHomeStatusCard";
import "./card.scss"

interface ClientHomeCardProps {
    card:IClientHomeStatusCard
}

const ClientHomeCard = (clientHomeCardProps: ClientHomeCardProps) => {
    const navigate = useNavigate();

    return (
        
        <div className="card card-basic-style card-on-hover" onClick={()=>{navigate(`/client/${clientHomeCardProps.card.redirectTo}`)}}>
            <div>
                { clientHomeCardProps.card.icon }
            </div>
            { clientHomeCardProps.card.title }
            <span className={`${clientHomeCardProps.card.unreadMessages ? "badge": "no-badge"}`}>
                { clientHomeCardProps.card.unreadMessages }
            </span>
        </div>
    );
};
export default ClientHomeCard;

