import NavItem from "./navItems/NavItem";
import "./sidebar.scss";

const basePath = "/client";

const ClientNavItems = () => {
  return  ( 
    <div className="navItemsList flex-grow-1">
        <NavItem navItemProps={{ display: "Home", to: `${basePath}/home`}} />
        <NavItem navItemProps={{ display: "Documents", to: `${basePath}/documents`}}/>
        <NavItem navItemProps={{ display: "Invoices", to: `${basePath}/invoices`}}/>
        <a href="https://floridaagents.net/faqs/" target="_blank" className="navItem">FAQs</a>
        <NavItem navItemProps={{ display: "Support", to: `${basePath}/support`}}/>
    </div>)
};
export { ClientNavItems };