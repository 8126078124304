import axios from "axios";
import { AxiosResponse } from "axios";

const baseUrl = "document";

async function _getDocument(id: number, inline: boolean): Promise<AxiosResponse<any, any>> {
  const response = await axios.get(`${baseUrl}/${id}?inline=${inline}`, {
    responseType: 'blob',
  });
  return response
}

async function _addFile(id: number, form: FormData): Promise<AxiosResponse<any, any>> {
  const response = await axios.post(`${baseUrl}/client/${id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
}

export { _getDocument, _addFile }