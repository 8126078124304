import { useEffect, useState } from "react";
import { IClientInvoiceDto } from "../../../models/clientInvoice/IClientInvoiceDto";
import { _getClientInovices } from "../../../APIs/agent/AgentApi";
import { IStatusInvoices } from "../../../models/client/invoices/IInvoices";
import { _getStatusClientInvoices } from "../../../APIs/client/ClientApi";

interface ClientInvoicesTableProps {
    invoices: IClientInvoiceDto[] ;
    setInvoices: React.Dispatch<React.SetStateAction<IClientInvoiceDto[]>>;
    setSelectedInvoice: React.Dispatch<React.SetStateAction<number | undefined>>
  }

const ClientInvoicesTable = (clientInvoicesTableProps:ClientInvoicesTableProps) => {
    const [statusInvoices, setStatusInvoices] = useState<IStatusInvoices>()
    const handleClick = (documentId: number) => {
        clientInvoicesTableProps.setSelectedInvoice(documentId)     
    };

    useEffect(() => {
        initStatusInvoices();
    }, []);

    const  initStatusInvoices = async () => {
        let statusInvoices = await _getStatusClientInvoices();
        setStatusInvoices(statusInvoices)
    } 

    return (
        <div className="table-responsive-md client-documents-table">
            <table className="table align-middle table-hover">
            <thead className="table-head sticky-top">
                <tr>
                    <th scope="col">Client Name</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                    <th scope="col">total</th>
                </tr>
            </thead>
            <tbody className="table-body">
                {clientInvoicesTableProps.invoices.map((invoice)=> 
                <tr 
                    key={invoice.id}
                    onClick={() => handleClick(invoice.id)}
                >  
                    <td>{invoice.client?.firstName}</td>      
                    <td>{invoice.client?.companyName}</td>      
                    <td>{new Date(invoice.dueDate!).toLocaleDateString('en-US', { dateStyle: 'short' })}</td>
                    <td  style={{ color: statusInvoices?.[invoice.status] === "PAID"? 'green'  
                        : statusInvoices?.[invoice.status] === "PENDING"? 'goldenrod':'red' }}>
                        {statusInvoices?.[invoice.status].toLowerCase()}
                    </td>
                    <td className="link-td">view</td>
                    <td>${invoice.total}</td>      
                </tr>)}
            </tbody>
            </table>
        </div>
    );
};

export default ClientInvoicesTable