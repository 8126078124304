import "./initAffiniPay.scss";
import "./cardnumber.scss";

interface AffiniPayCardErrorProps {
  affiniPayCardError: string;
}

const CardNumber = (affiniPayCardError: AffiniPayCardErrorProps) => {
  return (
    <div className="mb-3 twoInputInOneRow-input">
      <div className="affinipay-iframe-container" id="my_credit_card_field_id">
      </div>
      <p className="errorMessage">{affiniPayCardError.affiniPayCardError}</p>
    </div>
  );
};
export default CardNumber;
