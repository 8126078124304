import { useEffect } from "react";
import { IClientDocumentDto } from "../../../models/clientDocument/IClientDocumentDto";
import { _getClientDocuments } from "../../../APIs/clientDocument/ClientDocument";
import "./documentsTable.scss"
import React from 'react';

interface DocumentsTableProps {
    documents: IClientDocumentDto[];
    setDocuments: React.Dispatch<React.SetStateAction<IClientDocumentDto[]>>;
    selectedDocumentIds: number[]
    setSelectedDocumentIds: React.Dispatch<React.SetStateAction<number[]>>
    handleViewFileClick:Function
}

const DocumentsTable = (documentsTableProps: DocumentsTableProps) => {

    const initDocuments = async () => {
        let documents = await _getClientDocuments()
        documentsTableProps.setDocuments(documents)
    }

    const handleChecksClicks = (selectedDocumentId: number) => {
        const ischeckSelected = documentsTableProps.selectedDocumentIds.includes(selectedDocumentId);
        if (ischeckSelected) {
            const filteredDocumentIds = documentsTableProps.selectedDocumentIds.filter(id => id !== selectedDocumentId);
            documentsTableProps.setSelectedDocumentIds(filteredDocumentIds)
        } else {
            const addingDocumentIds = [...documentsTableProps.selectedDocumentIds, selectedDocumentId]
            documentsTableProps.setSelectedDocumentIds(addingDocumentIds);
        }
    }  
    
    useEffect(() => {
        initDocuments()
    }, []);

    const handlecheckedDocuments = (selectedDocumentId: number):boolean => {
        const isDocumentSelected = documentsTableProps.selectedDocumentIds.includes(selectedDocumentId);
        return isDocumentSelected ;
    }  

    return (
        <div className="table-responsive-md client-documents-table">
            <table className="table align-middle">
                <thead className="table-head sticky-top">
                    <tr>
                        <th scope="col-md-2"></th>
                        <th scope="col-md-2">ID</th>
                        <th scope="col-md-2">Date Received</th>
                        <th scope="col-md-2">Status</th>
                        <th scope="col-md-2">Document Type</th>
                        <th scope="col-md-2">Action</th>
                    </tr>
                </thead>
                <tbody className="table-body">
                    {documentsTableProps.documents.map((document) =>
                        <tr>
                            <td className="col-md-1 text-center">
                                <input 
                                className="form-check-input" 
                                type="checkbox" 
                                onClick={() => { handleChecksClicks(document.id)}}
                                checked={handlecheckedDocuments(document.id)}
                                 />
                            </td>
                            <td className="col-md-2">{document.id}</td>
                            <td className="col-md-2">{new Date(document.dateReceived).toLocaleDateString('en-US', { dateStyle: 'short' })}</td>
                            <td className="col-md-2" style={{ color: document.isRead ? 'green' : 'red' }}>{document.isRead ? 'Read' : 'Unread'}</td>
                            <td className="col-md-2">{document.documentTypeNavigation?.name}</td>
                            <td className="col-md-2">
                                <label className="link-td" onClick={()=>{documentsTableProps.handleViewFileClick(document.id)}}>View</label>
                            </td>
                        </tr>)}
                </tbody>
            </table>
        </div>
    );
};

export default DocumentsTable;
