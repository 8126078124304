import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface IPasswordProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
    setPasswordError:Function
  }

const Password = (passwordProps:IPasswordProps) => {
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
    const error = passwordProps.signUpErrors.acountSetupErrors.password.getValidationErrors(e.target.value);
    passwordProps.setPasswordError(error);
    }

    return (
        <div className="mb-3">
            <div  className="nameCompany">
            <input 
                value={passwordProps.newClientRegistration.acountSetup.password }
                type="password" 
                className={`form-control ${passwordProps.signUpErrors.acountSetupErrors.password.message ? 'input-error' : ''}`} 
                placeholder="Password" 
                maxLength={50}
                onBlur={handleOnBlur}
                autoComplete="off"
                onPaste={(e)=>{passwordProps.setPasswordError("Pasting is not allow"),e.preventDefault();}}
                onChange={(e) => {
                    passwordProps.setNewClientRegistration({
                        ...passwordProps.newClientRegistration,
                        acountSetup: {
                            ...passwordProps.newClientRegistration.acountSetup,
                            password: e.target.value
                    }
                });
                passwordProps.setPasswordError("");
            }}/>   
            </div>
            <p className="errorMessage">{passwordProps.signUpErrors.acountSetupErrors.password.message} </p>
        </div>
    );
};
export default Password;