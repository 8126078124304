import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface ILastNameProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}
const LastName = (lastNameProps: ILastNameProps) => {
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = lastNameProps.signUpErrors.contactDetailsErrors.lastName.getValidationErrors(e.target.value);
            lastNameProps.setSignUpErrors(prevState => ({
                ...prevState,
                contactDetailsErrors: {
                    ...prevState.contactDetailsErrors,
                    lastName: {
                        ...prevState.contactDetailsErrors.lastName,
                        message: error
                    }
                }
            }));
    }

    const setError = (error: string) => {
        lastNameProps.setSignUpErrors(prevState => ({
            ...prevState,
            contactDetailsErrors: {
                ...prevState.contactDetailsErrors,
                lastName: {
                    ...prevState.contactDetailsErrors.lastName,
                    message: error
                }
            }
        }));
    }

    return (
        <div className="mb-3 twoInputInOneRow-input">
            <div>
                <input 
                    value={lastNameProps.newClientRegistration.contactDetails.lastName}
                    type="text" 
                    placeholder="Last Name"
                    className={`form-control ${lastNameProps.signUpErrors.contactDetailsErrors.lastName.message ? 'input-error' : ''}`} 
                    maxLength={50}
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                        lastNameProps.setNewClientRegistration({
                            ...lastNameProps.newClientRegistration,
                            contactDetails: {
                                ...lastNameProps.newClientRegistration.contactDetails,
                                lastName: e.target.value
                        }
                    });
                    setError("");
                }}/>   
            </div>
            <p className="errorMessage">{lastNameProps.signUpErrors.contactDetailsErrors.lastName.message} </p> 
        </div>
    );
};
export default LastName;