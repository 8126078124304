import { IClientDto } from "../../../../../models/client/newRegisterAgent/IClientDto";
import { BiPhone } from 'react-icons/bi';
import { MdOutlinePlace } from 'react-icons/md';
import { CiMail } from "react-icons/ci";
import { MdMarkunreadMailbox } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import MailboxModal from "./MailboxModal";


interface ClientProfileCardProps {
    clienSelectedInformation: IClientDto;
  }
 
const ClientProfileCard = (clientProfileCardProps:ClientProfileCardProps) => {
    const [showModal, setShowModal] = useState(false);
    const [mailboxNumber, setMailboxNumber] = useState(clientProfileCardProps?.clienSelectedInformation?.mailboxNumber);

    useEffect(() => {
        setMailboxNumber(clientProfileCardProps?.clienSelectedInformation?.mailboxNumber);
    }, [clientProfileCardProps?.clienSelectedInformation?.id]);
    

    const handleOpenModal = () => {
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
    };

    return (
        <div className="clientProfileCard">
            <MailboxModal setMailboxNumber={setMailboxNumber} clientId={clientProfileCardProps?.clienSelectedInformation?.id} showModal={showModal} handleClose={handleCloseModal} />

            <div className="cardTitles__title">Client Profile  
            {clientProfileCardProps?.clienSelectedInformation?.hasMailbox && !(mailboxNumber && mailboxNumber != 0 ) ? (
                    <a onClick={handleOpenModal} className="messageBoxIcon btn link"><MdMarkunreadMailbox/></a>
                  ) : (
                    clientProfileCardProps?.clienSelectedInformation?.hasMailbox && (<span className="messageBoxIcon"><MdMarkunreadMailbox /> {mailboxNumber} </span>)
                  )}

            </div>
            <div className="clientProfileCard__pictureAndName">
                <img
                    alt="logo"
                    className="clientProfileCard__pictureAndName__picture"
                    src={require("../person.png")}
                />
                <div className="clientProfileCard__pictureAndName__name">
                    <div className="clientProfileCard__pictureAndName__name__name"> {clientProfileCardProps.clienSelectedInformation.firstName} {clientProfileCardProps.clienSelectedInformation.lastName}</div> 
                        <div className="clientProfileCard__pictureAndName__name__information">Notify Attorney: {clientProfileCardProps.clienSelectedInformation.notifyAttorney === true? "Yes": "No"}</div>
                            {clientProfileCardProps.clienSelectedInformation.notifyAttorney?
                                <div className="clientProfileCard__pictureAndName__name__information">Attorney Email: {clientProfileCardProps.clienSelectedInformation.attorneyEmail}</div>
                            :
                            null}
                    <div className="clientProfileCard__pictureAndName__name__information">Changed Agent: {clientProfileCardProps.clienSelectedInformation.changeAgent === true? "Yes": "No"}</div>
                </div>
            </div>
            <div className="iconAndInformation">
                <h5 className="pe-2 pt-1"> <BiPhone /> </h5>            
                <div className="contentProfileCard w-35" > {clientProfileCardProps.clienSelectedInformation.phone} </div>
                <h5 className="pe-2 pt-1"> <CiMail /> </h5>            
                <div className="contentProfileCard"> {clientProfileCardProps.clienSelectedInformation.users[0]?.email} </div>
            </div>
           
            <div className="iconAndInformation">
                <h5 className="pe-2"> <MdOutlinePlace /> </h5>            
                <div className="contentProfileCard">
                {` ${clientProfileCardProps.clienSelectedInformation.address},
                    ${clientProfileCardProps.clienSelectedInformation.city},
                    ${clientProfileCardProps.clienSelectedInformation.country},
                    ${clientProfileCardProps.clienSelectedInformation.stateProvince}`
                }                   
                </div>
                
            </div>
            {clientProfileCardProps?.clienSelectedInformation?.leadGeneratedBy &&
            clientProfileCardProps?.clienSelectedInformation?.leadGeneratedBy !== "" && (
            <div className="iconAndInformation">
                Lead Generated By : {clientProfileCardProps.clienSelectedInformation.leadGeneratedBy}               
            </div>
          )}
            
        </div>
    );
};
export default ClientProfileCard;
