import IPaymentInformation from "../../../../../../models/client/newRegisterAgent/paymentInformation/IPaymentInformation";
import { IPaymentInformationError } from "../../../../../signUp/paymenyInformation/validation/IPaymentInformationError";

interface AddonFirstNameProps {
    addonsErrors: IPaymentInformationError;
    setAddonsErrors: React.Dispatch<React.SetStateAction<IPaymentInformationError>>;
    setPaymentInformation:React.Dispatch<React.SetStateAction<IPaymentInformation>>
    paymentInformation:IPaymentInformation
}

const AddonFirstName = (addonFirstNameProps:AddonFirstNameProps) => {
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    var error = addonFirstNameProps.addonsErrors.firstName.getValidationErrors(e.target.value);
        addonFirstNameProps.setAddonsErrors(prevState => ({
                ...prevState,
                firstName: {
                    ...prevState.firstName,
                    message: error
                }
            }));
    }
    const setError = (error: string) => {
        addonFirstNameProps.setAddonsErrors(prevState => ({
                ...prevState,
                firstName: {
                    ...prevState.firstName,
                    message: error
                } 
        }));
    }

    return (
        <div className="twoInputInOneRow-input addon-heigth">
                <input 
                    value={addonFirstNameProps.paymentInformation.firstName}
                    type="text"
                    className={`addon-form-control ${addonFirstNameProps.addonsErrors.firstName.message ? 'input-error' : ''}`} 
                    placeholder="First Name" 
                    maxLength={50}
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                        addonFirstNameProps.setPaymentInformation({
                                ...addonFirstNameProps.paymentInformation,
                                firstName: e.target.value
                    });
                    setError("");
                }}/>   
            <div className="addon-errorMessage">{addonFirstNameProps.addonsErrors.firstName.message} </div> 
        </div>
    );
};
export default AddonFirstName;