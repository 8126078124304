import { useContext } from "react";
import { UserContext } from "../../context/UserContextProvider";
import SessionUser from "../../models/user/SessionUser";
import { _clearSession } from "../../services/session/SessionService";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../context/SidebarContextProvider";

const Logout = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext); 

  const onLogOutClicked = () => {
    _clearSession();
    setIsSidebarOpen(false)
    userContext.setUser(new SessionUser());
    navigate("/login");
  };

  return (
    <button
      onClick={() => onLogOutClicked()}
      type="button"
      className="btn btn-primary m-3 "
    >
      Log out
    </button>
  );
};

export default Logout;
