import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface IAdressProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const Address = (adressProps: IAdressProps) => {

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = adressProps.signUpErrors.contactDetailsErrors.address.getValidationErrors(e.target.value);
        adressProps.setSignUpErrors(prevState => ({
                ...prevState,
                contactDetailsErrors: {
                    ...prevState.contactDetailsErrors,
                    address: {
                        ...prevState.contactDetailsErrors.address,
                        message: error
                    }
                }
            }));
    }

    const setError = (error: string) => {
        adressProps.setSignUpErrors(prevState => ({
            ...prevState,
            contactDetailsErrors: {
                ...prevState.contactDetailsErrors,
                address: {
                    ...prevState.contactDetailsErrors.address,
                    message: error
                }
            }
        }));
    }

    return (
        <div className="mb-3">
            <input 
                value={adressProps.newClientRegistration.contactDetails.address}
                type="text"
                placeholder="Address"
                className={`form-control ${adressProps.signUpErrors.contactDetailsErrors.address.message ? 'input-error' : ''}`} 
                maxLength={50}
                onBlur={handleOnBlur}
                onChange={(e) => {
                    adressProps.setNewClientRegistration({
                        ...adressProps.newClientRegistration,
                        contactDetails: {
                            ...adressProps.newClientRegistration.contactDetails,
                            address: e.target.value
                    }
                });
                setError("");
            }}/>
            <p className="errorMessage">{adressProps.signUpErrors.contactDetailsErrors.address.message} </p>    
        </div>
    );
};
export default Address;