import './clientHome.scss';
import { _getClientResourcesStatuses } from '../../../APIs/client/ClientApi';
import {  useEffect, useState } from 'react';
import ClientHomeCard from '../../../components/clients/home/ClientHomeCard';
import IClientHomeStatusCard from '../../../models/client/resourcesStatusesDto/IClientHomeStatusCard';
import _mapStatusCardsInformation from '../../../services/client/ClientServices';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { FaExclamationTriangle } from 'react-icons/fa';
import { AiFillFileExclamation } from 'react-icons/ai';
import { HiShoppingCart } from 'react-icons/hi';

const icons = {
  unreadDocumentsIcon: <IoDocumentTextOutline size={60} color={"#a29d9d"}/>,
  unpaidInvoicesIcon: <FaFileInvoiceDollar size={60} color={"#a29d9d"}/>,
  ServicesAttentionIcon: <FaExclamationTriangle size={60} color={"#a29d9d"}/>,
  ordersAttentionIcon: <AiFillFileExclamation size={60} color={"#a29d9d"}/>,
  addOnsIcon: <HiShoppingCart size={60} color={"#a29d9d"}/>
 } 

const ClientHome = () => {
  const [clientStatus, setClientStatus] = useState<IClientHomeStatusCard[]>([])

  useEffect(()=>{
    initClientResourcesStatus()
  },[])

  const initClientResourcesStatus = async () => {
    const clientResourcesStatus = await _getClientResourcesStatuses();
    const cardsInformation = _mapStatusCardsInformation(clientResourcesStatus, icons)
    setClientStatus(cardsInformation)
  };
    
  let invoiceCard :IClientHomeStatusCard = {
    title:'Invoices',
    unreadMessages:   null,
    icon: <FaFileInvoiceDollar size={60} color={"#a29d9d"} /> ,
    redirectTo: 'invoices'

  }
  return (
    <div className='client-home'>
      <h1>Welcome to your account</h1> 
      <div className="client-home__cards">
        {clientStatus.map((card) => 
          <ClientHomeCard card={card}/>
        )}
          <ClientHomeCard card={invoiceCard}/>
      </div>
    </div>
    );
};
export default ClientHome;


