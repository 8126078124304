import { useEffect, useState } from "react";
import { IClientDto } from "../../../../../models/client/newRegisterAgent/IClientDto";
import { IClientInvoiceStatus } from "../../../../../models/clientInvoice/IClientInvoiceStatus";
import { _getClientInvoicesStatus } from "../../../../../APIs/client/ClientApi";
import { useNavigate } from "react-router-dom";

interface IInvoicesCardProps {
  clienSelectedInformation: IClientDto;
}

const InvoicesCard = (InvoicesCardProps: IInvoicesCardProps) => {
  const [clientInvoiceStatus, setClientInvoiceStatus] =
    useState<IClientInvoiceStatus>({});
    let navigate = useNavigate(); 

  useEffect(() => {
    initClientInvoiceStatus();
  }, []);

  const handleClick = (invoiceId: number) => {
      let path = `/provider/invoices/${invoiceId}`; 
      navigate(path,  { state: { clientId: InvoicesCardProps.clienSelectedInformation.id} })
  };

  const initClientInvoiceStatus = async () => {
    const ClientInvoiceStatus = await _getClientInvoicesStatus();
    setClientInvoiceStatus(ClientInvoiceStatus);
  };

  return (
    <div className="invoicesCard table-responsive-md">
      <div className="cardTitles">
        <div className="cardTitles__title">Invoices</div>
      </div>
      <table className="table align-middle">
        <thead className="table-head sticky-top">
          <tr>
            <th scope="col">Invoice Number</th>
            <th scope="col">Date Received</th>
            <th scope="col">Due Date</th>
            <th scope="col">Total</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {InvoicesCardProps.clienSelectedInformation.invoices.map(
            (invoice) => (
              <tr key={invoice.id}>
                <td>{invoice.id}</td>
                <td>
                  {new Date(invoice.dateReceived!).toLocaleDateString("en-US", {
                    dateStyle: "short",
                  })}
                </td>
                <td>
                  {new Date(invoice.dueDate!).toLocaleDateString("en-US", {
                    dateStyle: "short",
                  })}
                </td>
                <td>${invoice.total}</td>
                <td style={{ textTransform: "capitalize" }}>
                  {clientInvoiceStatus  && clientInvoiceStatus![invoice.status]?.toLowerCase()}
                </td>
                <td className="link-td" onClick={() => handleClick(invoice.id)}>
                  View Details
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};
export default InvoicesCard;
