interface AddonSecurityCodeProps {
    affiniPayCvvError: string;
  }
  
  const AddonSecurityCode = (
    addonSecurityCodeProps: AddonSecurityCodeProps
  ) => {
    return (
      <div className="twoInputInOneRow-input addon-heigth">
        <div className="addon-affinipay-iframe-container" id="my_cvv_field_id"></div>
        <div className="addon-errorMessage">{addonSecurityCodeProps.affiniPayCvvError}</div>
      </div>
    );
  };
  export default AddonSecurityCode;
  