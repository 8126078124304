import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface PostalCodeProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const PostalCode = (postalCodeProps:PostalCodeProps) => {
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = postalCodeProps.signUpErrors.paymentInformationError.postalCode.getValidationErrors(e.target.value);
            postalCodeProps.setSignUpErrors(prevState => ({
                ...prevState,
                paymentInformationError: {
                    ...prevState.paymentInformationError,
                    postalCode: {
                        ...prevState.paymentInformationError.postalCode,
                        message: error
                    }
                }
            }));
    }
    const setError = (error: string) => {
        postalCodeProps.setSignUpErrors(prevState => ({
            ...prevState,
            paymentInformationError: {
                ...prevState.paymentInformationError,
                postalCode: {
                    ...prevState.paymentInformationError.postalCode,
                    message: error
                }
            }
        }));
    }

    return (
        <div className="mb-3 twoInputInOneRow-input">
            <div>
                <input 
                    value={postalCodeProps.newClientRegistration.paymentInformation.postalCode}
                    type="text"
                    className={`form-control ${postalCodeProps.signUpErrors.paymentInformationError.postalCode.message ? 'input-error' : ''}`} 
                    placeholder="Postal Code" 
                    onBlur={handleOnBlur}
                    maxLength={50}
                    onChange={(e) => {
                        postalCodeProps.setNewClientRegistration({
                            ...postalCodeProps.newClientRegistration,
                            paymentInformation: {
                                ...postalCodeProps.newClientRegistration.paymentInformation,
                                postalCode: e.target.value
                        }
                    });
                    setError("");
                }}/>   
            </div>
            <p className="errorMessage">{postalCodeProps.signUpErrors.paymentInformationError.postalCode.message} </p> 
        </div>
    );
};
export default PostalCode;