interface AffiniPayCardErrorProps {
  affiniPayCardError: string;
}

const AddonCardNumber = (affiniPayCardError: AffiniPayCardErrorProps) => {
  return (
    <div className="twoInputInOneRow-input addon-heigth">
      <div className="addon-affinipay-iframe-container" id="my_credit_card_field_id"></div>
      <div className="addon-errorMessage">{affiniPayCardError.affiniPayCardError}</div>
    </div>
  );
};
export default AddonCardNumber;
