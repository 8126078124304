import { useContext } from "react";
import NavItem from "./navItems/NavItem";
// import NavItems from "./navItems/NavItems";
import "./sidebar.scss";
import { UserContext } from "../../context/UserContextProvider";

const basePath = "provider";

const ProviderNavItems = () => {
    const userContext = useContext(UserContext);

    return  ( 
        <div className="navItemsList flex-grow-1">  
            <NavItem navItemProps={{ display: "Clients", to: `${basePath}/clients`}} />
            <NavItem navItemProps={{ display: "Invoices", to: `${basePath}/invoices`, rolesAllowed: ["not-fully-implemented-yet"]  }} userRole={userContext.user.role}/>
            {/* collapsable sidebar. Enhace styles if Required in the future */}
            {/* <NavItems props={{ navItemProps: { display: 'Documents', to: '', rolesAllowed: ["agent-admin", 'agent-viewer'] }, userRole: userContext.user.role }}> */}
            <NavItem navItemProps={{ display: "Documents", to: `${basePath}/Documents`, rolesAllowed: ["not-fully-implemented-yet"]}} userRole={userContext.user.role}/>
            {/* </NavItems> */}
        </div>)
};
export { ProviderNavItems };