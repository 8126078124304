import IPaymentInformation from "../../../../../../models/client/newRegisterAgent/paymentInformation/IPaymentInformation";
import { IPaymentInformationError } from "../../../../../signUp/paymenyInformation/validation/IPaymentInformationError";

interface AddonLastNameProps {
    addonsErrors: IPaymentInformationError;
    setAddonsErrors: React.Dispatch<React.SetStateAction<IPaymentInformationError>>;
    setPaymentInformation:React.Dispatch<React.SetStateAction<IPaymentInformation>>
    paymentInformation:IPaymentInformation
}

const AddonLastName = (addonLastNameProps:AddonLastNameProps) => {
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        var error = addonLastNameProps.addonsErrors.lastName.getValidationErrors(e.target.value);
        addonLastNameProps.setAddonsErrors(prevState => ({
                    ...prevState,
                    lastName: {
                        ...prevState.lastName,
                        message: error
                    }
                }));
        }
        const setError = (error: string) => {
            addonLastNameProps.setAddonsErrors(prevState => ({
                ...prevState,
                paymentInformationError: {
                    ...prevState,
                    lastName: {
                        ...prevState.lastName,
                        message: error
                    }
                }
            }));
        }

    return (
        <div className="twoInputInOneRow-input addon-heigth">
                <input 
                    value={addonLastNameProps.paymentInformation.lastName}
                    type="text"
                    className={`addon-form-control ${addonLastNameProps.addonsErrors.lastName.message ? 'input-error' : ''}`} 
                    placeholder="Last Name" 
                    maxLength={50}
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                        addonLastNameProps.setPaymentInformation({
                                ...addonLastNameProps.paymentInformation,
                                lastName: e.target.value
                    });
                    setError("");
                }}/>   
            <div className="addon-errorMessage">{addonLastNameProps.addonsErrors.lastName.message} </div> 
        </div>
    );
};
export default AddonLastName;