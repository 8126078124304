import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface IPasswordValidationCopyProps {
    setConfirmPasswordError:Function
    signUpErrors: ISignUpErrors;
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
  }

const PasswordValidationCopy = (passwordValidationCopyProps:IPasswordValidationCopyProps) => {
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
    const error = passwordValidationCopyProps.signUpErrors.acountSetupErrors.confirmPassword.getValidationErrors(passwordValidationCopyProps.newClientRegistration.acountSetup.password,e.target.value);
    passwordValidationCopyProps.setConfirmPasswordError(error);
    }

    return (
        <div className="mb-3">
            <div  className="nameCompany">
            <input 
                value={passwordValidationCopyProps.newClientRegistration.acountSetup.confirmPassword }
                type="password" 
                className={`form-control ${passwordValidationCopyProps.signUpErrors.acountSetupErrors.confirmPassword.message ? 'input-error' : ''}`} 
                placeholder="Confirm Password" 
                maxLength={50}
                onBlur={handleOnBlur}
                autoComplete="off"
                onPaste={(e)=>{passwordValidationCopyProps.setConfirmPasswordError("Pasting is not allow"),e.preventDefault();}}
                onChange={(e) => {
                    passwordValidationCopyProps.setNewClientRegistration({
                        ...passwordValidationCopyProps.newClientRegistration,
                        acountSetup: {
                            ...passwordValidationCopyProps.newClientRegistration.acountSetup,
                            confirmPassword: e.target.value
                    }
                });
                passwordValidationCopyProps.setConfirmPasswordError("");
            }}/>   
            </div>
            <p className="errorMessage">{passwordValidationCopyProps.signUpErrors.acountSetupErrors.confirmPassword.message}</p>
        </div>
    );
};
export default PasswordValidationCopy;