import { useEffect, useState } from "react";
import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ChangingRegisteredAgentsModal } from "./ChangingRegisteredAgentsModal";
import "./changingRegisteredAgents.scss"

interface IChangingRegisteredAgentsProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
}

const ChangingRegisteredAgents = (changingRegisteredAgentsProps:IChangingRegisteredAgentsProps) => {
    const [colorFirstView, setColorFirstView] = useState("");
    const [selectedValue, setSelectedValue] = useState(
        changingRegisteredAgentsProps.newClientRegistration.companyInformation.changingRegisteredAgents === undefined ? '0' : changingRegisteredAgentsProps.newClientRegistration.companyInformation.changingRegisteredAgents ? '1' : '2' 
    );

      useEffect(() => {
        setSelectedValue(changingRegisteredAgentsProps.newClientRegistration.companyInformation.changingRegisteredAgents === undefined ? '0' : changingRegisteredAgentsProps.newClientRegistration.companyInformation.changingRegisteredAgents ? '1' : '2' );
      }, [changingRegisteredAgentsProps.newClientRegistration.companyInformation.changingRegisteredAgents]);

    return (
        <div className="mb-3">
            <div className="signUp-input-titles-text p-0 m-0">
            Are You Changing Registered Agents and you want to hire us? 
            </div>
            <div  className="changingAgents">
                <select
                    value={selectedValue}
                    className={`form-select ${colorFirstView === "" ? "color-default-option-selected" : ""}`}
                    disabled={false}
                    onChange={(e) => {
                        setColorFirstView(e.target.value);
                        const isTrueSelected = e.target.value === "1";
                        changingRegisteredAgentsProps.setNewClientRegistration({
                            ...changingRegisteredAgentsProps.newClientRegistration,
                            companyInformation: {
                                ...changingRegisteredAgentsProps.newClientRegistration.companyInformation,
                                changingRegisteredAgents: isTrueSelected
                            }
                        });
                    }}
                >
                    <option value="0" disabled></option>
                    <option value={1} >Yes</option>
                    <option value={2} >No</option>
                </select>
                <ChangingRegisteredAgentsModal/>
            </div>           
            {changingRegisteredAgentsProps.newClientRegistration.companyInformation.changingRegisteredAgents && <p className="basic-font">Total cost for this product: U$D 35.00</p>}</div>
    );
};
export default ChangingRegisteredAgents;