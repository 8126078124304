interface AffiniPaySecurityCodeProps {
  affiniPayCvvError: string;
}

const SecurityCode = (
  affiniPaySecurityCodeProps: AffiniPaySecurityCodeProps
) => {
  return (
    <div className="mb-3 twoInputInOneRow-input">
      <div className="affinipay-iframe-container" id="my_cvv_field_id" >
      </div>
      <p className="errorMessage">{affiniPaySecurityCodeProps.affiniPayCvvError}</p>
    </div>
  );
};
export default SecurityCode;
