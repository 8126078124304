import { IAgentDto } from "../../agent/IAgentDto";
import { IBusinessEntityTypeDto } from "../../businessEntityType/IBusinessEntityTypeDto";
import { IClientDocumentDto } from "../../clientDocument/IClientDocumentDto";
import { IClientInvoiceDto } from "../../clientInvoice/IClientInvoiceDto";
import { IClientServiceDto } from "../../clientService/IClientServiceDto";
import { IMinimalUserDto } from "../../user/IMinimalUserDto";
import { IClientDto } from "./IClientDto";

export default class ClientDto implements IClientDto {
  constructor(
    id: number = 0,
    companyName: string = '',
    businessType: number | null = null,
    attorneyEmail:string | null = null,
    notifyAttorney: boolean | null = null,
    changeAgent: boolean | null = null,
    useAgentAddress: boolean | null = null,
    firstName: string = '',
    lastName: string | null = null,
    phone: string | null = null,
    country: string | null = null,
    address: string | null = null,
    city: string | null = null,
    stateProvince: string | null = null,
    zipcode: string | null = null,
    agentId: number = 0,
    leadGeneratedBy: string | null = null,
    agent: IAgentDto | null = null,
    businessTypeNavigation: IBusinessEntityTypeDto | null = null,
    documents: IClientDocumentDto[] = [],
    invoices: IClientInvoiceDto[] = [],
    clientServices: IClientServiceDto[] = [],
    users: IMinimalUserDto[] = [],
  ) {
    this.id = id;
    this.companyName = companyName;
    this.businessType = businessType;
    this.attorneyEmail = attorneyEmail;
    this.notifyAttorney = notifyAttorney;
    this.changeAgent = changeAgent;
    this.useAgentAddress = useAgentAddress;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.country = country;
    this.address = address;
    this.city = city;
    this.stateProvince = stateProvince;
    this.zipcode = zipcode;
    this.agentId = agentId;
    this.leadGeneratedBy = leadGeneratedBy;
    this.agent = agent;
    this.businessTypeNavigation = businessTypeNavigation;
    this.documents = documents;
    this.invoices = invoices;
    this.clientServices = clientServices;
    this.users = users;
  }

    id: number;
    companyName: string;
    businessType: number | null;
    attorneyEmail: string | null;
    notifyAttorney: boolean | null;
    changeAgent: boolean | null;
    useAgentAddress: boolean | null;
    firstName: string;
    lastName: string | null;
    phone: string | null;
    country: string | null;
    address: string | null;
    city: string | null;
    stateProvince: string | null;
    zipcode: string | null;
    agentId: number;
    leadGeneratedBy: string | null;
    agent: IAgentDto | null;
    businessTypeNavigation: IBusinessEntityTypeDto | null;
    documents: IClientDocumentDto[];
    invoices: IClientInvoiceDto[];
    clientServices: IClientServiceDto[];
    users: IMinimalUserDto[];
}
