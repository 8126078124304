import { _searchClients } from "../../../../../APIs/agent/AgentApi";
import { IClientDocumentDto } from "../../../../../models/clientDocument/IClientDocumentDto";
import { _searchClientDocuments } from "../../../../../APIs/clientDocument/ClientDocument";

interface AddAndSearchClientsProps {
    documents: IClientDocumentDto[];
    setDocuments: React.Dispatch<React.SetStateAction<IClientDocumentDto[]>>
    element: JSX.Element[]
  }

const ClientElementsAndSearchButtonCard = (addAndSearchClientsProps:AddAndSearchClientsProps) => { 
    return (
      <div>
        <div className="components-and-search-button-card">
          <div 
            className={`cellphone-display-none computer-management-top-buttons`}>
            {addAndSearchClientsProps.element}
          </div>
        </div>
        <div className="cellphone-management-top-buttons computer-display-none">
        {addAndSearchClientsProps.element}       
        </div>
      </div>
    );
};

export default ClientElementsAndSearchButtonCard;
