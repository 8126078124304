import { useContext, useState } from "react";
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import "./login.scss";
import {
  _login,
  _getLoginUser,
  _getSessionUserFromLoginUser,
  _getUserType,
} from "../../services/login/LoginService";
import ApiError from "../../models/error/ApiError";
import logoImage from "../../components/logoHeader/floridaAgents.png";
import { _forgotPassword } from "../../APIs/user/userApi";

const LogIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isForgotPwScreen, setIsForgotPwScreen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const onLoginClicked = async () => {
    try {
      setIsLoading(true);
      var succesfullyLoggedUser = await _login(email, password);
      var loginUser = _getLoginUser(succesfullyLoggedUser.token);
      let userType: string = _getUserType(loginUser);
      var sessionUser = _getSessionUserFromLoginUser(loginUser);
      userContext.setUser(sessionUser); // this is for components consumption (with hooks)
      sessionStorage.setItem(
        process.env.REACT_APP_SESSION_KEY!,
        succesfullyLoggedUser.token
      ); // this is for routing (need sync code)
      redirectAccordingly(userType);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const typedError = error as ApiError;
      setErrorMessage(typedError.details);
    }
  };

  const redirectAccordingly = (userType: string): void => {
    switch (userType) {
      case "agent": {
        navigate("/provider/clients"); // update this to {agentName}/clients
        break;
      }
      case "client": {
        navigate("/client/home"); // update this to {clientName}/home
        break;
      }
      default: {
        navigate("/error");
        break;
      }
    }
  };

  const forgotPassword = (): void => {
    setIsForgotPwScreen(true);
  };
  const onForgotClicked = async () => {
    setIsLoading(true);
    const result = await _forgotPassword(email, window.location.href);
    if (result) {
      setErrorMessage(
        "Please check your email and click on the link to reset the password. The link will expire in 20 minutes"
      );
    } else {
      setErrorMessage("User not found");
    }
    setIsLoading(false);
  };

  return (
    <div className="logInPage">
      <div className="image-section">
        {/* the following code is the text in the background, now the image has the text but if we change the image we need to use this code          */}
        {/* <div className="text-section">
                    <div className="text-blue text-welcome">
                        Welcome to
                    </div>
                        <img src={logoImage} alt="Company image" className="logo-login-header"/>
                    <div className="text-blue text-welcome">
                    Where excellence
                    Meets Your Expectation
                    </div>
                    <div className="text-gold unique-mail">
                        Florida Register Agent Service with Unique Mail Forwarding Suite #
                    </div>
                </div> */}
      </div>
      {isForgotPwScreen ? (
        <div className="login-section">
          <div className="login">
            <div className="form__title">Forgot Password</div>
            <label className="form__subTitle"></label>
            <input
              type="email"
              className="form-control mb-3"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            ></input>
            <div className="submitStageButtons">
              <button
                disabled={email.length === 0 || isLoading}
                type="button"
                className="primaryButton w-30 h-37"
                onClick={() => onForgotClicked()}
              >
                Submit
              </button>
            </div>
            <p className="error">{errorMessage}</p>
          </div>
        </div>
      ) : (
        <div className="login-section">
          <img
            src={logoImage}
            alt="company Image"
            className="login-logo-cellphone"
          />
          <div className="login">
            <div className="form__title">Login</div>
            <label className="form__subTitle"></label>
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            ></input>
            <input
              type="password"
              className="form-control mb-3"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            ></input>
            <div className="submitStageButtons">
              <button
                disabled={email.length === 0 || password.length === 0 || isLoading}
                type="button"
                className="primaryButton"
                onClick={() => onLoginClicked()}
              >
                {isLoading ? "Please wait…":"LogIn"}
              </button>
              {!isLoading ? (<p onClick={forgotPassword} className="buttonForgotPasword">
                Forgot password?
              </p>):(<p className="disabled">
                Forgot password?
              </p>)}
              
            </div>
            <p className="error">{errorMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogIn;
