import IServiceSubscriptions from "../companyInformation/serviceSubscriptions/IServiceSubscriptions";
import { IOptionalRegistrationServices } from "./IOptionalRegistrationServices";

export default class OptionalRegistrationServices implements IOptionalRegistrationServices {
  serviceCategory: number| undefined;
  serviceSubscriptions : IServiceSubscriptions[];
  
  constructor(
    ServiceCategory: number | undefined = undefined,
    serviceSubscriptions : IServiceSubscriptions[] = [],
  ) {
    this.serviceCategory = ServiceCategory;
    this.serviceSubscriptions = serviceSubscriptions;
  }
}


