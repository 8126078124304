import IContactDetails from "./IContactDetails";

export default class ContactDetails implements IContactDetails {
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
  address: string;
  city: string;
  stateProvince: string;
  zipPostal: string;
  
  constructor(
    firstName: string = "",
    lastName: string = "",
    phone: string = "",
    country: string = "",
    address: string = "",
    city: string = "",
    stateProvince: string = "",
    zipPostal: string = "",
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.country = country;
    this.address = address;
    this.city = city;
    this.stateProvince = stateProvince;
    this.zipPostal = zipPostal;
  }
}
