import { BiSearchAlt } from "react-icons/bi";
import { IClientInvoiceDto } from "../../../../models/clientInvoice/IClientInvoiceDto";
import { ChangeEvent, useState } from "react";
import { _searchClientInvoices } from "../../../../APIs/agent/AgentApi";


interface AddAndSearchClientsProps {
    invoices: IClientInvoiceDto[] ;
    setInvoices: React.Dispatch<React.SetStateAction<IClientInvoiceDto[]>>;
    setSelectedInvoice: React.Dispatch<React.SetStateAction<number | undefined>>
    element: JSX.Element[]
  }

const ClientElementsAndSearchButtonCard = (addAndSearchClientsProps:AddAndSearchClientsProps) => {
    const [clientOrCompanyName, setClientOrCompanyName] = useState("");
    const [isSearchDisabled, setIsSearchDisabled] = useState(true); 

    const  onSearchClicked = async () => {
      let invoices = await  _searchClientInvoices(clientOrCompanyName)
      addAndSearchClientsProps.setInvoices(invoices)
    } 
      
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      setClientOrCompanyName(e.target.value); 
      let shouldDisableSearch = e.target.value === "";
      setIsSearchDisabled(shouldDisableSearch)
    };
    return (
        <div>
          <div className="components-and-search-button-card">
            <div className="cellphone-display-none computer-management-top-buttons">
              {addAndSearchClientsProps.element}
            </div>
            <input 
              className="searchInput me-2 " 
              placeholder="Client or Company name"
              value={clientOrCompanyName}
              onChange={handleInputChange}> 
            </input>
            <button
                className="primaryButton basic-font "
                onClick={() => {onSearchClicked()}}
                disabled={isSearchDisabled} 
            >
                <span className="search-text">Search</span>
                <BiSearchAlt />
            </button>
          </div>
          <div className="cellphone-management-top-buttons computer-display-none">
          {addAndSearchClientsProps.element}       
          </div> 
        </div>
    );
};

export default ClientElementsAndSearchButtonCard;
