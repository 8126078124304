import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface ILastName {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const LastName = (lastNameProps:ILastName) => {
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = lastNameProps.signUpErrors.paymentInformationError.lastName.getValidationErrors(e.target.value);
            lastNameProps.setSignUpErrors(prevState => ({
                ...prevState,
                paymentInformationError: {
                    ...prevState.paymentInformationError,
                    lastName: {
                        ...prevState.paymentInformationError.lastName,
                        message: error
                    }
                }
            }));
    }
    const setError = (error: string) => {
        lastNameProps.setSignUpErrors(prevState => ({
            ...prevState,
            paymentInformationError: {
                ...prevState.paymentInformationError,
                lastName: {
                    ...prevState.paymentInformationError.lastName,
                    message: error
                }
            }
        }));
    }

    return (
        <div className="mb-3 twoInputInOneRow-input">
            <div>
                <input 
                    value={lastNameProps.newClientRegistration.paymentInformation.lastName}
                    type="text"
                    className={`form-control ${lastNameProps.signUpErrors.paymentInformationError.lastName.message ? 'input-error' : ''}`} 
                    placeholder="Last Name" 
                    onBlur={handleOnBlur}
                    maxLength={50}
                    aria-describedby="emailHelp" 
                    onChange={(e) => {
                        lastNameProps.setNewClientRegistration({
                            ...lastNameProps.newClientRegistration,
                            paymentInformation: {
                                ...lastNameProps.newClientRegistration.paymentInformation,
                                lastName: e.target.value
                        }
                    });
                    setError("");
                }}/>   
            </div>
            <p className="errorMessage">{lastNameProps.signUpErrors.paymentInformationError.lastName.message} </p> 
        </div>
    );
};
export default LastName;