import axios from "axios";
import { IClientDto } from "../../models/client/newRegisterAgent/IClientDto";
import { IClientInvoiceDto } from "../../models/clientInvoice/IClientInvoiceDto";

const baseUrl = "agent"

async function _searchClients(clientOrCompanyName: string): Promise<IClientDto[]> {
  let response = await axios.get(`${baseUrl}/clients/search?clientOrCompanyName=${clientOrCompanyName}`)
  return response.data;
}

async function _getAllClients(pageNo: number, pageSize: number, count: number): Promise<IClientDto[]> {
  let response = await axios.get(`${baseUrl}/clients/getall?pageNo=${pageNo}&pageSize=${pageSize}&count=${count}`)
  return response.data;
}

async function _getClientInovices(): Promise<IClientInvoiceDto[]> {
  let response = await axios.get(`${baseUrl}/invoices`)
  return response.data;
}

async function _searchClientInvoices(clientOrCompanyName: string): Promise<IClientInvoiceDto[]> {
  let response = await axios.get(`${baseUrl}/invoices/search?clientOrCompanyName=`+clientOrCompanyName)
  return response.data;
}

export {_searchClients, _getAllClients, _getClientInovices, _searchClientInvoices};

