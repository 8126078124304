import { useEffect, useState } from "react";
import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import IBusinessEntityType from "../../../../models/client/newRegisterAgent/companyInformation/businessEntityType/IBusinessEntityType";
import { _getBusinessEntityType } from "../../../../APIs/companyInformation/businessEntityType/BusinessEntityTypeAPIs";

interface IBusinessEntityTypeProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
  }
    
const SelectBusinessEntityType = (businessEntityTypeProps:IBusinessEntityTypeProps) => {
    const [businessEntityTypes, setBusinessEntityTypes] = useState<IBusinessEntityType[]>([]);
    const [colorFirstView, setColorFirstView] = useState("");
    const [selectedValue, setSelectedValue] = useState(
        businessEntityTypeProps.newClientRegistration.companyInformation.businessEntityType?.id || '0'
      );

    useEffect(() => {
        initBusinessEntityTypes()  
    }, []);

    const initBusinessEntityTypes = async () => {   
        const businessEntityTypes = await _getBusinessEntityType();
        setBusinessEntityTypes(businessEntityTypes);
      };

      useEffect(() => {
        if (businessEntityTypeProps.newClientRegistration.companyInformation.businessEntityType) {
          setSelectedValue(businessEntityTypeProps.newClientRegistration.companyInformation.businessEntityType.id);
        }
      }, [businessEntityTypeProps.newClientRegistration.companyInformation.businessEntityType]);

    return (
        <div className="mb-3">
            <select
                value={selectedValue}
                className={`form-select ${colorFirstView === "" ? "color-default-option-selected" : ""}`}
                disabled={false}
                onChange={(e) => {
                    setColorFirstView(e.target.value);
                    const businessEntityTypeResult = businessEntityTypes.find(businessEntityType => businessEntityType.id === Number(e.target.value));
                    if (businessEntityTypeResult) {
                        businessEntityTypeProps.setNewClientRegistration({
                            ...businessEntityTypeProps.newClientRegistration,
                            companyInformation: {
                                ...businessEntityTypeProps.newClientRegistration.companyInformation,
                                businessEntityType: businessEntityTypeResult  
                            }
                     });
                }}}
            >
                <option value="0" disabled>Business Entity Type</option>
                {businessEntityTypes && businessEntityTypes.map((businessEntityType: IBusinessEntityType) => (
                <option key={businessEntityType.id} value={businessEntityType.id}>
                    {businessEntityType.name}
                </option>
                ))}
            </select>
        </div>
    );
};
export default SelectBusinessEntityType;
