import { IClientDto } from "../../../../../models/client/newRegisterAgent/IClientDto";
import { BiPhone } from "react-icons/bi";
import { MdOutlinePlace } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { MdMarkunreadMailbox } from "react-icons/md";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { IAgentMailboxDto } from "../../../../../models/agentMailbox/IAgentMailboxDto";
import { _getAvailableMailBoxes } from "../../../../../APIs/agentMailbox/AgentMailboxApi";
import { _assignMailbox } from "../../../../../APIs/client/ClientApi";

interface MyModalProps {
  showModal: boolean;
  clientId: Number;
  handleClose: () => void;
  setMailboxNumber: React.Dispatch<React.SetStateAction<number | null|undefined>>;  

}

const MailboxModal: React.FC<MyModalProps> = ({
  showModal,
  handleClose,
  clientId,
  setMailboxNumber
}) => {
  const [mailBoxes, setMailBoxes] = useState<IAgentMailboxDto[]>([]);
  const [selectedmailBox, setSelectedmailBox] = useState<number | null>(null);

  useEffect(() => {
    setMailBoxData();
  }, []);

  const setMailBoxData = async () => {
    const mailBoxes = await _getAvailableMailBoxes();
    setMailBoxes(mailBoxes);
  };

  const handleSelect = (mailboxNumber: number | null) => {
    setSelectedmailBox(mailboxNumber);
  };

  const assignMailbox = async () => {
    if (selectedmailBox) {
      await _assignMailbox(clientId, selectedmailBox);
    }
    handleClose();
    setMailboxNumber(selectedmailBox  );
  };
  const handleModalExited = () => {
    setMailBoxData();
    setSelectedmailBox(null);
  };
  return (
    <Modal show={showModal} onExited={handleModalExited}  onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Mailbox </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropdown>
          <Dropdown.Toggle variant="primary">
            {selectedmailBox ? `${selectedmailBox}` : "Select Mailbox"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {mailBoxes.map((mailBox) => (
              <Dropdown.Item
                key={mailBox.mailboxNumber}
                onClick={() => handleSelect(mailBox.mailboxNumber)}
              >
                {mailBox.mailboxNumber}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>{" "}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={assignMailbox}>
          Assign
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MailboxModal;
