import './Support.scss';


const Support = () => {
  return (
    <div className='support-home'>
      <h3>Have Questions?</h3> 
      <div className="support-content">
        <p>If you have additional questions or need further assistance, please feel free to contact us:</p>
        <p className='support-row'>Email: <a href="mailto:help@floridaagents.net">help@floridaagents.net</a></p>
        <p>Phone: +1-888.991.5786</p>
      </div>
    </div>
   
    );
};
export default Support;


