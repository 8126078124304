import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";
import { IPaymentInformationError } from "../validation/IPaymentInformationError";

interface IFirstName {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const FirstName = (firstNameProps:IFirstName) => {

    const FirstNameHandleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = firstNameProps.signUpErrors.paymentInformationError.firstName.getValidationErrors(e.target.value);
        setError(error)
      }
    
      const setError = (error: string ) => {
        firstNameProps.setSignUpErrors(prevState => ({
            ...prevState,
            paymentInformationError: {
                ...prevState.paymentInformationError,
                firstName: {
                    ...prevState.paymentInformationError.firstName,
                    message: error
                }
            }
        }));
    }
    

    return (
        <div className="mb-3 twoInputInOneRow-input">
            <div>
                <input 
                    value={firstNameProps.newClientRegistration.paymentInformation.firstName}
                    type="text"
                    className={`form-control ${firstNameProps.signUpErrors.paymentInformationError.firstName.message ? 'input-error' : ''}`} 
                    placeholder="First Name" 
                    maxLength={50}
                    onBlur={FirstNameHandleOnBlur}
                    onChange={(e) => {
                        firstNameProps.setNewClientRegistration({
                            ...firstNameProps.newClientRegistration,
                            paymentInformation: {
                                ...firstNameProps.newClientRegistration.paymentInformation,
                                firstName: e.target.value
                        }
                    });
                    setError("");
                }}/>   
            </div>
            <p className="errorMessage">{firstNameProps.signUpErrors.paymentInformationError.firstName.message} </p> 
        </div>
    );
};
export default FirstName;