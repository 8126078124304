import { useContext, useState } from "react";
import { IService } from "../../../../../models/service/IService";
import { IServicesPackageDto } from "../../../../../models/servicesPackage/IServicesPackageDto";
import TotalAddonPackages from "./TotalAddonPackages";

interface AddonTotalAmountCardProps {
  selectedServicesAndPackages:{selectedService:IService,selectedPackage:IServicesPackageDto}[]
  getAffiniPayToken: (e: React.MouseEvent<HTMLButtonElement>) => void; 
  affiniPayGeneratingTokenError: string
  affiniPayCardError:string
  affiniPayCvvError:string
  areTouchedFieldsWithErrors:boolean
  isPaid:boolean
  areRequiredFieldsUndefined:boolean
  showLoadingButton:boolean
  }

const AddonTotalAmountCard = (addonTotalAmountCardProps: AddonTotalAmountCardProps) => {
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const successMessage = "Your order was successfully processed, enjoy our services!"
  
  return(
    <div className="card-template">
      <div className="small-titles">Order Summary</div>  
      <form className="flex-column small-text">
        Billed to:
        <div>dolorem ipsum </div>
        <div>dolorem ipsum quia dolor sit amet</div>
        <TotalAddonPackages totalAmount={totalAmount} setTotalAmount={setTotalAmount} selectedServicesAndPackages={addonTotalAmountCardProps.selectedServicesAndPackages}/>
                  
      </form>
      <div className="submitStageButtons d-flex">
        {addonTotalAmountCardProps.showLoadingButton ? 
          <button className="primaryButton  basic-font w-150px h-100" type="button" disabled>
            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <span role="status">Processing...</span>
          </button>
          :
          <button
              type="button"
              className={`primaryButton w-fit-35 h-37 ${
                addonTotalAmountCardProps.areRequiredFieldsUndefined ||
                addonTotalAmountCardProps.areTouchedFieldsWithErrors ||
                addonTotalAmountCardProps.affiniPayCardError.length > 0 ||
                addonTotalAmountCardProps.affiniPayCvvError.length > 0 ||
                addonTotalAmountCardProps.affiniPayGeneratingTokenError.length > 0 }
                ? 'disabled' : ''}`}
              onClick={(e) => {
                addonTotalAmountCardProps.getAffiniPayToken(e);
              }}
              disabled={
                addonTotalAmountCardProps.areRequiredFieldsUndefined ||
                addonTotalAmountCardProps.areTouchedFieldsWithErrors ||
                addonTotalAmountCardProps.affiniPayCardError.length > 0 ||
                addonTotalAmountCardProps.affiniPayCvvError.length > 0 ||  
                addonTotalAmountCardProps.affiniPayGeneratingTokenError.length > 0 } 
              >
              Place Order
          </button>
        }
        <p className="errorMessage">{addonTotalAmountCardProps.affiniPayGeneratingTokenError}</p>
        {showSuccessMessage && (
          <div className="successMessage">{successMessage}</div>
        )}
        {addonTotalAmountCardProps.isPaid && (
          <div className="successMessage">{"Payment processed successfully. Please wait.."}</div>
        )}     
      </div> 
    </div>
  )
}

export default AddonTotalAmountCard;