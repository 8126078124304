import IPaymentInformation from "./IPaymentInformation";

export default class PaymentInformation implements IPaymentInformation {
    firstName: string;
    lastName: string;
    cardNumber:number;
    expiration: string;
    securityCode: number;
    postalCode:string;
  
  constructor(
    firstName: string = "",
    lastName: string = "",
    cardNumber: number = 0,
    expiration: string = "",
    securityCode: number = 0,
    postalCode: string = "",
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.cardNumber = cardNumber;
    this.expiration = expiration;
    this.securityCode = securityCode;
    this.postalCode = postalCode;
  }
}