import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./successSignUpText.scss"

const SuccessSignUpText = () => {   
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(()=> {
            navigate('/login');  
        }, 4000) 
      }, []);
    
    return (
        <div className="successSignUpText">
            <div className="align-center">
                Thank you for your Order.
            </div>
            <div className="align-center">
                See you on the other side!
            </div>
            <div className="align-center">
                you will be redirected to login in 3, 2, 1...
            </div>
        </div>
    );
};
export default SuccessSignUpText;