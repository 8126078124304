import { useContext } from "react";
import { UserContext } from "../../context/UserContextProvider";
import { PiSquaresFourFill } from 'react-icons/pi';
import LogoHeader from "../../components/logoHeader/logoHeader";
import { SidebarContext } from "../../context/SidebarContextProvider";

const ClientsHeader = () => {
    const userContext = useContext(UserContext);
    const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext); 
    
    const handleOpenSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="providerInformation">
            <div  className="providerInformation__logoAndTitlePosition">
                <div className="providerInformation__cellPhoneView" onClick={handleOpenSidebar}><PiSquaresFourFill size={"50px"} title={"openSidebar"} color={"#010863"}/></div>
                <div className="providerInformation__name">Hello, {userContext.user.companyName} </div>
                <LogoHeader/>
            </div> 
        </div>
    );
};

export default ClientsHeader;
