import acountSetupErrors, { IAcountSetupErrors } from "../acountSetup/validations/AcountSetupErrors";
import companyInformationErrors, { ICompanyInformationErrors } from "../companyInformation/validation/ICompanyInformationErrors";
import contactDetailsErrors, { IContactDetailsErrors } from "../contactDetails/validations/IContactDetailsErrors";
import differentBillingAddressError, { IDifferentBillingAddressError } from "../differentBillingAddress/validation/IDifferentBillingAddressError";
import paymentInformationError, { IPaymentInformationError } from '../paymenyInformation/validation/IPaymentInformationError';

export interface ISignUpErrors {
    companyInformationErrors: ICompanyInformationErrors;
    contactDetailsErrors: IContactDetailsErrors;
    acountSetupErrors: IAcountSetupErrors;
    differentBillingAddressError: IDifferentBillingAddressError;
    paymentInformationError: IPaymentInformationError
}

const singUpErrors: ISignUpErrors = {
    companyInformationErrors,
    contactDetailsErrors,
    acountSetupErrors,
    differentBillingAddressError,
    paymentInformationError
};
export default singUpErrors