import { useEffect, useState } from "react";
import "./providerClients.scss"
import { IClientDto } from "../../../models/client/newRegisterAgent/IClientDto";
import ClientsTable from "../../../components/provider/home/ClientsTable";
import NewClient from "../../../components/provider/home/elementsAndSearchButtons/NewClient";
import { ModalUploadDocument } from "../../../components/provider/home/elementsAndSearchButtons/ModalUploadDocument";
import NewInvoice from "../../../components/provider/home/elementsAndSearchButtons/NewInvoice";
import ElementsAndSearchButtonCard from "../../../components/provider/home/elementsAndSearchButtons/ElementsAndSearchButtonCard";
import ClientDto from "../../../models/client/newRegisterAgent/ClientDto";
import ClientDashboardCards from "../../../components/provider/home/clientDashboard/ClientDashboardCards";
import { IClientDocumentDto } from "../../../models/clientDocument/IClientDocumentDto";
import { useLocation, useParams } from "react-router-dom";
import { _getAllClients } from "../../../APIs/agent/AgentApi";

const ProviderClients = () => {
  const [clients, setSClients] = useState <IClientDto[]> ([]);
  const [selectedClient, setSelectedClient] = useState <number | undefined> (undefined);
  const [clienSelectedInformation, setClienSelectedInformation] = useState <IClientDto> (new ClientDto());
  const location = useLocation();
  const [showLoadingButton, setShowLoadingButton] = useState<boolean>(false);
  const params = useParams<"id">();

  useEffect(() => {
    if (params && params?.id) {
      let id :number|undefined = Number(params?.id);
      setSelectedClient(id );
    } 
  }, []);

  useEffect(() => {
    if(location.pathname == "/provider/clients"){
      getClientsOnLoad()
    }
  }, [location.key]);

  const setClientDocuments = (addDocuments: IClientDocumentDto[]) => {
    setClienSelectedInformation({ ...clienSelectedInformation,  
      documents: addDocuments})
  }

  const  getClientsOnLoad = async () => {
    let clients = await _getAllClients(0,100,100)
    setSClients(clients)
    setSelectedClient(undefined)
  } 
  return (
    <div className="providerHome">
      <div className="providerInformation__state">Client Management</div>
      <ElementsAndSearchButtonCard showLoadingButton={showLoadingButton} clients={clients} setSClients={setSClients} setSelectedClient={setSelectedClient} newClient={selectedClient ? [<NewInvoice />, <ModalUploadDocument selectedClient={selectedClient} setClientDocuments={setClientDocuments} clienSelectedInformation={clienSelectedInformation}/>] : [<NewClient />]}/>
      {selectedClient || params?.id  ? 
        (<ClientDashboardCards setShowLoadingButton={setShowLoadingButton} setClienSelectedInformation={setClienSelectedInformation} selectedClient={selectedClient??0} clienSelectedInformation={clienSelectedInformation}/>) 
        : 
        (<ClientsTable clients={clients} setSClients={setSClients} setSelectedClient={setSelectedClient}/>)}
    </div>
  );
}
export default ProviderClients;

