import { IService } from "../../../../../models/service/IService";
import { IServicesPackageDto } from "../../../../../models/servicesPackage/IServicesPackageDto";

interface AddonSummaryCardProps {
  selectedServicesAndPackages:{selectedService:IService,selectedPackage:IServicesPackageDto}[]
  }

const AddonSummaryCard = (addonSummaryCardProps: AddonSummaryCardProps) => {
  return(
    <div className="card-template">
       <table className="table table-responsive ">
          <thead>
              <tr>
                  <th scope="col-md-1">Service</th>
                  <th scope="col-md-1">Service Description</th>
                  <th scope="col-md-1">Package</th>
                  <th scope="col-md-2">Package Description</th>
                  <th scope="col-md-1">Amount</th>
              </tr>
          </thead>
          <tbody>
            {addonSummaryCardProps.selectedServicesAndPackages.map((serviceAndPackage) => (
              <tr key={serviceAndPackage.selectedService.id}>
                <td className="col-md-1">{serviceAndPackage.selectedService.name}</td>
                <td className="col-md-1">{serviceAndPackage.selectedService.description}</td>
                <td className="col-md-1">{serviceAndPackage.selectedPackage.name}</td>
                <td className="col-md-2">{serviceAndPackage.selectedPackage.description}</td>
                <td className="col-md-1">${serviceAndPackage.selectedPackage.amount}</td>
              </tr> 
            ))}
            
          </tbody>
        </table>
    </div>
  )
}

export default AddonSummaryCard;