import axios from "axios";
const baseUrl = "user";

async function isUserDefined(email: string): Promise<boolean> {
  let userExistsUrl = `${baseUrl}/${email}/exists`;
  let response = await axios.get(userExistsUrl);
  return response.data;
}

async function _forgotPassword(email: string, url: string): Promise<boolean> {
  let forgotPasswordUrl = `${baseUrl}/ForgotPassword`;
  let response = await axios.post(forgotPasswordUrl,{
    email:email,
    url:url
  });
  return response.data;
}

async function _resetPassword(
  email: string,
  password: string,
  token: string
): Promise<boolean> {
  let forgotPasswordUrl = `${baseUrl}/reset`;
  let response = await axios.post(forgotPasswordUrl, {
    email: email,
    token: token,
    password: password,
  });
  return response.data;
}

export { isUserDefined, _forgotPassword, _resetPassword };
