import { useEffect, useState } from "react";
import { IClientInvoiceDto } from "../../../models/clientInvoice/IClientInvoiceDto";
import InvoicesTable from "../../../components/clients/invoices/InvoicesTable";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  _getMultipleInvoiceList,
  _getStatusClientInvoices,
} from "../../../APIs/client/ClientApi";
import { IClientInvoicePymentSummary } from "../../../models/clientInvoice/IClientInvoicePymentSummary";
import "./clientInvoiceDetails.scss";
import { CloseButton } from "react-bootstrap";
import { IStatusInvoices } from "../../../models/client/invoices/IInvoices";

interface ClientInvoiceDetailProps {
  clientId?: string;
}

const ClientInvoiceDetail: React.FC<ClientInvoiceDetailProps> = (props) => {
  const [summary, setSummary] = useState<IClientInvoicePymentSummary[]>();
  const [statusInvoices, setStatusInvoices] = useState<IStatusInvoices>();
  let navigate = useNavigate();

  const { state } = window.history;
  const clientId = state?.usr?.clientId;

  const params = useParams<"id">();
  useEffect(() => {
    getInvoiceData();
  }, []);

  const getInvoiceData = async () => {
    const data = await _getMultipleInvoiceList([params?.id ?? ""]);
    let statusInvoices = await _getStatusClientInvoices();
    setStatusInvoices(statusInvoices);
    setSummary(data);
  };

  const onClose = () => {
    if(clientId){
    navigate("/provider/clients/"+clientId);
    }else{
      navigate("/client/invoices");
    }
  };

  return (
    <>
      {summary ? (
        <>
        {clientId && <div className="providerInformation__state">Client Management</div>}
          <div className="clientDocuments">
            <div className="modal-body row">
              <div className="col-md-5">
                <strong style={{ textTransform: "capitalize" }}>
                  {summary[0]?.client.companyName}
                </strong>
                <div style={{ textTransform: "capitalize" }}>
                  {`${summary[0]?.client.firstName} ${summary[0]?.client.lastName}`}
                </div>
                <div style={{ textTransform: "capitalize" }}>
                  {summary[0]?.client.address}{" "}
                </div>
                <div style={{ textTransform: "capitalize" }}>
                  {summary[0]?.client.city}{" "}
                </div>
                <div style={{ textTransform: "capitalize" }}>
                  {`${summary[0]?.client.stateProvince}, ${summary[0]?.client.zipcode}`}
                </div>
              </div>
              <div className="col-md-5">
                <strong>Invoice : {summary[0]?.clientInvoice.id}</strong>
                <div>
                  Created on :{" "}
                  {new Date(
                    summary[0]?.clientInvoice.dateReceived!
                  ).toLocaleDateString("en-US", { dateStyle: "short" })}
                </div>
                <div>
                  {" "}
                  Due date :{" "}
                  {new Date(
                    summary[0]?.clientInvoice.dueDate!
                  ).toLocaleDateString("en-US", { dateStyle: "short" })}{" "}
                </div>{" "}
              </div>
              <div className="col-md-2 d-flex justify-content-end">
                <CloseButton onClick={onClose} />
              </div>
            </div>
          </div>

          <hr />
          <div className="table-responsive-md client-documents-table">
            <table className="table">
              <thead className="table-head sticky-top">
                <tr>
                  <th scope="col">Description</th>
                  <th scope="col">Price</th>
                  <th scope="col">Subtotal</th>
                </tr>
              </thead>
              <tbody className="">
                {summary[0]?.clientInvoiceDetails.map((invoice) => (
                  <tr>
                    <td>
                      {`${invoice.service.name} - ${invoice.servicePackage.name}`}{" "}
                      <br />{" "}
                      {`${new Date(invoice.serviceFromDate!).toLocaleDateString(
                        "en-US",
                        {
                          dateStyle: "short",
                        }
                      )} - ${new Date(
                        invoice.serviceToDate!
                      ).toLocaleDateString("en-US", {
                        dateStyle: "short",
                      })}`}
                    </td>
                    <td>$ {invoice.servicePackage.amount}</td>
                    <td>$ {invoice.servicePackage.amount}</td>
                  </tr>
                ))}
              </tbody>
              <tr>
                <td className="border-top-table" colSpan={2}>
                  <strong>Total</strong>
                </td>
                <td className="border-top-table">
                  <strong>${summary[0]?.clientInvoice.total} </strong>
                </td>
              </tr>

              <tfoot>
                <tr>
                  <td></td>
                  <td
                    style={{
                      textTransform: "capitalize",
                    }}
                    colSpan={2}
                  >
                    {statusInvoices?.[
                      summary[0]?.clientInvoice?.status
                    ].toLowerCase()}
                  </td>
                </tr>
                <tr>
                  <td> </td>
                  <td colSpan={2}>
                    {" "}
                    {summary[0]?.clientInvoice?.paidDate
                      ? `Paid on ${new Date(
                          summary[0]?.clientInvoice?.paidDate!
                        ).toLocaleDateString("en-US", {
                          dateStyle: "short",
                        })}`
                      : ""}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      ) : null}
    </>
  );
};
export default ClientInvoiceDetail;
