import { IErroredField, IRequiredField } from "../../validation/IErroredAndRequiredField";

export interface IAcountSetupErrors {
    email: IErroredField & IRequiredField;
    password: IErroredField & IRequiredField;
    confirmPassword: IErroredField & IRequiredField;
}

const acountSetupErrors: IAcountSetupErrors = {
    email: {
        required: true,
        getValidationErrors: (value): string => {
            let errorMessages = [];
            if (value.length <= 0) {
                errorMessages.push("Email is required.");
            }
            if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                errorMessages.push("Invalid email address format.");
            }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
    password: { 
        required: true,
        getValidationErrors: (value): string => {
            let errorMessages = [];
            if (value.length <= 0) {
                errorMessages.push("Password is required.");
            }
            if (value.length < 8) {
                errorMessages.push("Password must be at least 8 characters long.");
              }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
    confirmPassword: { 
        required: true,
        getValidationErrors: (password:string, confirmPasword:string): string => {
            let errorMessages = [];
            if (confirmPasword.length <= 0) {
                errorMessages.push("Password is required.");
            }
            if (password !== confirmPasword) {
                errorMessages.push("Passwords do not match.");
              }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
}
export default acountSetupErrors