import React, { useEffect, useState } from "react";
import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { Button } from "react-bootstrap";
import { _validateCouponCode } from "../../../../APIs/client/ClientApi";
import { IClientCouponDto } from "../../../../models/client/newRegisterAgent/IClientDto";

interface ICouponProps {
  setTotalAmount: React.Dispatch<React.SetStateAction<number>>;
  totalAmount: number;
  newClientRegistration: INewClientRegistration;
  setNewClientRegistration: React.Dispatch<
    React.SetStateAction<INewClientRegistration>
  >;
}

const Coupon = (couponProps: ICouponProps) => {
  const [code, setCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedCoupon, setSelectedCoupon] = useState<IClientCouponDto>();
  const [discountAmount, setDiscountAmount] = useState<number>(0);

  const verifyCoupon = async () => {
    var codes = await _validateCouponCode(code);
    if (codes) {
      setSelectedCoupon(codes);
      setErrorMessage("");
      calculateTotal(codes.discountPercent ?? 0);
      couponProps.setNewClientRegistration({
        ...couponProps.newClientRegistration,
        couponId: codes.id,
      });
    } else {
      setSelectedCoupon({ id: 0 });
      calculateTotal(0);
      setErrorMessage("Invalid Coupon Code");
      couponProps.setNewClientRegistration({
        ...couponProps.newClientRegistration,
        couponId: 0,
      });
    }
  };

  const calculateTotal = async (discountPercentage: number) => {
    let totalAmount = 0;
    if (
      couponProps.newClientRegistration.companyInformation
        .serviceSubscriptions &&
      couponProps.newClientRegistration.companyInformation.serviceSubscriptions
        .length > 0
    ) {
      totalAmount +=
        couponProps.newClientRegistration.companyInformation
          .serviceSubscriptions[0].servicePackageAmount;
    }
    if (
      couponProps.newClientRegistration.optionalRegistrationServices
        .serviceSubscriptions
    ) {
      couponProps.newClientRegistration.optionalRegistrationServices.serviceSubscriptions.forEach(
        (selectedService) => {
          if (selectedService.servicePackageId) {
            totalAmount += selectedService.servicePackageAmount;
          }
        }
      );
    }
    if (
      couponProps.newClientRegistration.companyInformation
        .changingRegisteredAgents === true
    ) {
      totalAmount +=
        couponProps.newClientRegistration.companyInformation
          .changingRegisteredAgentsSubscription.amount;
    }

    let finalTotalAmount =
      totalAmount - (totalAmount * discountPercentage) / 100;

    if (discountPercentage == 0) {
      setDiscountAmount(0);
    } else {
      setDiscountAmount(Number((totalAmount - finalTotalAmount).toFixed(2)));
    }
    console.log(finalTotalAmount, totalAmount, totalAmount - finalTotalAmount);
    couponProps.setTotalAmount(finalTotalAmount);
  };

  return (
    <div>
      <div className="row">
        <div className="col-9 ">
          <small id="emailHelp" className="form-text text-muted">
            Have a coupon code?
          </small>
          <div style={{ display: "flex" }}>
            <div>
              <input
                value={code}
                type="text"
                placeholder="Code"
                className={"form-control mb-3"}
                maxLength={10}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                style={{ maxWidth: "97%" }}
              />
              <div className="errorMessage"> {errorMessage}</div>
            </div>
            <div>
              <Button disabled={code.length < 1} onClick={verifyCoupon}>
                Apply
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      {selectedCoupon && selectedCoupon.id > 0 && (
        <div className="row" style={{ marginTop: "12" }}>
          <div className="col-8  successMessage"> Coupon code applied successfully</div>
          <div className="col-4" style={{ textAlign: "right" }}>
           <b> - ${discountAmount.toFixed(2)}{" "}</b>
          </div>
        </div>
      )}

      <br />
    </div>
  );
};

export default Coupon;
