import { useState } from "react";
import ClientElementsAndSearchButtonCard from "../../../components/clients/documents/elementsAndSearchButtons/elementsAndSearchButtonCard/ClientElementsAndSearchButtonCard";
import DocumentsDownload from "../../../components/clients/documents/elementsAndSearchButtons/DocumentsDownload";
import DocumentsTable from "../../../components/clients/documents/DocumentsTable";
import { IClientDocumentDto } from "../../../models/clientDocument/IClientDocumentDto";
import Modal from "react-bootstrap/esm/Modal";
import { _downloadSuccesfullAndRejectedResults, _viewDocument, RejectedResponseType } from "../../../services/documents/Documents";
import { _getDocument } from "../../../APIs/documents/Documents";
import { useNavigate } from "react-router-dom";

const ClientDocuments = () => {
  const [documents, setDocuments] = useState<IClientDocumentDto[]>([]);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<number[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalFile, setShowModalFile] = useState<boolean>(false);
  const [showLoadingButton, setShowLoadingButton] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [image, setImage] = useState<string>("");

  const navigate = useNavigate();

  const handleDownloadFileClick = async (viewId: number[]) => {
    setShowLoadingButton(true)
    let handleDocumentsResults = await _downloadSuccesfullAndRejectedResults(viewId);
    handleSucessfullyDownloadedFiles(handleDocumentsResults.succesfullyDownloadIds);
    handleFailedDownloadedFiles(handleDocumentsResults.failedDownloadResponses);
    setSelectedDocumentIds([]);
    setShowLoadingButton(false);
  }

  const handleViewFileClick = async (viewId: number) => {
    setShowLoadingButton(true)
    let handleDocumentsResults = await _viewDocument(viewId)
    if ('urlFile' in handleDocumentsResults) {
      setImage(handleDocumentsResults.urlFile)
      handleSucessfullyDownloadedFiles([handleDocumentsResults.id]);
      setShowModalFile(true)
    } else {
      handleFailedDownloadedFiles([handleDocumentsResults]);
    }
    setShowLoadingButton(false);
  }
  
  const handleSucessfullyDownloadedFiles = (ids: number[]): void => {
    setDocumentsIsRead(ids, true);
  }

  const handleFailedDownloadedFiles = (failedDownloadResponses: RejectedResponseType[]): void => {
    let message = ""
    if (failedDownloadResponses.length > 0) {
       message = `You have reached the maximum limit for the number of documents that can be viewed or downloaded based on your subscription. To view/download more documents, please upgrade your subscription or purchase more documents by clicking the button below.`;
      handleShow()
    }
    setErrorMessage(message);
  }
  const setDocumentsIsRead = (documentIds: number[], isRead: boolean = true) => {
    let updatedDocuments = documents.map((document) => {
      if (documentIds.includes(document.id)) {
        document.isRead = isRead;
      }
      return document;
    })
    setDocuments(updatedDocuments)
  }

  const handleShow = () => {
    setShowModal(true);
  };
  
  const handleClose = () => {
    setShowModal(false);
    setErrorMessage("");
  };

  const handlePurchase = () => {
     navigate('/client/addons?type=doc');
  };
  

  return (
    <div className="clientDocuments">
      <div className="providerInformation__state">Documents</div>
      <ClientElementsAndSearchButtonCard documents={documents} setDocuments={setDocuments}  element={[<DocumentsDownload selectedDocumentIds={selectedDocumentIds} handleDownloadFileClick={handleDownloadFileClick} showLoadingButton={showLoadingButton} />]} />
      <DocumentsTable documents={documents} setDocuments={setDocuments}  selectedDocumentIds={selectedDocumentIds} setSelectedDocumentIds={setSelectedDocumentIds} handleViewFileClick={handleViewFileClick} />
      <Modal show={showModal} onHide={handleClose} className=""  >
        <div className="small-text stext-white-space smodal-body basic-scroll-hide">
          {errorMessage}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
          <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handlePurchase}>Purchase Documents</button>
        </div>
      </Modal>
      <div className={`modal fade ${showModalFile ? 'show' : ''}`} id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showModalFile ? 'block' : 'none' }}>
        <div className="modal-dialog modal-fullscreen ">
          <div className="modal-content mh-100">
            <div className="modal-body ">
              <iframe
                src={image}
                width="90%"
                height="98%"
              >
             </iframe>                 
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>setShowModalFile(false)}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ClientDocuments;