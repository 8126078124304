import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";
import StateProvinceDropDown from "./StateProvinceDropDown";
import StateProvinceTextBox from "./StateProvinceTextBox";


interface StateAndProvinceProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const StateProvince = (stateAndProvinceProps: StateAndProvinceProps) => {
    const USCountryName = "UNITED STATES"

    return (
        stateAndProvinceProps.newClientRegistration.differentBillingAddress.country === USCountryName ?
            <StateProvinceDropDown
                 newClientRegistration={stateAndProvinceProps.newClientRegistration} 
                 setNewClientRegistration={stateAndProvinceProps.setNewClientRegistration}
                 signUpErrors={stateAndProvinceProps.signUpErrors}
                 setSignUpErrors={stateAndProvinceProps.setSignUpErrors}
            />
        : 
            <StateProvinceTextBox
                newClientRegistration={stateAndProvinceProps.newClientRegistration} 
                setNewClientRegistration={stateAndProvinceProps.setNewClientRegistration}
                signUpErrors={stateAndProvinceProps.signUpErrors}
                setSignUpErrors={stateAndProvinceProps.setSignUpErrors}
            />
        
    );
};
export default StateProvince;