import { Navigate } from 'react-router-dom'
import { _isUserAuthenticated } from '../../services/auth/AuthenticationService';

interface IProps {
    children: JSX.Element;
  }

const PrivateRoute = (props: IProps): any => {
    const isAuthenticated = _isUserAuthenticated();
    return isAuthenticated ? props.children : <Navigate to='/login'/>;
}

export default PrivateRoute;