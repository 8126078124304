import { useEffect, useState } from "react";
import "./signUp.scss"
import INewClientRegistration from "../../models/client/newRegisterAgent/INewClientRegistration";
import NewClientRegistration from "../../models/client/newRegisterAgent/NewClientRegistration";
import ContactDetails from "../../components/signUp/contactDetails/ContactDetails";
import AcountSetup from "../../components/signUp/acountSetup/AcountSetup";
import DifferentBillingAddress from "../../components/signUp/differentBillingAddress/DifferentBillingAddress";
import { ICountryDto } from "../../models/country/ICountryDto";
import { _getCountries } from "../../APIs/country/Country";
import { IAffinipayPaymentToken } from "../../models/affinipayPayment/affinipayPaymentToken/IAffinipayPaymentToken";
import OptionalItems from "../../components/signUp/optionalItems/OptionalItems";
import AffinipayPaymentToken from "../../models/affinipayPayment/affinipayPaymentToken/AffinipayPaymentToken";
import singUpErrors, { ISignUpErrors } from "../../components/signUp/validation/ISignUpErrors";
import PaymentAndOrder from "../../components/signUp/paymentAndOrder/PaymentAndOrder";
import SuccessSignUpText from "../../components/signUp/successSignUpText/SuccessSignUpText";
import CompanyInformation from "../../components/signUp/companyInformation/CompanyInformation";
import { IServiceSubscriptionsOptional } from "../../models/client/newRegisterAgent/companyInformation/serviceSubscriptions/IServiceSubscriptions";
import DifferentBillingAddressNew from "../../models/client/newRegisterAgent/differentBillingAddress/DifferentBillingAddress";

const SignUp = () => {
  const [newClientRegistration , setNewClientRegistration] = useState<INewClientRegistration>(new NewClientRegistration());
  const [currentStep, setCurrentStep] = useState(1);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [countries, setCountries] = useState<ICountryDto[]>([]);
  const [signUpErrors, setSignUpErrors] = useState<ISignUpErrors>(singUpErrors);
  const [affinipayPaymentDetailsToken, setAffinipayPaymentDetailsToken] = useState<IAffinipayPaymentToken>(new AffinipayPaymentToken());
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {setIsChecked(event.target.checked);
  if(!event.target.checked){
    restoreDifferentBillingAddress();
  }}
  const [selectedServices, setSelectedServices] = useState< IServiceSubscriptionsOptional[]>([]);
  useEffect(() => {
    initCountries()
  }, []);

  const restoreDifferentBillingAddress = () => {
     setNewClientRegistration({
        ...newClientRegistration,
        differentBillingAddress:  new DifferentBillingAddressNew()
});
}
  const initCountries = async () => {
    const countries = await _getCountries();
    setCountries(countries);
  };

  function changeStep(step : string) {
    if (step==="next")
      {setCurrentStep(currentStep+1)}
    else if  (step==="back")
      {setCurrentStep(currentStep-1)}
    else if  (step==="nextFlow")
      {setCurrentStep(currentStep+0.1)}
    else if  (step==="backFlow")
      {setCurrentStep(currentStep-0.1)}
    }
  return (
    <div className="signUp">
        {
          currentStep === 1 ?
          <CompanyInformation 
            newClientRegistration={newClientRegistration} 
            setNewClientRegistration={setNewClientRegistration}  
            changeStep={changeStep}
            signUpErrors={signUpErrors}
            setSignUpErrors={setSignUpErrors}
          />
          :currentStep === 2 ?
          <OptionalItems 
            newClientRegistration={newClientRegistration} 
            setNewClientRegistration={setNewClientRegistration}  
            changeStep={changeStep}
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
          />
          :currentStep === 3 ?
          <ContactDetails 
            newClientRegistration={newClientRegistration} 
            setNewClientRegistration={setNewClientRegistration}  
            changeStep={changeStep} 
            countries={countries}
            signUpErrors={signUpErrors}
            setSignUpErrors={setSignUpErrors}
          />
          :currentStep === 4 ?
          <AcountSetup 
            newClientRegistration={newClientRegistration} 
            setNewClientRegistration={setNewClientRegistration}  
            changeStep={changeStep}
            signUpErrors={signUpErrors}
            setSignUpErrors={setSignUpErrors}
          />
          :currentStep === 5 ?
          <PaymentAndOrder 
            newClientRegistration={newClientRegistration}
            setNewClientRegistration={setNewClientRegistration}
            changeStep={changeStep}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            handleCheckboxChange={handleCheckboxChange}
            setAffinipayPaymentDetailsToken={setAffinipayPaymentDetailsToken}
            signUpErrors={signUpErrors}
            setSignUpErrors={setSignUpErrors}
            affinipayPaymentDetailsToken={affinipayPaymentDetailsToken}
            countries={countries}

          />
          :currentStep === 6 ?
            <SuccessSignUpText/>   
          :null
        }
    </div>
  );
}
export default SignUp;





