import "./termsOfService.scss"

interface ITermsOfServiceProps {
    setShowModalTermsFiles:React.Dispatch<React.SetStateAction<boolean>>
}

const TermsOfService = (termsOfServiceProps:ITermsOfServiceProps) => {
    return (
        <div className="mb-3 termsOfService">
            By clicking "Submit Order", I agree to the:  
            <div className="ps-1 link-td" onClick={()=>{termsOfServiceProps.setShowModalTermsFiles(true)}}>
            Terms and Conditions
            </div>
        </div>
    );
};
export default TermsOfService;