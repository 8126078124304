import { ChangeEvent, useEffect, useState } from "react";
import { IClientDto } from "../../../../models/client/newRegisterAgent/IClientDto";
import "./elementsAndSearchButtonCard.scss"
import { _searchClients, _getAllClients } from "../../../../APIs/agent/AgentApi";
import { BiSearchAlt } from 'react-icons/bi';
import { Button, Modal } from "react-bootstrap";
import { IoPersonAdd } from "react-icons/io5";
import SignUp from "../../../../pages/signUp/SignUp";
import SignUpForm from "../../../signUp/SignUpForm";
import { useParams } from "react-router-dom";

interface AddAndSearchClientsProps {
    clients: IClientDto[];
    setSClients: React.Dispatch<React.SetStateAction<IClientDto[]>>
    setSelectedClient: React.Dispatch<React.SetStateAction<number | undefined>>
    newClient: JSX.Element[]
    showLoadingButton:boolean
  }

const ElementsAndSearchButtonCard = (addAndSearchClientsProps:AddAndSearchClientsProps) => {
  const [clientOrCompanyName, setClientOrCompanyName] = useState("");
  const [isSearchDisabled, setIsSearchDisabled] = useState(true); 
  const [showCreateClientModal, setShowCreateClientModal] = useState(false); 
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); 
  const params = useParams<"id">();

  useEffect(() => {
    if (!(params && params?.id)) {
      getClientsOnLoad();
    } 
  }, []);

  const  getClientsOnLoad = async () => {
    let clients = await _getAllClients(0,100,100)
    addAndSearchClientsProps.setSClients(clients)
    addAndSearchClientsProps.setSelectedClient(undefined)
  } 
  const  getClients = async () => {
    let clients = await _searchClients(clientOrCompanyName)
    addAndSearchClientsProps.setSClients(clients)
    addAndSearchClientsProps.setSelectedClient(undefined)
  } 
    
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setClientOrCompanyName(e.target.value); 
    setIsSearchDisabled(e.target.value === "")
  }
  const createUser = () => {
    setShowCreateClientModal(true);
  };
  const closeModal = () => {
    setShowConfirmationModal(true);
  };
  const closeAllModals = () => {
    setShowCreateClientModal(false);
    setShowConfirmationModal(false);
  };
  
  
  return (
    <div>
      <div className="components-and-search-button-card">
      <Button 
          style={{marginRight:"12px"}}
          className="primaryButton  basic-font w-150px h-100" onClick={createUser} ><IoPersonAdd /> Add Client</Button>
        {
          addAndSearchClientsProps.showLoadingButton ? 
            <div className="cellphone-display-none computer-management-top-buttons">
              <button className="primaryButton  basic-font w-150px h-100" type="button" disabled>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span role="status">Uploading...</span>
              </button>
           </div>
        :
          <div className="cellphone-display-none computer-management-top-buttons">
            {addAndSearchClientsProps.newClient}
          </div>
        }
        
        <input 
          className="searchInput me-2 " 
          placeholder="Client or company name"
          value={clientOrCompanyName}
          onChange={handleInputChange}> 
        </input>
        <button
          className="primaryButton basic-font"
          onClick={() => {getClients()}}
          disabled={isSearchDisabled || addAndSearchClientsProps.showLoadingButton} 
        >
        <span className="search-text">Search </span>
        <BiSearchAlt />
        </button> 
      </div>
      <div className="cellphone-management-top-buttons computer-display-none">
        {addAndSearchClientsProps.newClient}       
      </div>



      <Modal style={{maxHeight:"100%"}} show={showCreateClientModal} onHide={closeModal} >
        <Modal.Header style={{background:"white" , color:"black"}}  closeButton>
          <Modal.Title>Add Client</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background:"white" , color:"black"}}>
          <SignUpForm closeAllModals={closeAllModals}/>
        </Modal.Body>
      </Modal>

      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel? All data entered on this form will be lost permanently.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closeAllModals}>
            Yes
          </Button>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ElementsAndSearchButtonCard;
