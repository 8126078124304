import INewClientRegistration from "../../../models/client/newRegisterAgent/INewClientRegistration";
import Expiration from "./Expiration/Expiration";
import CardNumber from "./cardNumber/CardNumber";
import "./paymentInformation.scss";
import FirstName from "./firstName/FirstName";
import LastName from "./lastName/LastName";
import SecurityCode from "./securityCode/SecurityCode";
import visa from "../../../Assets/images/visa.png";
import mastercard from "../../../Assets/images/mastercard.png";
import americanexpress from "../../../Assets/images/americanexpress.png";
import discover from "../../../Assets/images/discover.png";
import Check from "./check/Check";
import { ISignUpErrors } from "../validation/ISignUpErrors";
import { IAffinipayPaymentToken } from "../../../models/affinipayPayment/affinipayPaymentToken/IAffinipayPaymentToken";
import PostalCode from "./postalCode/PostalCode";

interface IPaymentProps {
  newClientRegistration: INewClientRegistration;
  setNewClientRegistration: React.Dispatch<React.SetStateAction<INewClientRegistration>>;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  handleCheckboxChange: (event: any) => void;
  setAffinipayPaymentDetailsToken: React.Dispatch<React.SetStateAction<IAffinipayPaymentToken>>;
  signUpErrors: ISignUpErrors
  setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>
  affiniPayCardError:string
  affiniPayCvvError:string
  changeStep: Function;
}

const PaymentInformation = (PaymentProps: IPaymentProps) => {
  return (
    <div >
      <div className="form__title">Payment Information</div>
      <label className="form__subTitle">
          We accept all major credit cards.
        <br/>Please double-check your order.
        <br/>All fees paid pass this stage are NON-REFUNDABLE.
      </label>
      <div className="creditCardsImg">
        <img src={visa} alt="visa" />
        <img src={mastercard} alt="mastercard" />
        <img src={americanexpress} alt="americanexpress" />
        <img src={discover} alt="discover" className="discover" />
      </div>
      <form>
        <div className="form__twoInputInOneRow">
          <FirstName
            newClientRegistration={PaymentProps.newClientRegistration}
            setNewClientRegistration={PaymentProps.setNewClientRegistration}
            signUpErrors={PaymentProps.signUpErrors}
            setSignUpErrors={PaymentProps.setSignUpErrors}
          />
          <LastName
            newClientRegistration={PaymentProps.newClientRegistration}
            setNewClientRegistration={PaymentProps.setNewClientRegistration}
            signUpErrors={PaymentProps.signUpErrors}
            setSignUpErrors={PaymentProps.setSignUpErrors}
          />
        </div>
        <div className="form__twoInputInOneRow">
          <CardNumber 
            affiniPayCardError={PaymentProps.affiniPayCardError} 
          />
          <SecurityCode
              affiniPayCvvError={PaymentProps.affiniPayCvvError}
          />
        </div>
        <div className="form__twoInputInOneRow">
          <Expiration
            newClientRegistration={PaymentProps.newClientRegistration}
            setNewClientRegistration={PaymentProps.setNewClientRegistration}
            signUpErrors={PaymentProps.signUpErrors}
            setSignUpErrors={PaymentProps.setSignUpErrors}
          />
          <PostalCode
            newClientRegistration={PaymentProps.newClientRegistration}
            setNewClientRegistration={PaymentProps.setNewClientRegistration}
            signUpErrors={PaymentProps.signUpErrors}
            setSignUpErrors={PaymentProps.setSignUpErrors}
          />
        </div>
          <Check
            isChecked={PaymentProps.isChecked}
            setIsChecked={PaymentProps.setIsChecked}
            handleCheckboxChange={PaymentProps.handleCheckboxChange}
            title={"Use different Billing Address"}
            changeStep={PaymentProps.changeStep}
          />
      </form>
    </div>
  );
};
export default PaymentInformation;

