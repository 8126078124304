import { useEffect } from "react";

interface ICheckProps {
    isChecked: boolean
    setIsChecked:React.Dispatch<React.SetStateAction<boolean>>
    handleCheckboxChange: (event: any) => void
    title: string
    changeStep: Function;
}

const Check = (checkProps:ICheckProps) => {

    return (
    <div className="form-check">
        <input 
            className="form-check-input" 
            type="checkbox" 
            id="flexCheckDefault" 
            checked={checkProps.isChecked} 
            onChange={(event)=>{checkProps.handleCheckboxChange(event)
             }}/>
        <label className="form-check-label" htmlFor="flexCheckDefault">
            {checkProps.title}
        </label>
    </div>
    );
};
export default Check;