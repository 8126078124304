import INewClientRegistration from "../../../models/client/newRegisterAgent/INewClientRegistration";
import IContactDetails from "../../../models/client/newRegisterAgent/differentBillingAddress/IDifferentBillingAddress";
import { ISignUpErrors } from "../validation/ISignUpErrors";
import Address from "./address/Address";
import City from "./city/City";
import Country from "./country/Country";
import { useEffect, useState } from "react";
import StateProvince from "./stateProvince/StateProvince";
import { IDifferentBillingAddressError } from "./validation/IDifferentBillingAddressError";
import ZipPostal from "./zipPostal/ZipPostal";
import { ICountryDto } from "../../../models/country/ICountryDto";
import DifferentBillingAddressNew from "../../../models/client/newRegisterAgent/differentBillingAddress/DifferentBillingAddress";

interface IBillingAddressProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    countries: ICountryDto[]
    signUpErrors: ISignUpErrors
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>
    changeStep: Function
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>
    setBillingErrors: React.Dispatch<React.SetStateAction<boolean>>
}

const DifferentBillingAddress = (billingAddressProps: IBillingAddressProps) => {
    const [areRequiredFieldsUndefined, setAreRequiredFieldsUndefined] = useState<boolean>(true);
    const [areTouchedFieldsWithErrors, setAreTouchedFieldsWithErrors] = useState<boolean>(true);

    useEffect(() => {
        let areRequiredFieldsUndefined  = evaluateRequireUndefinedFields(billingAddressProps.signUpErrors.differentBillingAddressError, billingAddressProps.newClientRegistration.differentBillingAddress)
        setAreRequiredFieldsUndefined(areRequiredFieldsUndefined)
        billingAddressProps.setBillingErrors(areTouchedFieldsWithErrors ||  areRequiredFieldsUndefined);
    }, [billingAddressProps.newClientRegistration.differentBillingAddress, billingAddressProps.signUpErrors.differentBillingAddressError]);

    useEffect(() => {
        let areTouchedFieldsWithErrors  = evaluateTouchedFieldsWithErrors(billingAddressProps.signUpErrors.differentBillingAddressError)
        setAreTouchedFieldsWithErrors(areTouchedFieldsWithErrors)
        billingAddressProps.setBillingErrors(areTouchedFieldsWithErrors || areRequiredFieldsUndefined);
    }, [billingAddressProps.signUpErrors.differentBillingAddressError]);

    const restoreDifferentBillingAddress = () => {
        billingAddressProps.setNewClientRegistration({
            ...billingAddressProps.newClientRegistration,
            differentBillingAddress:  new DifferentBillingAddressNew()
    });
    }
    
    return (
        <div>
            <div className="form__title">Different Billing Address</div>  
            <form>
                <Country 
                newClientRegistration={billingAddressProps.newClientRegistration} 
                setNewClientRegistration={billingAddressProps.setNewClientRegistration} countries={billingAddressProps.countries}/>
                <Address 
                newClientRegistration={billingAddressProps.newClientRegistration} 
                setNewClientRegistration={billingAddressProps.setNewClientRegistration}
                signUpErrors={billingAddressProps.signUpErrors}
                setSignUpErrors={billingAddressProps.setSignUpErrors}/>
                <City 
                newClientRegistration={billingAddressProps.newClientRegistration} 
                setNewClientRegistration={billingAddressProps.setNewClientRegistration}
                signUpErrors={billingAddressProps.signUpErrors}
                setSignUpErrors={billingAddressProps.setSignUpErrors}/>
                <div className="form__twoInputInOneRow">
                    <StateProvince 
                    newClientRegistration={billingAddressProps.newClientRegistration} 
                    setNewClientRegistration={billingAddressProps.setNewClientRegistration}
                    signUpErrors={billingAddressProps.signUpErrors}
                    setSignUpErrors={billingAddressProps.setSignUpErrors}/>
                    <ZipPostal 
                    newClientRegistration={billingAddressProps.newClientRegistration} 
                    setNewClientRegistration={billingAddressProps.setNewClientRegistration}
                    signUpErrors={billingAddressProps.signUpErrors}
                    setSignUpErrors={billingAddressProps.setSignUpErrors}/>
                </div>  
            </form>
        </div>
    );
};
export default DifferentBillingAddress;

const evaluateRequireUndefinedFields = (errors: IDifferentBillingAddressError, contactDetails: IContactDetails): boolean => {
    let areRequiredFieldsUndefined = false;
    const keys = Object.keys(contactDetails) as (keyof typeof contactDetails)[];
    var isAnyRequireUndefinedField = keys.find(key => (contactDetails[key] === undefined || (contactDetails[key]!.toString().length === 0)) && errors[key].required === true);
    areRequiredFieldsUndefined = isAnyRequireUndefinedField !== undefined;
    return areRequiredFieldsUndefined;
};
 
const evaluateTouchedFieldsWithErrors = (errors: IDifferentBillingAddressError): boolean => {
    let areTouchedFieldsWithErrors = false;
    let errorFields = Object.values(errors);
    let erroredFields = errorFields.filter(errorField => errorField.hasOwnProperty("message"));
    var isAnyFieldsWithErrors =  erroredFields.find(error => error.message?.length > 0);
    areTouchedFieldsWithErrors = isAnyFieldsWithErrors !== undefined;
    return areTouchedFieldsWithErrors;
};
