import { useEffect, useState } from "react";
import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { SelectNotifyAttorneyModal } from "./SelectNotifyAttorneyModal";
import "./selectNotifyAttorney.scss"

interface INotifyAttorneyProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
}

const SelectNotifyAttorney = (notifyAttorney:INotifyAttorneyProps) => {
const [colorFirstView, setColorFirstView] = useState("");
const [selectedValue, setSelectedValue] = useState(
    notifyAttorney.newClientRegistration.companyInformation.attorneyLawsuit === undefined ? '0' : notifyAttorney.newClientRegistration.companyInformation.attorneyLawsuit ? '1' : '2' 
  );
  
  useEffect(() => {
    setSelectedValue(notifyAttorney.newClientRegistration.companyInformation.attorneyLawsuit === undefined ? '0' : notifyAttorney.newClientRegistration.companyInformation.attorneyLawsuit ? '1' : '2' );
  }, [notifyAttorney.newClientRegistration.companyInformation.attorneyLawsuit]);
  

    return (
        <div className="mb-3">
            <div className="signUp-input-titles-text p-0 m-0">
            Notify Your Attorney of a Lawsuit?  
            </div>
            <div  className="notifyattorney">
                <select
                    value={selectedValue}
                    className={`form-select ${colorFirstView === "" ? "color-default-option-selected" : ""}`}
                    disabled={false}
                    onChange={(e) => {
                        setColorFirstView(e.target.value);
                        const isTrueSelected = e.target.value === "1";
                        notifyAttorney.setNewClientRegistration({
                        ...notifyAttorney.newClientRegistration,
                        companyInformation: {
                            ...notifyAttorney.newClientRegistration.companyInformation,
                            attorneyLawsuit: isTrueSelected,
                        },
                        });
                    }}
                    >
                    <option value="0" disabled></option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                </select>
                <SelectNotifyAttorneyModal/>
            </div>
         </div>
    );
};
export default SelectNotifyAttorney;