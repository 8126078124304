import { useEffect, useState } from "react";
import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ICountryDto } from "../../../../models/country/ICountryDto";
import { ISignUpErrors } from "../../validation/ISignUpErrors";
import StateProvinceDropDown from "./StateProvinceDropDown";
import StateProvinceTextBox from "./StateProvinceTextBox";

interface StateAndProvinceProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
    countries: ICountryDto[]
}
const StateProvince = (stateAndProvinceProps: StateAndProvinceProps) => {
    const USCountryName = "UNITED STATES"
    const [UsaConutry, setUsaConutry] = useState<ICountryDto | undefined>(undefined);

    useEffect(() => {
        const foundCountry = stateAndProvinceProps.countries.find((country) => country.name === USCountryName);
        setUsaConutry(foundCountry);
    }, [stateAndProvinceProps.countries]);

    const setStateProvinceTextBoxError = (error:string) => {
        stateAndProvinceProps.setSignUpErrors(prevState => ({
            ...prevState,
            contactDetailsErrors: {
                ...prevState.contactDetailsErrors,
                stateProvince: {
                    ...prevState.contactDetailsErrors.stateProvince,
                    message: error
                }
            }
        }));
    }

    return (
        Number(stateAndProvinceProps.newClientRegistration.contactDetails.country) === UsaConutry?.id?
            <StateProvinceDropDown
                 newClientRegistration={stateAndProvinceProps.newClientRegistration} 
                 setNewClientRegistration={stateAndProvinceProps.setNewClientRegistration}
                 signUpErrors={stateAndProvinceProps.signUpErrors}
                 setSignUpErrors={stateAndProvinceProps.setSignUpErrors}
                 setStateProvinceTextBoxError={setStateProvinceTextBoxError}
            />
        : 
            <StateProvinceTextBox
                newClientRegistration={stateAndProvinceProps.newClientRegistration} 
                setNewClientRegistration={stateAndProvinceProps.setNewClientRegistration}
                signUpErrors={stateAndProvinceProps.signUpErrors}
                setSignUpErrors={stateAndProvinceProps.setSignUpErrors}
                setStateProvinceTextBoxError={setStateProvinceTextBoxError}
            />
        
    );
};
export default StateProvince;