import { _getClientDocuments } from "../../../APIs/clientDocument/ClientDocument";
import { IService } from "../../../models/service/IService";
import { IServicesPackageDto } from "../../../models/servicesPackage/IServicesPackageDto";
import "../../../pages/clients/addons/Addons"

interface PackagesCardProps {
    key:number
    selectedService:IService;
    selectedPackagesCardIds: {serviceId:number,packageId:number}[];
    setSelectedPackagesCardIds:React.Dispatch<React.SetStateAction<{
        serviceId: number;
        packageId: number;
    }[]>>
  }

const PackagesCard = (packagesCardProps: PackagesCardProps) => {
    const handlePackageCardClick = (servicePackage: IServicesPackageDto) => {
        const newPackages = packagesCardProps.selectedPackagesCardIds.filter((selectedPackageCardId) => {
            return selectedPackageCardId.serviceId !== servicePackage.serviceId;
        });
        const newPackage = {
            serviceId: packagesCardProps.selectedService.id,
            packageId: servicePackage.id
        };
        newPackages.push(newPackage)
        packagesCardProps.setSelectedPackagesCardIds(newPackages);
    };

    const isCardSelected = (servicePackage: IServicesPackageDto): boolean => {
        var servicePackageOjbect =  packagesCardProps.selectedPackagesCardIds.find((element)=> element.serviceId===servicePackage.serviceId)
        if (servicePackageOjbect?.packageId === servicePackage.id) {
            return true
        } 
        return(false)
        };

    return(
        <div className="package">
            <div className="package__service-header generalTitles">
                {packagesCardProps.selectedService.name}
            </div>
            <div className="package__service-body bm-3">
                {packagesCardProps.selectedService.servicesPackages.map((servicePackage)=>
                    <div   
                        className={`client-addons-cards package__card card-basic-style card-on-hover ${isCardSelected(servicePackage)?"client-addons-cards__selected":"client-addons-cards__unselected"}`}
                        onClick={()=>{handlePackageCardClick(servicePackage)}}
                    >
                        <div className="client-addons-cards__title">
                            {servicePackage.name}
                        </div>
                        <div  className="client-addons-cards__description">
                            {servicePackage.description}
                        </div>
                        <div  className="client-addons-cards__amount">
                            ${servicePackage.amount}
                        </div>
                        <div  className="client-addons-cards__foot">
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PackagesCard;