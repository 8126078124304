import { Navigate } from 'react-router-dom'
import SessionUser from '../../models/user/SessionUser';
import { _getSession, _getSessionUser, _getUserType } from '../../services/session/SessionService';

interface IProps {
  children: JSX.Element;
}

const ProviderRoutes = (props: IProps): any => {
  let session = _getSession();
  if(session === null) {
      return <Navigate to='/login'/>;
  }
  let user: SessionUser = _getSessionUser(session);
  let userType = _getUserType(user);

  if(userType === 'agent'){
      return props.children;
  }
  else if(userType ==="client"){
      return <Navigate to='/client/home'/>;
  }
  else{
      return <Navigate to='/error'/>; // should never happen
  }
};

export default ProviderRoutes;
