import ClientProfile from "./clientProfileCard/ClientProfileCard";
import Documents from "./documentsCard/DocumentsCard";
import Invoices from "./invoicesCard/InvoicesCard";
import Services from "./servicesCard/ServicesCard";
import "./clientDashboard.scss"
import { IClientDto } from "../../../../models/client/newRegisterAgent/IClientDto";
import { useEffect } from "react";
import { _getClientById } from "../../../../APIs/client/ClientApi";
import { useLocation, useNavigate } from "react-router-dom";

interface ClientDashboardProps {
    selectedClient: number;
    clienSelectedInformation: IClientDto;
    setClienSelectedInformation: React.Dispatch<React.SetStateAction<IClientDto>>;
    setShowLoadingButton: React.Dispatch<React.SetStateAction<boolean>>

  }
  
const ClientDashboardCards = (clientDashboardProps:ClientDashboardProps) => {

  const navigate = useNavigate(); 
    useEffect(() => {
        if (clientDashboardProps.selectedClient) {
          getClientInformation();
            navigate("/provider/clients/"+clientDashboardProps.selectedClient);
        }
      }, [clientDashboardProps.selectedClient]);
    
    
    const  getClientInformation = async () => {
        let clientInformation = await _getClientById(clientDashboardProps.selectedClient)
        if(clientInformation){
          clientDashboardProps.setClienSelectedInformation(clientInformation)
        }
    } 

    return (
        <div className="clientDashboardCards">
          <ClientProfile clienSelectedInformation={clientDashboardProps.clienSelectedInformation}/>
          <Documents clienSelectedInformation={clientDashboardProps.clienSelectedInformation} setShowLoadingButton={clientDashboardProps.setShowLoadingButton}/>
          <Invoices clienSelectedInformation={clientDashboardProps.clienSelectedInformation}/>
          <Services  clienSelectedInformation={clientDashboardProps.clienSelectedInformation}/> 
        </div>
    );
};

export default ClientDashboardCards;
