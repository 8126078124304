import INewClientRegistration from "../../../models/client/newRegisterAgent/INewClientRegistration";
import BoxTextNameCompany from "./nameCompany/BoxTextNameCompany";
import ChangingRegisteredAgents from "./changeRegisteredAgents/ChangingRegisteredAgents";
import SelectBusinessEntityType from "./businessEntityType/SelectBusinessEntityType";
import SelectNotifyAttorney from "./notifyAttorney/SelectNotifyAttorney";
import { useState, useEffect } from "react";
import { ICompanyInformationErrors } from "./validation/ICompanyInformationErrors";
import ICompanyInformation from "../../../models/client/newRegisterAgent/companyInformation/ICompanyInformation";
import { ISignUpErrors } from "../validation/ISignUpErrors";
import BusinessAddressAndPaidMail from "./businessAddressAndPaidMail/BusinessAddressAndPaidMail";
import AttorneyEmail from "./notifyAttorney/AttorneyEmail";

interface CompanyInformationProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    changeStep: Function
    signUpErrors: ISignUpErrors
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>
}

const CompanyInformation = (companyInformationProps: CompanyInformationProps) => {
    const [areRequiredFieldsUndefined, setAreRequiredFieldsUndefined] = useState<boolean>(true);
    const [areTouchedFieldsWithErrors, setAreTouchedFieldsWithErrors] = useState<boolean>(true);
  
    const handleStateClick = (goTo:string) => {
        companyInformationProps.changeStep(goTo)
    }

    const setErrorAttorneyEmail = (error: string) => {
        companyInformationProps.setSignUpErrors(prevState => ({
            ...prevState,
            companyInformationErrors: {
                ...prevState.companyInformationErrors,
                attorneyEmail: {
                    ...prevState.companyInformationErrors.attorneyEmail,
                    message: error
                }
            }
        }));
    }

    const setAttorneyEmail = (value: string) => {
        companyInformationProps.setNewClientRegistration({
            ...companyInformationProps.newClientRegistration,
            companyInformation: {
                ...companyInformationProps.newClientRegistration.companyInformation,
                attorneyEmail: value
            }       
        });
    }

    const changingRegisteredAgentsSubscription = (props: {name: string, amount: number}) => {
        companyInformationProps.setNewClientRegistration({
            ...companyInformationProps.newClientRegistration,
            companyInformation: {
                ...companyInformationProps.newClientRegistration.companyInformation,
                changingRegisteredAgentsSubscription: {name: props.name, amount: props.amount}
            }       
        });
    }

    useEffect(() => {
        companyInformationProps.newClientRegistration.companyInformation.attorneyLawsuit?
        setErrorAttorneyEmail("Attorney email is required")
        :
        setErrorAttorneyEmail("")
        setAttorneyEmail("")

    }, [companyInformationProps.newClientRegistration.companyInformation.attorneyLawsuit]);

    useEffect(() => {
        let areRequiredFieldsUndefined  = evaluateRequireUndefinedFields(companyInformationProps.signUpErrors.companyInformationErrors, companyInformationProps.newClientRegistration.companyInformation)
        let areTouchedFieldsWithErrors  = evaluateTouchedFieldsWithErrors(companyInformationProps.signUpErrors.companyInformationErrors)
        setAreTouchedFieldsWithErrors(areTouchedFieldsWithErrors)
        setAreRequiredFieldsUndefined(areRequiredFieldsUndefined)
    }, [companyInformationProps.newClientRegistration.companyInformation, companyInformationProps.signUpErrors.companyInformationErrors]);

    useEffect(() => {
        companyInformationProps.newClientRegistration.companyInformation.changingRegisteredAgents?
        changingRegisteredAgentsSubscription({name: "Changing Registered Agents", amount: 50}):changingRegisteredAgentsSubscription({name: "", amount: 0})
    }, [companyInformationProps.newClientRegistration.companyInformation.changingRegisteredAgents]);

    return (
        <div className="form">
            <div className="form__title">Company Information</div>  
            <form>
                <BoxTextNameCompany 
                    newClientRegistration={companyInformationProps.newClientRegistration} 
                    setNewClientRegistration={companyInformationProps.setNewClientRegistration} 
                    signUpErrors={companyInformationProps.signUpErrors}
                    setSignUpErrors={companyInformationProps.setSignUpErrors}
                />
                <SelectBusinessEntityType newClientRegistration={companyInformationProps.newClientRegistration} setNewClientRegistration={companyInformationProps.setNewClientRegistration}/>
                <SelectNotifyAttorney newClientRegistration={companyInformationProps.newClientRegistration} setNewClientRegistration={companyInformationProps.setNewClientRegistration}/>
                    {companyInformationProps.newClientRegistration.companyInformation.attorneyLawsuit ?
                     <AttorneyEmail 
                     newClientRegistration={companyInformationProps.newClientRegistration} 
                     setNewClientRegistration={companyInformationProps.setNewClientRegistration}
                     signUpErrors={companyInformationProps.signUpErrors}
                     setSignUpErrors={companyInformationProps.setSignUpErrors}
                     setErrorAttorneyEmail={setErrorAttorneyEmail}
                     setAttorneyEmail={setAttorneyEmail}
                    />
                    : null
                    }
                <ChangingRegisteredAgents newClientRegistration={companyInformationProps.newClientRegistration} setNewClientRegistration={companyInformationProps.setNewClientRegistration}/>
                <BusinessAddressAndPaidMail 
                newClientRegistration={companyInformationProps.newClientRegistration} 
                setNewClientRegistration={companyInformationProps.setNewClientRegistration}
                signUpErrors={companyInformationProps.signUpErrors}
                setSignUpErrors={companyInformationProps.setSignUpErrors}/>
            </form>
            <div className="form__stageButtons">
            <button
                type="button"
                className={`primaryButton w-35 h-37${ areTouchedFieldsWithErrors || areRequiredFieldsUndefined ? 'disabled' : ''}`}
                onClick={() => handleStateClick("next")}
                disabled={areTouchedFieldsWithErrors || areRequiredFieldsUndefined}>
                Next
            </button>
            </div>
        </div>
    );
};
export default CompanyInformation;

const evaluateRequireUndefinedFields = (errors: ICompanyInformationErrors, companyInformation: ICompanyInformation): boolean => {
    let areRequiredFieldsUndefined = false;
    const keys = Object.keys(companyInformation) as (keyof typeof companyInformation)[];
    var isAnyRequireUndefinedField = keys.find(key => (companyInformation[key] === undefined || (companyInformation[key]!.toString().length === 0)) && errors[key].required === true);
    areRequiredFieldsUndefined = isAnyRequireUndefinedField !== undefined;
    return areRequiredFieldsUndefined;
};
 
const evaluateTouchedFieldsWithErrors = (errors: ICompanyInformationErrors): boolean => {
    let areTouchedFieldsWithErrors = false;
    let errorFields = Object.values(errors);
    let erroredFields = errorFields.filter(errorField => errorField.hasOwnProperty("message"));
    var isAnyFieldsWithErrors =  erroredFields.find(error => error.message?.length > 0);
    areTouchedFieldsWithErrors = isAnyFieldsWithErrors !== undefined;
    return areTouchedFieldsWithErrors;
};