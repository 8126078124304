import Logout from "../logout/Logout";
import "./sidebar.scss";
import { ClientNavItems } from "./ClientNavItems";
import { ProviderNavItems } from "./ProviderNavItems";
import { useContext } from "react";
import { UserContext } from "../../context/UserContextProvider";
import {_getUserType } from "../../services/session/SessionService";
import { SidebarContext } from "../../context/SidebarContextProvider";
import { AiOutlineCloseCircle } from 'react-icons/ai';

const Sidebar = () => {
  // sidebar is rendered once the user logs in.
  // we have a valid session (valid user type ) well defined at that moment.
  const userContext = useContext(UserContext);
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext); 
  let userType = _getUserType(userContext.user);

  const getNavItems = (): JSX.Element => {
    let navItems: JSX.Element;
    switch(userType){
      case'client': {
        navItems = <ClientNavItems />;
        break;
      }
      case 'agent': {
        navItems = <ProviderNavItems/>;
        break;
      }
      default: {
        navItems = <div></div>
        break;
      }
    }
    return navItems;
  }

  return (
    <div className={` ${isSidebarOpen ? "sidebar" : "sidebar sidebar__close"}`}>
      <div  className="closeButton" onClick={()=>{setIsSidebarOpen(false)}}><AiOutlineCloseCircle size={"50px"} title={"closeSidebar"} color={"#ECEEF5"}/></div>
      <div className="header">
        {/* Consider removing below, since we don't have yet defined user images in our system, and this adds extra complexity */}
        <img
          alt="logo"
          className="header__picture"
          src={require("./person.png")}
        />
         {userType === "agent" ?
          <div className="header__name"></div> 
          : 
          <div className="header__name">{userContext.user.firstName } {userContext.user.lastName}</div> 
        }
      </div>
      { 
        getNavItems()
      }
      <Logout />
    </div>
  );
};
export default Sidebar;
