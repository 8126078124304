import React, { useEffect } from 'react';
import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import "./total.scss"

interface ITotalProps {
    setTotalAmount: React.Dispatch<React.SetStateAction<number>>;
    totalAmount: number;
    newClientRegistration: INewClientRegistration;
}

const Total = (totalProps: ITotalProps) => {

    useEffect(() => {
        let totalAmount = 0;
        if (totalProps.newClientRegistration.companyInformation.serviceSubscriptions && totalProps.newClientRegistration.companyInformation.serviceSubscriptions.length > 0 ) {
            totalAmount += totalProps.newClientRegistration.companyInformation.serviceSubscriptions[0].servicePackageAmount;
        }
        if (totalProps.newClientRegistration.optionalRegistrationServices.serviceSubscriptions) {
            totalProps.newClientRegistration.optionalRegistrationServices.serviceSubscriptions.forEach((selectedService) => {
                if (selectedService.servicePackageId) {
                    totalAmount += selectedService.servicePackageAmount;
                }
            });
        }
        if (totalProps.newClientRegistration.companyInformation.changingRegisteredAgents === true) {
            totalAmount += totalProps.newClientRegistration.companyInformation.changingRegisteredAgentsSubscription.amount;
        }
        totalProps.setTotalAmount(totalAmount);
      }, []);
    
    return (
        <div className="mb-3 total">
            <label className="totalText">Total</label>
            <div className="totalValue">${totalProps.totalAmount.toFixed(2)}</div>
        </div>
    );
};

export default Total;