import { useEffect, useState } from "react";
import { _getClientDocuments } from "../../../APIs/clientDocument/ClientDocument";
import { IService } from "../../../models/service/IService";
import { IServicesPackageDto } from "../../../models/servicesPackage/IServicesPackageDto";
import "../../../pages/clients/addons/Addons";
import AddonPaymentCard from "../addon/paymentCards/addonSummary/AddonPaymentCard";
import AffinipayPaymentToken from "../../../models/affinipayPayment/affinipayPaymentToken/AffinipayPaymentToken";
import {
  IAffiniPayState,
  IAffinipayPaymentInformation,
} from "../../../models/affinipayPayment/aaffinipayPayment/IAffinipayPayment";
import { initAffiniPay } from "../../signUp/paymenyInformation/cardNumber/InitAffiniPay";
import IPaymentInformation from "../../../models/client/newRegisterAgent/paymentInformation/IPaymentInformation";
import PaymentInformation from "../../../models/client/newRegisterAgent/paymentInformation/PaymentInformation";
import {
  _getMultipleInvoiceList,
  _packagesSubscription,
  _payInvoices,
} from "../../../APIs/client/ClientApi";
import InvoiceTotalAmountCard from "./InvoiceTotalAmountCard";
import { IClientInvoicePymentSummary } from "../../../models/clientInvoice/IClientInvoicePymentSummary";
import InvoiceSummaryCard from "./InvoiceSummaryCard";
import InvoicePaymentCard from "./InvoicePaymentCard";

interface InvoicePaymentCardsProps {
  selectedInvoiceIds: string[];
}

const InvoicePaymentCards = (paymentCardsProps: InvoicePaymentCardsProps) => {
  const [selectedServicesAndPackages, setSelectedServicesAndPackages] =
    useState<
      { selectedService: IService; selectedPackage: IServicesPackageDto }[]
    >([]);
  const [paymentInformation, setPaymentInformation] =
    useState<IPaymentInformation>(new PaymentInformation());
  const [hostedFields, setHostedFields] =
    useState<IAffinipayPaymentInformation>();
  const [affiniPayGeneratingTokenError, setAffiniPayGeneratingTokenError] =
    useState<string>("");
  const [affiniPayCardError, setAffiniPayCardError] = useState<string>("");
  const [affiniPayCvvError, setAffiniPayCvvError] = useState<string>("");
  const [areRequiredFieldsUndefined, setAreRequiredFieldsUndefined] =
    useState<boolean>(true);
  const [areTouchedFieldsWithErrors, setAreTouchedFieldsWithErrors] =
    useState<boolean>(true);
  const [showLoadingButton, setShowLoadingButton] = useState<boolean>(false);
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [invoiceListData, setInvoiceListData] = useState<
    IClientInvoicePymentSummary[]
  >([]);

  useEffect(() => {
    initInvoices();
    initAffiniPay(setHostedFields, hostedFieldsCallBack);
  }, []);

  const initInvoices = async () => {
    var invoices: IClientInvoicePymentSummary[] = await _getMultipleInvoiceList(
      paymentCardsProps.selectedInvoiceIds
    );
    setInvoiceListData(invoices);
  };

  const hostedFieldsCallBack = function (state: IAffiniPayState) {
    var cardError = state.fields[0].error;
    setAffiniPayCardError(cardError);

    var cvvError = state.fields[1].error;
    setAffiniPayCvvError(cvvError);
  };

  useEffect(() => {
    if (
      affiniPayGeneratingTokenError &&
      affiniPayGeneratingTokenError.length > 0
    ) {
      setTimeout(() => {
        hostedFields?.clearSavedPaymentMethod();
        setAffiniPayGeneratingTokenError("");
      }, 3500);
    }
  }, [affiniPayGeneratingTokenError]);

  useEffect(() => {
    if (isPaid) {
      setTimeout(() => {
        window.location.reload();
      }, 3500);
    }
  }, [isPaid]);

  const getAffiniPayToken = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowLoadingButton(true);
    event.preventDefault();
    if (!hostedFields || !hostedFields.getState()) {
      console.error("Hosted fields not ready");
      return;
    }
    try {
      let affinipayPaymentToken: AffinipayPaymentToken =
        await hostedFields.getPaymentToken({
          exp_year: 20 + paymentInformation.expiration.split("/")[1],
          exp_month: paymentInformation.expiration.split("/")[0],
          postal_code: paymentInformation.postalCode,
          name: `${paymentInformation.firstName} ${paymentInformation.lastName}`,
        });
        
      _payInvoices(
        paymentCardsProps.selectedInvoiceIds,
        affinipayPaymentToken.id
      );
      setShowLoadingButton(false);
      setIsPaid(true);
    } catch (error: any) {
      setAffiniPayGeneratingTokenError({ error: error.message }.error);
      setShowLoadingButton(false);
    }
  };

  return (
    <div className="payment">
      <div className="payment__summary card-basic-style basic-scroll">
        <InvoiceSummaryCard
          ClientInvoicePaymentSummarys={invoiceListData}
          showLoadingButton={false}
        />
      </div>
      <div className="payment__payAndSummaryRow ">
        <InvoicePaymentCard
          affiniPayCardError={affiniPayCardError}
          affiniPayCvvError={affiniPayCvvError}
          paymentInformation={paymentInformation}
          setPaymentInformation={setPaymentInformation}
          setAreTouchedFieldsWithErrors={setAreTouchedFieldsWithErrors}
          setAreRequiredFieldsUndefined={setAreRequiredFieldsUndefined}
        />
        <InvoiceTotalAmountCard
          ClientInvoicePaymentSummarys={invoiceListData}
          getAffiniPayToken={getAffiniPayToken}
          affiniPayGeneratingTokenError={affiniPayGeneratingTokenError}
          affiniPayCardError={affiniPayCardError}
          affiniPayCvvError={affiniPayCvvError}
          areTouchedFieldsWithErrors={areTouchedFieldsWithErrors}
          areRequiredFieldsUndefined={areRequiredFieldsUndefined}
          showLoadingButton={showLoadingButton}
          isPaid={isPaid}
        />
      </div>
    </div>
  );
};

export default InvoicePaymentCards;
