import ISessionUser from "./SessionUser";

export default class SessionUser implements ISessionUser {
  constructor(
      id: number | undefined = undefined, 
      email: string | undefined = undefined,
      clientId: string | undefined = undefined,
      agentId: string | undefined = undefined,
      role: string | undefined = undefined,
      companyName: string | undefined = undefined,
      firstName: string | undefined = undefined,
      lastName: string | undefined = undefined,
      exp: number | undefined = undefined,
      iss: string | undefined = undefined,
      aud: string | undefined = undefined)
  {
      this.id = id;
      this.email = email;
      this.clientId = clientId;
      this.agentId = agentId;
      this.role = role;
      this.companyName = companyName;
      this.firstName = firstName;
      this.lastName = lastName;
      this.exp = exp;
      this.iss = iss;
      this.aud = aud;
}

  id: number | undefined;
  email: string | undefined;
  clientId: string | undefined;
  agentId: string | undefined; 
  role: string | undefined;
  companyName: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  exp: number | undefined;
  iss: string | undefined;
  aud: string | undefined;
}