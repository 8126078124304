import axios from "axios";

export const axiosInit = () => {
  axios.defaults.baseURL = process.env.REACT_APP_REGISTEREDAGENTSSERVICESAPI_URL!;
  axios.defaults.headers.common["Content-Type"] = "application/json";
};

// configure interceptors: pick auth header from session storage - add it to all requests
axios.interceptors.request.use(function (config) {
  let authorizationHeader = getAuthorizationHeader();
  if (config.headers !== undefined) {
    config.headers.Authorization = authorizationHeader; 
  }
  return config;
});

function getAuthorizationHeader() {
  let auththorizationHeader = "";
  let session = sessionStorage.getItem(process.env.REACT_APP_SESSION_KEY!);
  if (session !== null) {
    auththorizationHeader = "Bearer " + session;
  }
  return auththorizationHeader;
}

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error.response.data); // consider redirecting to a Error page or raising a modal with the error.
  }
);

export default axiosInit;
