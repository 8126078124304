import axios from "axios";
import { IService } from "../../models/service/IService";

const baseUrl = "service"

async function _getServicesByCategory(categoryName:string): Promise<IService[]> {
  let response = await axios.get(`${baseUrl}/category/`+categoryName)
  return response.data;
}

export  {_getServicesByCategory, };

