import { useEffect, useState } from "react";
import "../../pages/signUp/signUp.scss"
import INewClientRegistration from "../../models/client/newRegisterAgent/INewClientRegistration";
import NewClientRegistration from "../../models/client/newRegisterAgent/NewClientRegistration";
import ContactDetails from "./contactDetails/ContactDetails";
import AcountSetup from "./acountSetup/AcountSetup";
import { ICountryDto } from "../../models/country/ICountryDto";
import { _getCountries } from "../../APIs/country/Country";
import OptionalItems from "./optionalItems/OptionalItems";
import singUpErrors, { ISignUpErrors } from "./validation/ISignUpErrors";
import CompanyInformation from "./companyInformation/CompanyInformation";
import OrderSummaryWithoutPayment from "./orderSummary/OrderSummaryWithoutPayment";
import { IServiceSubscriptionsOptional } from "../../models/client/newRegisterAgent/companyInformation/serviceSubscriptions/IServiceSubscriptions";

interface SignUpProps {
  closeAllModals: () => void;
}

const SignUpForm = ({closeAllModals}:SignUpProps) => {
  const [newClientRegistration , setNewClientRegistration] = useState<INewClientRegistration>(new NewClientRegistration());
  const [currentStep, setCurrentStep] = useState(1);
  const [countries, setCountries] = useState<ICountryDto[]>([]);
  const [signUpErrors, setSignUpErrors] = useState<ISignUpErrors>(singUpErrors);
  const [selectedServices, setSelectedServices] = useState<IServiceSubscriptionsOptional[]>([]);

  useEffect(() => {
    initCountries()
  }, []);

  const initCountries = async () => {
    const countries = await _getCountries();
    setCountries(countries);
  };

  function changeStep(step : string) {
    if (step==="next")
      {setCurrentStep(currentStep+1)}
    else if  (step==="back")
      {setCurrentStep(currentStep-1)}
    else if  (step==="nextFlow")
      {setCurrentStep(currentStep+0.1)}
    else if  (step==="backFlow")
      {setCurrentStep(currentStep-0.1)}
    }

 
  return (
    <div className="signUp">
        {
          currentStep === 1 ?
          <CompanyInformation 
            newClientRegistration={newClientRegistration} 
            setNewClientRegistration={setNewClientRegistration}  
            changeStep={changeStep}
            signUpErrors={signUpErrors}
            setSignUpErrors={setSignUpErrors}
          />
          :currentStep === 2 ?
          <OptionalItems 
            newClientRegistration={newClientRegistration} 
            setNewClientRegistration={setNewClientRegistration}  
            changeStep={changeStep}
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
          />
          :currentStep === 3 ?
          <ContactDetails 
            newClientRegistration={newClientRegistration} 
            setNewClientRegistration={setNewClientRegistration}  
            changeStep={changeStep} 
            countries={countries}
            signUpErrors={signUpErrors}
            setSignUpErrors={setSignUpErrors}
          />
          :currentStep === 4 ?
          <AcountSetup 
            newClientRegistration={newClientRegistration} 
            setNewClientRegistration={setNewClientRegistration}  
            changeStep={changeStep}
            signUpErrors={signUpErrors}
            setSignUpErrors={setSignUpErrors}
          />
          :currentStep === 5 ?
          <OrderSummaryWithoutPayment
            newClientRegistration={newClientRegistration}
            setNewClientRegistration={setNewClientRegistration}
            changeStep={changeStep}
            closeAllModals={closeAllModals}
          /> 
          :null
        }
    </div>
  );
}
export default SignUpForm;





