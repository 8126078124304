import IDifferentBillingAddress from "./IDifferentBillingAddress";

export default class DifferentBillingAddress implements IDifferentBillingAddress {
    country: string;
    address: string;
    city: string;
    stateProvince: string;
    zipPostal: string;
  
  constructor(
    country: string = "",
    address: string = "",
    city: string = "",
    stateProvince: string = "",
    zipPostal: string = "",
  ) {
    this.country = country;
    this.address = address;
    this.city = city;
    this.stateProvince = stateProvince;
    this.zipPostal = zipPostal;
  }
}
