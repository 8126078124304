import ICompanyInformation from "./ICompanyInformation";
import IBusinessEntityType from "./businessEntityType/IBusinessEntityType";
import IServiceSubscriptions from "./serviceSubscriptions/IServiceSubscriptions";

export default class CompanyInformation implements ICompanyInformation {
  constructor(
    companyName: string = "",
    businessEntityType: IBusinessEntityType | undefined = undefined,
    attorneyLawsuit:  boolean | undefined = undefined,
    attorneyEmail:  string = "",
    changingRegisteredAgents:  boolean | undefined = undefined,
    changingRegisteredAgentsSubscription: { name: string, amount: number } = { name: "", amount: 0 },
    serviceSubscriptions: IServiceSubscriptions [] | undefined = []
  ) {
    this.companyName = companyName;
    this.businessEntityType = businessEntityType;
    this.attorneyLawsuit = attorneyLawsuit;
    this.attorneyEmail = attorneyEmail;
    this.changingRegisteredAgents = changingRegisteredAgents;
    this.changingRegisteredAgentsSubscription = changingRegisteredAgentsSubscription;
    this.serviceSubscriptions = serviceSubscriptions;
  }
  companyName: string ;
  businessEntityType: IBusinessEntityType | undefined;
  attorneyLawsuit: boolean | undefined;
  attorneyEmail: string ;
  changingRegisteredAgents: boolean | undefined;
  changingRegisteredAgentsSubscription: { name: string, amount: number };
  serviceSubscriptions: IServiceSubscriptions [] | undefined  ;
}
