import { useState } from "react";
import { IClientDto } from "../../../../../models/client/newRegisterAgent/IClientDto";
import { RejectedResponseType, _viewDocument } from "../../../../../services/documents/Documents";
import { IClientDocumentDto } from "../../../../../models/clientDocument/IClientDocumentDto";
import Modal from "react-bootstrap/esm/Modal";

interface IDocumentsCardProps {
    clienSelectedInformation: IClientDto;
    setShowLoadingButton: React.Dispatch<React.SetStateAction<boolean>>
}

const DocumentsCard = (documentsCardProps:IDocumentsCardProps) => {
    const [image, setImage] = useState<string>("");
    const [showModalFile, setShowModalFile] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);


    const handleViewFileClick = async (viewId: number) => {
        documentsCardProps.setShowLoadingButton(true)
        let handleDocumentsResults = await _viewDocument(viewId)
        if ('urlFile' in handleDocumentsResults) {
          setImage(handleDocumentsResults.urlFile)
          setShowModalFile(true)
        } else {
          handleFailedDownloadedFiles([handleDocumentsResults]);
        }
        documentsCardProps.setShowLoadingButton(false);
      }

      const handleFailedDownloadedFiles = (failedDownloadResponses: RejectedResponseType[]): void => {
        let message = ""
        if (failedDownloadResponses.length > 0) {
          var serializedFailedResults = failedDownloadResponses.map(({ id, details }) => `id:  ${id}, details: ${details}\n`).join("");
          message = 
          `There were issues while retrieving some documents. These are the details:
          \n ${serializedFailedResults}
          \nConsider contacting an administrator if the problem persists.`;
          handleShow()
        }
        setErrorMessage(message);
      }


      const handleShow = () => {
        setShowModal(true);
      };

     

  
      const handleClose = () => {
        setShowModal(false);
        setErrorMessage("");
      };

    return (
        <div className="documentsCard table-responsive-md">
            <div className="cardTitles">
                <div className="cardTitles__title">Documents</div>
                {/* <button className="primaryButton basic-font h-10">View All</button> */}
            </div>
            <table className="table align-middle">
                <tbody >
                    {documentsCardProps.clienSelectedInformation.documents.map((document)=> 
                    <tr 
                        key={document.id}
                    >
                        {/* document.documentType goes here once backend is defined. */}
                        <td>{document.id}</td>
                        <td>{document.isRead}</td>
                        <td>
                            <span className={ document.isRead ? 'greenButton' : 'redButton' }>
                                {document.isRead ? 'Read' : 'Unread'}
                            </span>
                        </td>                           
                        <td className="col-md-2">
                                <label className="link-td" onClick={()=>{handleViewFileClick(document.id)}}>view</label>
                        </td>
                    </tr>)}
                </tbody>
            </table>
            <Modal show={showModal} onHide={handleClose} className=""  >
                <div className="small-text stext-white-space smodal-body ">
                    {errorMessage}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                </div>
            </Modal>
            <div className={`modal fade ${showModalFile ? 'show' : ''}`} id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showModalFile ? 'block' : 'none' }}>
                <div className="modal-dialog modal-fullscreen ">
                <div className="modal-content mh-100">
                    <div className="modal-body">
                        <iframe
                            src={image}
                            width="90%"
                            height="98%"
                        >
                        </iframe>                 
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>setShowModalFile(false)}>Close</button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};
export default DocumentsCard;
