import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface IPhoneProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}
const Phone = (phoneProps: IPhoneProps) => {

    const updateErrors = (value: string) => {
        let error = phoneProps.signUpErrors.contactDetailsErrors.phone.getValidationErrors(value);
        setError(error);
    }

    const setError = (error: string) => {
        phoneProps.setSignUpErrors(prevState => ({
            ...prevState,
            contactDetailsErrors: {
                ...prevState.contactDetailsErrors,
                phone: {
                    ...prevState.contactDetailsErrors.phone,
                    message: error
                }
            }
        }));
    }
 
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        updateErrors(e.target.value)
    }

    const handleOnChange = (e: React.FocusEvent<HTMLInputElement>) => {
        if(e.target.value.length<=10){
            phoneProps.setNewClientRegistration({
                ...phoneProps.newClientRegistration,
                contactDetails: {
                    ...phoneProps.newClientRegistration.contactDetails,
                    phone: e.target.value
                }
            
            });
            setError(""); // while typing we clear errors
        }
    }

    return (
        <div className="mb-3 twoInputInOneRow-input">
            <input 
                value={phoneProps.newClientRegistration.contactDetails.phone}
                type="number"
                title="Enter phone number without the country code"
                className={`form-control`} 
                placeholder="Phone number"
                maxLength={10}
                onBlur={handleOnBlur}
                onChange={handleOnChange}/>
            <p className="errorMessage">{phoneProps.signUpErrors.contactDetailsErrors.phone.message}</p>    
        </div>
    );
};
export default Phone;