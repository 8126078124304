import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface IEmailProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
    setEmailError:Function
  }

const Email = (emailProps:IEmailProps) => {

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = emailProps.signUpErrors.acountSetupErrors.email.getValidationErrors(e.target.value);
        emailProps.setEmailError(error);
    }

    return (
        <div className="mb-3">
            <div  className="nameCompany">
            <input 
                value={emailProps.newClientRegistration.acountSetup.email}
                type="email" 
                className={`form-control ${emailProps.signUpErrors.acountSetupErrors.email.message ? 'input-error' : ''}`} 
                placeholder="Email" 
                aria-describedby="emailHelp" 
                maxLength={50}
                onBlur={handleOnBlur}
                onPaste={(e)=>{emailProps.setEmailError("Pasting is not allow"),e.preventDefault();}}
                onChange={(e) => {
                    emailProps.setNewClientRegistration({
                        ...emailProps.newClientRegistration,
                        acountSetup: {
                            ...emailProps.newClientRegistration.acountSetup,
                            email: e.target.value
                    }
                });
                emailProps.setEmailError("");
            }}/>   
            </div>
            <p className="errorMessage">{emailProps.signUpErrors.acountSetupErrors.email.message} </p>
        </div>
    );
};
export default Email;