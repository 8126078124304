import INewClientRegistration from "../../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../../validation/ISignUpErrors";

interface IZipAndPostalProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const ZipAndPostal = (zipAndPostalProps:IZipAndPostalProps) => {
    const USCountryName = "UNITED STATES"

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = zipAndPostalProps.signUpErrors.contactDetailsErrors.zipPostal.getValidationErrors(e.target.value);
        zipAndPostalProps.setSignUpErrors(prevState => ({
                ...prevState,
                contactDetailsErrors: {
                    ...prevState.contactDetailsErrors,
                    zipPostal: {
                        ...prevState.contactDetailsErrors.zipPostal,
                        message: error
                    }
                }
            }));
    }

    const setError = (error: string) => {
        zipAndPostalProps.setSignUpErrors(prevState => ({
                ...prevState,
                contactDetailsErrors: {
                    ...prevState.contactDetailsErrors,
                    zipPostal: {
                        ...prevState.contactDetailsErrors.zipPostal,
                        message: error
                    }
                }
            }));
    }

    return (
        <div className="mb-3">
            <div  className="nameCompany">
                <input 
                    value={zipAndPostalProps.newClientRegistration.contactDetails.zipPostal}
                    type="text" 
                    placeholder="Zip / Postal"
                    className={`form-control ${zipAndPostalProps.signUpErrors.contactDetailsErrors.zipPostal.message ? 'input-error' : ''}`} 
                    id="exampleInputEmail1" 
                    aria-describedby="emailHelp" 
                    maxLength={10}
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                        if (zipAndPostalProps.newClientRegistration.contactDetails.country === USCountryName &&
                             e.target.value.length > 5) {
                        } else { zipAndPostalProps.setNewClientRegistration({
                            ...zipAndPostalProps.newClientRegistration,
                            contactDetails: {
                                ...zipAndPostalProps.newClientRegistration.contactDetails,
                                zipPostal: e.target.value
                        }
                });
                setError("");}}}/>
            </div>
            <p className="errorMessage">{zipAndPostalProps.signUpErrors.contactDetailsErrors.zipPostal.message} </p> 
        </div>
    );
};
export default ZipAndPostal;