interface DocumentsDownloadProps {
    selectedDocumentIds:number[]
    handleDownloadFileClick:Function
    showLoadingButton:boolean
  }

const DocumentsDownload = (documentsDownloadProps:DocumentsDownloadProps) => {
    return (
          documentsDownloadProps.showLoadingButton ? 
          <button className="primaryButton  basic-font w-150px h-100" type="button" disabled>
            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <span role="status">Downloading...</span>
          </button>
          :
          <button 
            className={`primaryButton basic-font w-150px h-100`}
            disabled={documentsDownloadProps.selectedDocumentIds.length===0} 
            onClick={()=>{documentsDownloadProps.handleDownloadFileClick(documentsDownloadProps.selectedDocumentIds)}}>
              Download
          </button>
    );
};
export default DocumentsDownload;
