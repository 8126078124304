import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface IZipAndPostalProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const ZipPostal = (zipAndPostalProps:IZipAndPostalProps) => {
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = zipAndPostalProps.signUpErrors.differentBillingAddressError.zipPostal.getValidationErrors(e.target.value);
                zipAndPostalProps.setSignUpErrors(prevState => ({
                ...prevState,
                differentBillingAddressError: {
                    ...prevState.differentBillingAddressError,
                    zipPostal: {
                        ...prevState.differentBillingAddressError.zipPostal,
                        message: error
                    }
                }
        }));
    }
    const setError = (error: string) => {
        zipAndPostalProps.setSignUpErrors(prevState => ({
            ...prevState,
            differentBillingAddressError: {
                ...prevState.differentBillingAddressError,
                zipPostal: {
                    ...prevState.differentBillingAddressError.zipPostal,
                    message: error
                }
            }
    }));
    }
    return (
        <div className="mb-3 twoInputInOneRow-input">
            <div>
                <input 
                    value={zipAndPostalProps.newClientRegistration.differentBillingAddress.zipPostal}
                    type="text" 
                    placeholder="Zip / Postal"
                    className={`form-control ${zipAndPostalProps.signUpErrors.differentBillingAddressError.zipPostal.message ? 'input-error' : ''}`} 
                    id="exampleInputEmail1" 
                    aria-describedby="emailHelp" 
                    maxLength={10}
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                        zipAndPostalProps.setNewClientRegistration({
                            ...zipAndPostalProps.newClientRegistration,
                            differentBillingAddress: {
                                ...zipAndPostalProps.newClientRegistration.differentBillingAddress,
                                zipPostal: e.target.value
                        }
                    });
                    setError("");
                }}/>
                <p className="errorMessage">{zipAndPostalProps.signUpErrors.differentBillingAddressError.zipPostal.message} </p>      
            </div>
        </div>
    );
};
export default ZipPostal;