import axios from "axios";
import IBusinessEntityType from "../../../models/client/newRegisterAgent/companyInformation/businessEntityType/IBusinessEntityType";

const baseUrl = "businessEntityType"

async function _getBusinessEntityType(): Promise<IBusinessEntityType[]> {
 let response = await axios.get("businessEntityType")
  return response.data;
}
 
export {_getBusinessEntityType};
