import React, { useEffect } from 'react';
import { IServicesPackageDto } from '../../../models/servicesPackage/IServicesPackageDto';
import { IService } from '../../../models/service/IService';

interface TotalAddonPackagesProps {
    setTotalAmount: React.Dispatch<React.SetStateAction<number>>;
    totalAmount: number;
}

const TotalInvoicePackages = (totalAddonPackagesProps: TotalAddonPackagesProps) => {

    return (
        <div className="mt-2 total">
            <label className="totalText">Total</label>
            <div className="totalValue">${totalAddonPackagesProps.totalAmount}</div>
        </div>
    );
};

export default TotalInvoicePackages;