import { ITokenFormData } from "./IAffinipayPaymentToken";

export default class TokenFormData implements ITokenFormData {
    constructor(
      exp_year: string = "",
      exp_month: string = "",
      name: string = "",
      
    ) {
      this.exp_year = exp_year;
      this.exp_month = exp_month;
      this.name = name;
     
    }
    exp_year: string
    exp_month: string
    name: string
  }
  
  export interface FormData {
  
  }
  