import { _getClientDocuments } from "../../../APIs/clientDocument/ClientDocument";
import { IService } from "../../../models/service/IService";
import "../../../pages/clients/addons/Addons"

interface ServicesCardsProps {
    services: IService;
    selectedCardIds: number[];
    handleCardClick: (serviceId: number) => void;
  }

const ServicesCards = (servicesCardsProps: ServicesCardsProps) => {

    return(
        <div 
            className={`client-addons-cards card-basic-style card-on-hover ${servicesCardsProps.selectedCardIds.includes(servicesCardsProps.services.id)?"client-addons-cards__selected":""}`}
            onClick={()=>{servicesCardsProps.handleCardClick(servicesCardsProps.services.id)}}
            >
            <div className="client-addons-cards__title">
                {servicesCardsProps.services.name}
            </div>
            <div  className="client-addons-cards__description">
                {servicesCardsProps.services.description}
            </div>
            <div  className="client-addons-cards__foot">
            </div>
        </div>
    )
}

export default ServicesCards;
