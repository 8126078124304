import INewClientRegistration from "../../../models/client/newRegisterAgent/INewClientRegistration";
import Total from "./total/Total";
import TermsOfService from "./termsOfService/TermsOfService";
import SelectedBusinessAddressService from "./selectedBusinessAddressService/SelectedBusinessAddressServices";
import SelectedRegistrationOptionalServicesProps from "./selectedRegistrationOptionalServices/SelectedRegistrationOptionalServices";
import { useState } from "react";
import { _postClientInformation } from "../../../APIs/client/ClientApi";
import { _mapToNewClientPayload } from "../../../services/client/ClientServices";
import "./orderSummary.scss";
import SelectedchangingRegisteredAgents from "./selectedchangingRegisteredAgents/SelectedchangingRegisteredAgents";
import ApiError from "../../../models/error/ApiError";

interface IOrderSummaryProps {
  newClientRegistration: INewClientRegistration;
  setNewClientRegistration: React.Dispatch<
    React.SetStateAction<INewClientRegistration>
  >;
  changeStep: Function;
  closeAllModals: () => void;
}

const OrderSummaryWithoutPayment = (orderSummaryProps: IOrderSummaryProps) => {
  const handleStateClick = (goTo: string) => {
    orderSummaryProps.changeStep(goTo);
  };
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [showModalTermsFiles, setShowModalTermsFiles] =
    useState<boolean>(false);

  const saveInformation = async () => {
    try {
      setSubmitDisabled(true);
      const newClientPayload = _mapToNewClientPayload(
        orderSummaryProps.newClientRegistration
      );
      await _postClientInformation(newClientPayload);
      setSuccessMessage("Client Created Successfully");
      setTimeout(() => {
        setSubmitDisabled(false);
        orderSummaryProps.closeAllModals();
      }, 2000);
    } catch (error) {
      const typedError = error as ApiError;
      setErrorMessage(typedError.details);
      setSubmitDisabled(false);
    } finally {
    }
  };

  const getValidationErrors = (value: string) => {
    let errorMessages = [];
    if (value.length <= 0) {
      errorMessages.push("Lead Generated By is required");
    }
    if (!/^[A-Za-z0-9\s]+$/.test(value)) {
      errorMessages.push("Only letters, numbers, and spaces are allowed");
    }
    if (/ {2,}/.test(value)) {
      errorMessages.push("Avoid more than one consecutive spaces.");
    }
    if (errorMessages.length == 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    let joinedErrors = "";
    if (errorMessages.length > 0) {
      joinedErrors = errorMessages.join(", ");
    }
    setErrorMessage(joinedErrors);
  };

  return (
    <div className="form">
      <div className="form__title">Order Summary</div>
      <form>
        <SelectedchangingRegisteredAgents
          newClientRegistration={orderSummaryProps.newClientRegistration}
        />
        <SelectedBusinessAddressService
          newClientRegistration={orderSummaryProps.newClientRegistration}
        />
        <SelectedRegistrationOptionalServicesProps
          newClientRegistration={orderSummaryProps.newClientRegistration}
        />
        <Total
          totalAmount={totalAmount}
          setTotalAmount={setTotalAmount}
          newClientRegistration={orderSummaryProps.newClientRegistration}
        />
        <div className="mb-3 city">
          <input
            value={orderSummaryProps.newClientRegistration.leadGeneratedBy}
            type="text"
            placeholder="Lead Generated By"
            className={`form-control`}
            maxLength={50}
            onChange={(e) => {
              orderSummaryProps.setNewClientRegistration({
                ...orderSummaryProps.newClientRegistration,
                leadGeneratedBy: e.target.value,
              });
              getValidationErrors(e.target.value);
            }}
          />
        </div>
        <TermsOfService setShowModalTermsFiles={setShowModalTermsFiles} />
      </form>
      <div className="submitStageButtons border-top pt-1rem">
        <button
          type="button"
          className={`primaryButton `}
          onClick={() => {
            saveInformation();
          }}
          disabled={submitDisabled}
        >
          Submit
        </button>

        <div className="errorMessage"> {errorMessage}</div>
        <div className="successMessage"> {successMessage}</div>
        <button
          type="button"
          className="greyButton"
          onClick={() => {
            handleStateClick("back");
          }}
        >
          Back
        </button>
      </div>
      <div
        className={`modal fade ${showModalTermsFiles ? "show" : ""}`}
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showModalTermsFiles ? "block" : "none" }}
      >
        <div className="modal-content mh-100 modal-dialog modal-fullscreen">
          <div className="modal-body ">
            <iframe
              title="PDF Viewer"
              src="/termsAndServices.pdf"
              width="800"
              height="600"
            ></iframe>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => setShowModalTermsFiles(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderSummaryWithoutPayment;
