import IPaymentInformation from "../../../models/client/newRegisterAgent/paymentInformation/IPaymentInformation";
import { IService } from "../../../models/service/IService";
import { IServicesPackageDto } from "../../../models/servicesPackage/IServicesPackageDto";
import paymentInformationError, { IPaymentInformationError } from "../../signUp/paymenyInformation/validation/IPaymentInformationError";
import AddonCardNumber from "../addon/paymentCards/addonSummary/addonPaymentForm/AddonCardNumber";
import AddonExpiration from "../addon/paymentCards/addonSummary/addonPaymentForm/AddonExpiration";
import AddonFirstName from "../addon/paymentCards/addonSummary/addonPaymentForm/AddonFirstName";
import AddonLastName from "../addon/paymentCards/addonSummary/addonPaymentForm/AddonLastName";
import AddonSecurityCode from "../addon/paymentCards/addonSummary/addonPaymentForm/AddonSecurityCode";
import { useEffect, useState } from "react";
import "../addon/paymentCards/addonSummary/addonPaymentCard.scss"
import AddonPostalCode from "../addon/paymentCards/addonSummary/addonPaymentForm/AddonPostalCode";
import visa from "../../../Assets/images/visa.png";
import mastercard from "../../../Assets/images/mastercard.png";
import americanexpress from "../../../Assets/images/americanexpress.png";
import discover from "../../../Assets/images/discover.png";

interface AddonPaymentCardProps {
  affiniPayCardError: string;
  affiniPayCvvError: string;
  paymentInformation: IPaymentInformation
  setPaymentInformation: React.Dispatch<React.SetStateAction<IPaymentInformation>>
  setAreTouchedFieldsWithErrors:React.Dispatch<React.SetStateAction<boolean>>
  setAreRequiredFieldsUndefined:React.Dispatch<React.SetStateAction<boolean>>
  }

const InvoicePaymentCard = (addonPaymentCardProps: AddonPaymentCardProps) => {
  const [addonsErrors, setAddonsErrors] = useState<IPaymentInformationError>(paymentInformationError);

  useEffect(() => {
    let areRequiredFieldsUndefined = evaluateRequireUndefinedFields(
      addonsErrors,
      addonPaymentCardProps.paymentInformation
    );
    addonPaymentCardProps.setAreRequiredFieldsUndefined(areRequiredFieldsUndefined);
  }, [
    addonsErrors,
  ]);

  useEffect(() => {
    let areTouchedFieldsWithErrors = evaluateTouchedFieldsWithErrors(
      addonsErrors
    );
    addonPaymentCardProps.setAreTouchedFieldsWithErrors(areTouchedFieldsWithErrors);
  }, [addonsErrors]);

  return(
    <div  className="payment__creditCard card-basic-style">
        <div className="small-titles">Payment</div>  
        
        <label className="form__subTitle">
          We accept all major credit cards.
        <br/>Please double-check your order.
        <br/>All fees paid pass this stage are NON-REFUNDABLE.
      </label>
      <div className="creditCardsImg">
        <img src={visa} alt="visa" />
        <img src={mastercard} alt="mastercard" />
        <img src={americanexpress} alt="americanexpress" />
        <img src={discover} alt="discover" className="discover" />
      </div>
        <form className="addon-payment-form">
          <div className="form__twoInputInOneRow">
            <AddonFirstName
              addonsErrors={addonsErrors}
              setAddonsErrors={setAddonsErrors}
              setPaymentInformation={addonPaymentCardProps.setPaymentInformation}
              paymentInformation={addonPaymentCardProps.paymentInformation}
              />
            <AddonLastName
              addonsErrors={addonsErrors}
              setAddonsErrors={setAddonsErrors}
              setPaymentInformation={addonPaymentCardProps.setPaymentInformation}
              paymentInformation={addonPaymentCardProps.paymentInformation}
              />
          </div>
          <div className="form__twoInputInOneRow ">
            <AddonCardNumber 
              affiniPayCardError={addonPaymentCardProps.affiniPayCardError} 
            />
            <AddonSecurityCode
                affiniPayCvvError={addonPaymentCardProps.affiniPayCvvError} 
            />
          </div>
          <div className="form__twoInputInOneRow ">
            <AddonExpiration
              addonsErrors={addonsErrors}
              setAddonsErrors={setAddonsErrors}
              setPaymentInformation={addonPaymentCardProps.setPaymentInformation}
              paymentInformation={addonPaymentCardProps.paymentInformation}
            />
            <AddonPostalCode
              addonsErrors={addonsErrors}
              setAddonsErrors={setAddonsErrors}
              setPaymentInformation={addonPaymentCardProps.setPaymentInformation}
              paymentInformation={addonPaymentCardProps.paymentInformation}
            />
          </div>
        </form>    
    </div>
  )
}
export default InvoicePaymentCard;

const evaluateRequireUndefinedFields = (
  errors: IPaymentInformationError,
  paymentInformation: IPaymentInformation
): boolean => {
  let areRequiredFieldsUndefined = false;
  const keys = Object.keys(paymentInformation) as (keyof typeof paymentInformation)[];
  var isAnyRequireUndefinedField = keys.find(
    (key) =>
      (paymentInformation[key] === undefined ||
        paymentInformation[key]!.toString().length === 0) &&
      errors[key].required === true
  );
  areRequiredFieldsUndefined = isAnyRequireUndefinedField !== undefined;
  return areRequiredFieldsUndefined;
};

const evaluateTouchedFieldsWithErrors = (
  errors: IPaymentInformationError
): boolean => {
  let areTouchedFieldsWithErrors = false;
  let errorFields = Object.values(errors);
  let erroredFields = errorFields.filter((errorField) =>
    errorField.hasOwnProperty("message")
  );
  var isAnyFieldsWithErrors = erroredFields.find(
    (error) => error.message?.length > 0
  );
  areTouchedFieldsWithErrors = isAnyFieldsWithErrors !== undefined;
  return areTouchedFieldsWithErrors;
};

