import { useEffect, useState } from "react";
import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";
import UsaStates from "../../../../APIs/mockData/states";


interface IStateAndProvinceProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const StateProvince = (stateAndProvinceProps: IStateAndProvinceProps) => {
    const [colorFirstView, setColorFirstView] = useState("");
    const [selectedValue, setSelectedValue] = useState(
        stateAndProvinceProps.newClientRegistration.differentBillingAddress.stateProvince || '0'
      );
    const [usaStates, setUsaStates] = useState< {id: string; state: string;}[]>(UsaStates());

      useEffect(() => {
        if (stateAndProvinceProps.newClientRegistration.differentBillingAddress.stateProvince) {
          setSelectedValue(stateAndProvinceProps.newClientRegistration.differentBillingAddress.stateProvince);
        }
      }, [stateAndProvinceProps.newClientRegistration.differentBillingAddress.stateProvince]);
      
    return (
        <div className="mb-3 twoInputInOneRow-input">
                <select
                value={selectedValue}
                className={`form-select ${colorFirstView === "" ? "color-default-option-selected" : ""}`}
                disabled={false}
                onChange={(e) => {
                setColorFirstView(e.target.value);
                    stateAndProvinceProps.setNewClientRegistration({
                        ...stateAndProvinceProps.newClientRegistration,
                        differentBillingAddress: {
                            ...stateAndProvinceProps.newClientRegistration.differentBillingAddress,
                            stateProvince: e.target.value
                        }
                    });
                }}>
                <option value="0" disabled selected>State / Province</option>
                {usaStates.map((state)=><option value={state.id} >{state.state}</option>)}
            </select>
        </div>
    );
};
export default StateProvince;