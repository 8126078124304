import { useEffect, useState } from "react";
import { _getClientDocuments } from "../../../../APIs/clientDocument/ClientDocument";
import { IService } from "../../../../models/service/IService";
import { IServicesPackageDto } from "../../../../models/servicesPackage/IServicesPackageDto";
import "../../../../pages/clients/addons/Addons";
import AddonSummaryCard from "./addonSummary/AddonSummaryCard";
import AddonTotalAmountCard from "./addonSummary/AddonTotalAmountCard";
import AddonPaymentCard from "./addonSummary/AddonPaymentCard";
import AffinipayPaymentToken from "../../../../models/affinipayPayment/affinipayPaymentToken/AffinipayPaymentToken";
import {
  IAffiniPayState,
  IAffinipayPaymentInformation,
} from "../../../../models/affinipayPayment/aaffinipayPayment/IAffinipayPayment";
import { initAffiniPay } from "../../../signUp/paymenyInformation/cardNumber/InitAffiniPay";
import IPaymentInformation from "../../../../models/client/newRegisterAgent/paymentInformation/IPaymentInformation";
import PaymentInformation from "../../../../models/client/newRegisterAgent/paymentInformation/PaymentInformation";
import { _packagesSubscription } from "../../../../APIs/client/ClientApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

interface PaymentCardsProps {
  services: IService[];
  selectedPackagesCardIds: { serviceId: number; packageId: number }[];
}

const PaymentCards = (paymentCardsProps: PaymentCardsProps) => {
  const [selectedServicesAndPackages, setSelectedServicesAndPackages] =
    useState<
      { selectedService: IService; selectedPackage: IServicesPackageDto }[]
    >([]);
  const [paymentInformation, setPaymentInformation] =
    useState<IPaymentInformation>(new PaymentInformation());
  const [hostedFields, setHostedFields] =
    useState<IAffinipayPaymentInformation>();
  const [affiniPayGeneratingTokenError, setAffiniPayGeneratingTokenError] =
    useState<string>("");
  const [affiniPayCardError, setAffiniPayCardError] = useState<string>("");
  const [affiniPayCvvError, setAffiniPayCvvError] = useState<string>("");
  const [areRequiredFieldsUndefined, setAreRequiredFieldsUndefined] =
    useState<boolean>(true);
  const [areTouchedFieldsWithErrors, setAreTouchedFieldsWithErrors] =
    useState<boolean>(true);
  const [isPaid, setIsPaid] = useState<boolean>(false);

  const [showLoadingButton, setShowLoadingButton] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const type = searchParams.get("type");

  useEffect(() => {
    initAffiniPay(setHostedFields, hostedFieldsCallBack);
  }, []);

  useEffect(() => {
    updateSelectedPackages(
      paymentCardsProps.selectedPackagesCardIds,
      paymentCardsProps.services
    );
  }, [paymentCardsProps.selectedPackagesCardIds]);

  useEffect(() => {
    if (isPaid) {
      setTimeout(() => {
        if (type == "doc") {
          navigate("/client/documents");
        } else {
          window.location.reload();
        }
      }, 3500);
    }
  }, [isPaid]);

  function updateSelectedPackages(
    servicesAndPackagesIds: { serviceId: number; packageId: number }[],
    services: IService[]
  ) {
    let selectedServicesPackages = servicesAndPackagesIds.map(
      (serviceAndPackageId) => {
        let currentService = services.find((service) => {
          return service.id === serviceAndPackageId.serviceId;
        });
        let currentPackage = currentService?.servicesPackages.find(
          (servicePkg) => {
            return servicePkg.id === serviceAndPackageId.packageId;
          }
        );
        return {
          selectedService: currentService!,
          selectedPackage: currentPackage!,
        };
      }
    );
    setSelectedServicesAndPackages(selectedServicesPackages);
  }

  const hostedFieldsCallBack = function (state: IAffiniPayState) {
    var cardError = state.fields[0].error;
    setAffiniPayCardError(cardError);

    var cvvError = state.fields[1].error;
    setAffiniPayCvvError(cvvError);
  };

  useEffect(() => {
    if (
      affiniPayGeneratingTokenError &&
      affiniPayGeneratingTokenError.length > 0
    ) {
      setTimeout(() => {
        hostedFields?.clearSavedPaymentMethod();
        setAffiniPayGeneratingTokenError("");
      }, 3500);
    }
  }, [affiniPayGeneratingTokenError]);

  const getAffiniPayToken = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowLoadingButton(true);
    event.preventDefault();
    if (!hostedFields || !hostedFields.getState()) {
      console.error("Hosted fields not ready");
      return;
    }
    try {
      selectedServicesAndPackages.map(async (selectedServiceAndPackages) => {
        let affinipayPaymentToken: AffinipayPaymentToken =
          await hostedFields.getPaymentToken({
            exp_year: 20 + paymentInformation.expiration.split("/")[1],
            exp_month: paymentInformation.expiration.split("/")[0],
            postal_code: paymentInformation.postalCode,
            name: `${paymentInformation.firstName} ${paymentInformation.lastName}`,
          });
        await _packagesSubscription(
          selectedServiceAndPackages.selectedService.id,
          selectedServiceAndPackages.selectedPackage.id,
          affinipayPaymentToken.id
        );
        setShowLoadingButton(false);
        setIsPaid(true);
      });
    } catch (error: any) {
      setAffiniPayGeneratingTokenError({ error: error.message }.error);
      setShowLoadingButton(false);
    }
  };

  return (
    <>
      <Row style={{ width: "100%" , marginBottom:8}}>
      <Col>
      <AddonSummaryCard
          selectedServicesAndPackages={selectedServicesAndPackages}
        />
      </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <Col span={6} sm>
          <AddonPaymentCard
            selectedServicesAndPackages={selectedServicesAndPackages}
            affiniPayCardError={affiniPayCardError}
            affiniPayCvvError={affiniPayCvvError}
            paymentInformation={paymentInformation}
            setPaymentInformation={setPaymentInformation}
            setAreTouchedFieldsWithErrors={setAreTouchedFieldsWithErrors}
            setAreRequiredFieldsUndefined={setAreRequiredFieldsUndefined}
          />
        </Col>
        <Col span={6} sm>
          <AddonTotalAmountCard
            selectedServicesAndPackages={selectedServicesAndPackages}
            getAffiniPayToken={getAffiniPayToken}
            affiniPayGeneratingTokenError={affiniPayGeneratingTokenError}
            affiniPayCardError={affiniPayCardError}
            affiniPayCvvError={affiniPayCvvError}
            areTouchedFieldsWithErrors={areTouchedFieldsWithErrors}
            areRequiredFieldsUndefined={areRequiredFieldsUndefined}
            showLoadingButton={showLoadingButton}
            isPaid={isPaid}
          />
        </Col>
      </Row>
    </>
  );
};

export default PaymentCards;
