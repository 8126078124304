import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface IExpiration {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const Expiration = (expirationProps:IExpiration) => {
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = expirationProps.signUpErrors.paymentInformationError.expiration.getValidationErrors(e.target.value);
        expirationProps.setSignUpErrors(prevState => ({
                ...prevState,
                paymentInformationError: {
                    ...prevState.paymentInformationError,
                    expiration: {
                        ...prevState.paymentInformationError.expiration,
                        message: error
                    }
                }
            }));
    }
    const setError = (error: string) => {
        expirationProps.setSignUpErrors(prevState => ({
            ...prevState,
            paymentInformationError: {
                ...prevState.paymentInformationError,
                expiration: {
                    ...prevState.paymentInformationError.expiration,
                    message: error
                }
            }
        }));
    }
    return (
        <div className="mb-3 twoInputInOneRow-input">
            <input 
                value={expirationProps.newClientRegistration.paymentInformation.expiration || ""}
                className={`form-control ${expirationProps.signUpErrors.paymentInformationError.expiration.message ? 'input-error' : ''}`} 
                placeholder="Expiration" 
                id="exampleInputEmail1" 
                aria-describedby="emailHelp"
                maxLength={50}
                onBlur={handleOnBlur} 
                onChange={(e) => {
                    expirationProps.setNewClientRegistration({
                        ...expirationProps.newClientRegistration,
                        paymentInformation: {
                            ...expirationProps.newClientRegistration.paymentInformation,
                            expiration: e.target.value
                    }
                });
                setError("");
            }}/>   
            <p className="errorMessage">{expirationProps.signUpErrors.paymentInformationError.expiration.message} </p> 
        </div>
    );
};
export default Expiration;