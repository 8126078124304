import INewClientRegistration from "./INewClientRegistration";
import AcountSetup from "./acountSetup/AcountSetup";
import IAcountSetup from "./acountSetup/IAcountSetup";
import CompanyInformation from "./companyInformation/CompanyInformation";
import ICompanyInformation from "./companyInformation/ICompanyInformation";
import ContactDetails from "./contactDetails/ContactDetails";
import IContactDetails from "./contactDetails/IContactDetails";
import DifferentBillingAddress from "./differentBillingAddress/DifferentBillingAddress";
import IDifferentBillingAddress from "./differentBillingAddress/IDifferentBillingAddress";
import { IOptionalRegistrationServices } from "./optionalRegistrationServices/IOptionalRegistrationServices";
import OptionalRegistrationServices from "./optionalRegistrationServices/OptionalRegistrationServices";
import IPaymentInformation from "./paymentInformation/IPaymentInformation";
import PaymentInformation from "./paymentInformation/PaymentInformation";

export default class NewClientRegistration implements INewClientRegistration {
  constructor(
    companyInformation: ICompanyInformation = new CompanyInformation(),
    optionalRegistrationServices: IOptionalRegistrationServices = new OptionalRegistrationServices(),
    contactDetails: IContactDetails = new ContactDetails(),
    acountSetup: IAcountSetup = new AcountSetup(),
    paymentInformation: IPaymentInformation = new PaymentInformation(),
    differentBillingAddress: IDifferentBillingAddress = new DifferentBillingAddress(),
    leadGeneratedBy: string = "",
    couponId : number = 0,
  ) {
    this.companyInformation = companyInformation;
    this.optionalRegistrationServices = optionalRegistrationServices;
    this.contactDetails = contactDetails;
    this.acountSetup = acountSetup;
    this.paymentInformation = paymentInformation;
    this.differentBillingAddress = differentBillingAddress;
    this.leadGeneratedBy = leadGeneratedBy;
    this.couponId = couponId;
  }

  companyInformation: ICompanyInformation;
  optionalRegistrationServices: IOptionalRegistrationServices;
  contactDetails: IContactDetails;
  acountSetup: IAcountSetup;
  paymentInformation: IPaymentInformation;
  differentBillingAddress: IDifferentBillingAddress;
  leadGeneratedBy: string;
  couponId? : number;
}
