import { IErroredField, IRequiredField } from "../../validation/IErroredAndRequiredField";

export interface ICompanyInformationErrors {
    companyName: IErroredField & IRequiredField;
    businessEntityType: IRequiredField;
    attorneyLawsuit: IRequiredField; 
    attorneyEmail: IRequiredField & IErroredField;
    changingRegisteredAgents: IRequiredField;
    changingRegisteredAgentsSubscription: IRequiredField;
    serviceSubscriptions: IRequiredField & IErroredField ;
}

const companyInformationErrors: ICompanyInformationErrors = {
    companyName: {
        required: true,
        getValidationErrors: (value): string => {
            let errorMessages = [];
            if (value.length <= 0) {
                errorMessages.push("company name is required");
            }
            if (!/^[A-Za-z0-9&.\-#\s]+$/.test(value)) {
                errorMessages.push("Only letters, numbers, spaces, &, ., -, and # are allowed.");
            }
            if (/ {2,}/.test(value)) {
                errorMessages.push("Avoid more than one consecutive spaces.");
            }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
    businessEntityType: { 
        required: true,
    },
    attorneyLawsuit: { 
        required: true,
    },
    serviceSubscriptions: { 
        required: true,
        getValidationErrors: (value): string => {
            let errorMessages = [];
            if (value === "ourFloridaAddress") {
                errorMessages.push("select an option");
            }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
    changingRegisteredAgents: { 
        required: true,
    },
    changingRegisteredAgentsSubscription: { 
        required: false,
    },
    attorneyEmail:  {
        required: false,
        getValidationErrors: (value): string => {
            let errorMessages = [];
            if (value.length <= 0) {
                errorMessages.push("email is required");
            }
            if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                errorMessages.push("Invalid email address format");
            }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
}
export default companyInformationErrors