import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface StateProvinceTextBoxProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const StateProvinceTextBox = (stateProvinceTextBoxProps: StateProvinceTextBoxProps) => {
    const updateError = (value: string) => {
        const error = stateProvinceTextBoxProps.signUpErrors.differentBillingAddressError.stateProvince.getValidationErrors(value);
        setError(error);
    }
    const maxLength = 50;

    const setError = (error:string) => {
        stateProvinceTextBoxProps.setSignUpErrors(prevState => ({
            ...prevState,
            differentBillingAddressError: {
                ...prevState.differentBillingAddressError,
                stateProvince: {
                    ...prevState.differentBillingAddressError.stateProvince,
                    message: error
                }
            }
        }));
    }

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        updateError(e.target.value);
    }
    
    const handleOnChange = (e: React.FocusEvent<HTMLInputElement>) => {
            if(e.target.value.length<=maxLength){
                stateProvinceTextBoxProps.setNewClientRegistration({
                    ...stateProvinceTextBoxProps.newClientRegistration,
                    differentBillingAddress: {
                        ...stateProvinceTextBoxProps.newClientRegistration.differentBillingAddress,
                        stateProvince: e.target.value
                    }
                });
                setError('');
            }
    }
    
    return (
        <div className="mb-3 twoInputInOneRow-input">
            <input 
                value={stateProvinceTextBoxProps.newClientRegistration.differentBillingAddress.stateProvince}
                type="text"
                className={`form-control ${stateProvinceTextBoxProps.signUpErrors.differentBillingAddressError.stateProvince.message ? 'input-error' : ''}`} 
                placeholder="State / Province"
                maxLength={maxLength}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
            />  
            <p className="errorMessage">{stateProvinceTextBoxProps.signUpErrors.differentBillingAddressError.stateProvince.message} </p> 
        </div>
    );
};
export default StateProvinceTextBox;