import { useEffect, useState } from "react";
import ClientInvoicesTable from "../../../components/provider/invoices/ClientInvoicesTable";
import { IClientInvoiceDto } from "../../../models/clientInvoice/IClientInvoiceDto";
import ClientInvoicesElementsAndSearchButtonCard from "../../../components/provider/invoices/elementsAndSearchButtons/ClientInvoicesElementsAndSearchButtonCard";
import NewInvoice from "../../../components/provider/home/elementsAndSearchButtons/NewInvoice";
import { useLocation } from "react-router-dom";
import { _getClientInovices } from "../../../APIs/agent/AgentApi";

const AgentInvoices = () => {
  const [invoices, setInvoices] = useState <IClientInvoiceDto[]> ([]);
  const [selectedInvoice, setSelectedInvoice] = useState <number | undefined> (undefined);
  const location = useLocation();

  useEffect(() => {
    initInvoices();
}, [location.key]);

const  initInvoices = async () => {
    let invoices = await _getClientInovices();
    setInvoices(invoices)
} 

  return (
    <div className="clientDocuments">
      <div className="providerInformation__state">Invoices</div>
      <ClientInvoicesElementsAndSearchButtonCard invoices={invoices} setInvoices={setInvoices} setSelectedInvoice={setSelectedInvoice} element={[<NewInvoice />]}/>
      <ClientInvoicesTable invoices={invoices} setInvoices={setInvoices} setSelectedInvoice={setSelectedInvoice}/>
    </div>  
  );
}
export default AgentInvoices;