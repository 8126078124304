import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate, useParams } from "react-router-dom";
import "./login.scss";
import {
  _login,
  _getLoginUser,
  _getSessionUserFromLoginUser,
  _getUserType,
} from "../../services/login/LoginService";
import { _forgotPassword, _resetPassword } from "../../APIs/user/userApi";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasTokenError, setHasTokenError] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams<"token">();

  useEffect(() => {
    setHasError(true);
  }, []);

  useEffect(() => {
    setHasError(true);
    let errorMessages = [];

    if (confirmPassword.length <= 0) {
      errorMessages.push("Confirm password is required");
    }
    if (password !== confirmPassword) {
      errorMessages.push("Passwords must be equal");
    }

    if (
      email.length != 0 &&
      password.length >= 8 &&
      password.length != 0 &&
      confirmPassword &&
      errorMessages.length == 0
    ) {
      setHasError(false);
    }
  }, [email, password, confirmPassword]);

  const onResetClicked = async () => {
    setIsLoading(true);
    const result = await _resetPassword(email, password, params?.token ?? "");
    if (result) {
      navigate("/login");
    } else {
      setHasTokenError(true);
    }
    setIsLoading(false);
  };

  const getValidationErrors = (confirmPasword: string) => {
    let errorMessages = [];
    if (confirmPasword.length <= 0) {
      errorMessages.push("Confirm password is required");
    }
    if (password.length < 8) {
      errorMessages.push("Password must be at least 8 characters long");
    }
    if (password !== confirmPasword) {
      errorMessages.push("Passwords must be equal");
    }
    let joinedErrors = "";
    if (errorMessages.length > 0) {
      joinedErrors = errorMessages.join(", ");
    }
    setErrorMessage(joinedErrors);
  };

  return (
    <div className="logInPage">
      <div className="image-section"></div>

      <div className="login-section">
        <div className="login">
          <div className="form__title">Reset Password</div>
          <label className="form__subTitle"></label>
          <input
            type="email"
            className="form-control mb-3"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          ></input>
          <input
            type="password"
            className="form-control mb-3"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          ></input>
          <input
            type="password"
            className="form-control mb-3"
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            onBlur={(e) => {
              getValidationErrors(e.target.value);
            }}
          ></input>
          <div className="submitStageButtons">
            <button
              disabled={isLoading || hasError}
              type="button"
              className="primaryButton w-30 h-37"
              onClick={() => onResetClicked()}
            >
              Reset
            </button>
          </div>
          <p className="error">{errorMessage}</p>
          {hasTokenError && (<p className="error">The link to reset the password has expired. To request a reset again, <a href="/login">go back</a> to the login screen and click the Forgot Password link. </p>)}
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
