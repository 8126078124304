import { ReactNode, useState } from "react";
import SessionUser from "../models/user/SessionUser";
import { UserContext } from "./UserContextProvider";
import { SidebarContext } from "./SidebarContextProvider";
import { _getSession, _getSessionUser } from '../services/session/SessionService';

interface props {
  children: ReactNode;
}

// contexts are cleared when refreshing the browser. With this code we read from session, which is perissted.
// if no sessiion retrieved, we instatiate a class with all undefined values. 
// No session defined should never happen, since we have a route guard that redirects to login if no session.
const getInitialSession = (): SessionUser => {
  let session = _getSession();
  let user = session ? _getSessionUser(session) : new SessionUser();
  return user;
}
  
export function ContextProvider({ children }: props) {
  const [user, setUser] = useState(getInitialSession()); // this is triggered everytime we refresh the browser.
  const [isSidebarOpen, setIsSidebarOpen]= useState<boolean>(false);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <SidebarContext.Provider value={{ isSidebarOpen, setIsSidebarOpen }}>
      {children}
      </SidebarContext.Provider>
    </UserContext.Provider>
  );
}