import { useEffect, useState } from "react";
import { _getServicesByCategory } from "../../../APIs/servicesCategory/ServicesCategory";
import INewClientRegistration from "../../../models/client/newRegisterAgent/INewClientRegistration";
import { IService } from "../../../models/service/IService";
import { IServiceSubscriptionsOptional } from "../../../models/client/newRegisterAgent/companyInformation/serviceSubscriptions/IServiceSubscriptions";

interface IOptionalItemsProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    changeStep: Function
    selectedServices: IServiceSubscriptionsOptional[]
    setSelectedServices:Function
}

const OptionalItems = (optionalItemsProps: IOptionalItemsProps) => {
    const [OptionalItems, setOptionalItems] = useState<IService[]>([])
    const ourOptionalServices =  "CLIENT_REGISTRATION_OPTIONAL"  
    const handleStateClick = (goTo:string) => {
        optionalItemsProps.changeStep(goTo)
    }

    useEffect(() => {
        initOptionalRegistrationServices()  
    }, [])

    useEffect(() => {
        if (OptionalItems[0]) {
            optionalItemsProps.setNewClientRegistration(
                {...optionalItemsProps.newClientRegistration,
                optionalRegistrationServices: {
                    ...optionalItemsProps.newClientRegistration.optionalRegistrationServices,
                    serviceSubscriptions: optionalItemsProps.selectedServices,
                }
            })
        }
    }, [optionalItemsProps.selectedServices])

    const initOptionalRegistrationServices = async () => {
        const OptionalServices = await _getServicesByCategory(ourOptionalServices);
        setOptionalItems(OptionalServices);
    };

    const addClientRegistration = (registrationOptionalService:IServiceSubscriptionsOptional ) => {
        optionalItemsProps.setSelectedServices((prevSelectedServices:any) => {
          const existingItemIndex = prevSelectedServices.findIndex(
            (item:any) => item.servicePackageId === registrationOptionalService.servicePackageId
          );
      
          if (existingItemIndex !== -1) {
            const updatedServices = [...prevSelectedServices];
            updatedServices.splice(existingItemIndex, 1);
            return updatedServices;
          } else {
            return [...prevSelectedServices, registrationOptionalService];
          }
        });
    };

    const handleOptionSelect = (selectedService: IService) => {
        const selectedClientService: IServiceSubscriptionsOptional = {
          active: selectedService.active,
          servicePackageId:selectedService.servicesPackages[0].id,
          serviceId: selectedService.id,
          serviceName: selectedService.name,
          servicePackageName: selectedService.servicesPackages[0].name,
          serviceDescription: selectedService.description,
          servicePackageDescription : selectedService.servicesPackages[0].description,
          servicePackageAmount : selectedService.servicesPackages[0].amount,
          } 
          addClientRegistration(selectedClientService)
        }
      
    return ( 
        <div className="form">
            <div className="form__title">Add-On Services</div>
            <div>(Also available in the client portal)</div><br />
            {OptionalItems.map((service) => (
                <div className="form-check" key={service.id}>
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        value="" 
                        id={`flexCheckDefault${service.id}`} 
                        onClick={() => handleOptionSelect(service)}
                        checked={optionalItemsProps.selectedServices.some(
                            (selectedService) => selectedService.servicePackageId === service.servicesPackages[0].id
                        )}                    />
                    <label className="form-check-label" htmlFor={`flexCheckDefault${service.id}`}>
                        {service.name} - ${service.servicesPackages[0].amount}
                    </label>
                </div>
            ))}
            <div className="form__stageButtons">
                <button type="button" className="greyButton" onClick={()=>{handleStateClick("back")}}>Back</button>
                <button type="button" className="primaryButton w-30 h-37" onClick={()=>{handleStateClick("next")}}>Next</button>
            </div>            
        </div>
    );
};
export default OptionalItems;