import { IErroredField, IRequiredField } from "../../validation/IErroredAndRequiredField";

export interface IDifferentBillingAddressError {
    country: IRequiredField ;
    address: IRequiredField & IErroredField;
    city: IRequiredField & IErroredField;
    stateProvince: IRequiredField & IErroredField;
    zipPostal: IRequiredField & IErroredField;
}

const differentBillingAddressError: IDifferentBillingAddressError = {
    country: { 
        required: true,
    },
    address: { 
        required: true,
        getValidationErrors: (value:string): string => {
            let errorMessages = [];
            if (value.length <= 0) {
                errorMessages.push("address is required");
            }
            if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                errorMessages.push("Only letters, numbers, and spaces are allowed");
            }
            if (/ {2,}/.test(value)) {
                errorMessages.push("Avoid more than one consecutive spaces.");
            }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
    city: { 
        required: true,
        getValidationErrors: (value:string): string => {
            let errorMessages = [];
            if (value.length <= 0) {
                errorMessages.push("city is required");
            }
            if (!/^[A-Za-z\s]+$/.test(value)) {
                errorMessages.push("only letters and spaces");
            }
            if (/ {2,}/.test(value)) {
                errorMessages.push("Avoid more than one consecutive spaces.");
            }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
    stateProvince: { 
        required: true,
        getValidationErrors: (value:string): string => {
            let errorMessages = [];
            if (value.length <= 0) {
                errorMessages.push("state/Province is required");
            }
            if (!/^[A-Za-z\s]+$/.test(value)) {
                errorMessages.push("only letters and spaces");
            }
            if (/ {2,}/.test(value)) {
                errorMessages.push("Avoid more than one consecutive spaces.");
            }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
    zipPostal: { 
        required: true,
        getValidationErrors: (value:string): string => {
            let errorMessages = [];
            if (value.length <= 0) {
                errorMessages.push("zip Postal is required");
            }
            if (/ {2,}/.test(value)) {
                errorMessages.push("Avoid more than one consecutive spaces.");
            }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
}
export default differentBillingAddressError