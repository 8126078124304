import { Link, useNavigate } from "react-router-dom";
import "../sidebar.scss";
import INavItem from "../../../models/navItems/INavItem";
import { useContext } from "react";
import { SidebarContext } from "../../../context/SidebarContextProvider";

type NavItemProps = {
  navItemProps: INavItem;
  userRole?: string;
};

function NavItem(props: NavItemProps) {
  const { setIsSidebarOpen } = useContext(SidebarContext); 
    const navigate = useNavigate();

    const onNavItemClick = () => {
      setIsSidebarOpen(false);
      navigate(props.navItemProps.to);
    };

  let isRoleAllowed: boolean = false;
  if(props.navItemProps.rolesAllowed === undefined){
    isRoleAllowed = true;
  }
  else if(props.userRole !== undefined) // in this condition rolesAllowed is defined as well ( it didn't fall in previous if)
  {
    isRoleAllowed = props.navItemProps.rolesAllowed.includes(props.userRole);
  }

  return (
      isRoleAllowed ? (
        <a className="navItem" onClick={ onNavItemClick}>
          {props.navItemProps.display}
        </a>
      ) : null
  );
}
export default NavItem;
