import { IAffinipayPaymentToken, ITokenFormData } from "./IAffinipayPaymentToken";
import TokenFormData from "./TokenFormData";

export default class AffinipayPaymentToken implements IAffinipayPaymentToken {
  constructor(
    type: string = "",
    cvv: string = "",
    number: string = "",
    exp_month: number = 0,
    exp_year: number = 0,
    name: string = "",
    form_data: ITokenFormData = new TokenFormData() ,
    id: string = "",
  ) {
    this.type = type;
    this.cvv = cvv;
    this.number = number;
    this.exp_month = exp_month;
    this.exp_year = exp_year;
    this.name = name;
    this.form_data = form_data;
    this.id = id;
  }
  type: string
  cvv: string
  number: string
  exp_month: number
  exp_year: number
  name: string
  form_data: ITokenFormData
  id: string
}

