import { useState } from "react";
import { Modal } from "react-bootstrap";
import { QuestionCircle } from "../../../icons/QuestionCircle";

export const ChangingRegisteredAgentsModal = () => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleShow = () => {
        setShowModal(true);
      };
      const handleClose = () => {
        setShowModal(false);
      };

return (   
<div>
    <div onClick={() => handleShow()} >
        <QuestionCircle />
    </div>
    <Modal show={showModal} onHide={handleClose} >
        <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">Are You Changing Registered Agents?</h1>
            </div>
            <div className="smodal-body">
            if you’re changing your registered agent to us, all the forms in your online account are pre-signed (if they need to be) and ready for you to complete and sign immediately after you pay. If you need help with the change of agent filing or would like us to do it for you, we will gladly assist! Thank you for changing! We think you’ll be happy with our level of service.
            </div>
            <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
        </div> 
    </Modal>
</div>)};





  
    