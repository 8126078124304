import { useEffect } from "react";
import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface IAttorneyEmailProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
    setErrorAttorneyEmail:Function
    setAttorneyEmail:Function
  }

const AttorneyEmail = (attorneyEmailProps:IAttorneyEmailProps) => {

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = attorneyEmailProps.signUpErrors.companyInformationErrors.attorneyEmail.getValidationErrors(e.target.value);
        attorneyEmailProps.setErrorAttorneyEmail(error);
    }

    return (
        <div className="mb-3">
            <div className="signUp-input-titles-text p-0 m-0">
                Attorney Email 
            </div>
            <div  className="nameCompany">
            <input 
                value={attorneyEmailProps.newClientRegistration.companyInformation.attorneyEmail}
                type="email" 
                className={`form-control ${attorneyEmailProps.signUpErrors.companyInformationErrors.attorneyEmail.message ? 'input-error' : ''}`} 
                placeholder="Attorney Email" 
                aria-describedby="emailHelp" 
                maxLength={50}
                onBlur={handleOnBlur}
                onPaste={(e)=>{attorneyEmailProps.setErrorAttorneyEmail("Pasting is not allow"),e.preventDefault();}}
                onChange={(e) => {
                    attorneyEmailProps.setAttorneyEmail(e.target.value)
                attorneyEmailProps.setErrorAttorneyEmail("");
            }}/>   
            </div>
            <p className="errorMessage">{attorneyEmailProps.signUpErrors.companyInformationErrors.attorneyEmail.message} </p>
        </div>
    );
};
export default AttorneyEmail;