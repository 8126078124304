import { useEffect, useState } from "react";
import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";
import UsaStates from "../../../../APIs/mockData/states";

interface StateAndProvinceProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
    setStateProvinceTextBoxError: (error: string) => void
}

const StateProvinceDropDown = (stateAndProvinceProps: StateAndProvinceProps) => {
    const [colorFirstView, setColorFirstView] = useState("");
    const usaStates = UsaStates();
    const [selectedValue, setSelectedValue] = useState(stateAndProvinceProps.newClientRegistration.contactDetails.stateProvince || '0');

    useEffect(() => {
        if ( stateAndProvinceProps.newClientRegistration.contactDetails.stateProvince) {
            setSelectedValue( stateAndProvinceProps.newClientRegistration.contactDetails.stateProvince);
        }
        stateAndProvinceProps.setStateProvinceTextBoxError("")
    }, [ stateAndProvinceProps.newClientRegistration.contactDetails.stateProvince]);
    
    return (
        <div className="mb-3">
                <select
                value={selectedValue}
                className={`form-select ${colorFirstView === "" ? "color-default-option-selected" : ""}`}
                disabled={false}
                onChange={(e) => {
                setColorFirstView(e.target.value);
                    stateAndProvinceProps.setNewClientRegistration({
                        ...stateAndProvinceProps.newClientRegistration,
                        contactDetails: {
                            ...stateAndProvinceProps.newClientRegistration.contactDetails,
                            stateProvince: e.target.value
                        }
                    });
                }}>
                <option value="0" disabled selected>State / Province</option>
                {usaStates.map((state)=><option value={state.state} >{state.state}</option>)}
            </select>
        </div>
    );
};
export default StateProvinceDropDown;