import { useEffect, useState } from "react";
import { IClientInvoiceDto } from "../../../models/clientInvoice/IClientInvoiceDto";
import { _getClientInovices } from "../../../APIs/agent/AgentApi";
import { IStatusInvoices } from "../../../models/client/invoices/IInvoices";
import {
  _getInovices,
  _getStatusClientInvoices,
} from "../../../APIs/client/ClientApi";
import { useNavigate } from "react-router-dom";

interface ClientInvoicesTableProps {
  invoices: IClientInvoiceDto[];
  setInvoices: React.Dispatch<React.SetStateAction<IClientInvoiceDto[]>>;
  setSelectedInvoice: React.Dispatch<React.SetStateAction<string[]>>;
  setIsPaid: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvoicesTable = (clientInvoicesTableProps: ClientInvoicesTableProps) => {
  const [statusInvoices, setStatusInvoices] = useState<IStatusInvoices>();
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<string[]>([]);
  const [paidIds, setPaidIds] = useState<string[]>([]);
  let navigate = useNavigate(); 

  useEffect(() => {
    initInvoices();
    initStatusInvoices();
  }, []);

  const initInvoices = async () => {
    let invoices = await _getInovices();
    clientInvoicesTableProps.setInvoices(invoices);
  };

  const initStatusInvoices = async () => {
    let statusInvoices = await _getStatusClientInvoices();
    setStatusInvoices(statusInvoices);
  };

  const handleChecksClicks = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    status: number
  ) => {
    let newPaids: string[] = [];
    if (e.target.checked) {
      const newArray = [...selectedInvoiceIds, id];
      if (status == 1) {
        newPaids = [...paidIds, id];
        setPaidIds(newPaids);
      }
      setSelectedInvoiceIds(newArray);
      clientInvoicesTableProps.setSelectedInvoice(newArray);
    } else {
      const newArray = selectedInvoiceIds.filter((element) => element !== id);
      setSelectedInvoiceIds(newArray);
      clientInvoicesTableProps.setSelectedInvoice(newArray);
      newPaids = paidIds.filter((element) => element !== id);
      setPaidIds(newPaids);
    }
    if (newPaids.length > 0) {
      clientInvoicesTableProps.setIsPaid(true);
    } else {
      clientInvoicesTableProps.setIsPaid(false);
    }
  };

  const routeChange = (id:number) =>{ 
    let path = `${id}`; 
    navigate(path);
  }

  return (
    <div className="table-responsive-md client-documents-table">
      <table className="table align-middle table-hover">
        <thead className="table-head sticky-top">
          <tr>
            <th scope="col"></th>
            <th scope="col">Date Received</th>
            <th scope="col">Due Date</th>
            <th scope="col">Total Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {clientInvoicesTableProps.invoices.map((invoice) => (
            <tr key={invoice.id}>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={(e) => {
                    handleChecksClicks(
                      e,
                      invoice.id.toString(),
                      invoice.status
                    );
                  }}
                />
              </td>
              <td>
                {new Date(invoice.dateReceived!).toLocaleDateString("en-US", {
                  dateStyle: "short",
                })}
              </td>
              <td>
                {new Date(invoice.dueDate!).toLocaleDateString("en-US", {
                  dateStyle: "short",
                })}
              </td>
              <td>${invoice.total}</td>
              <td
                style={{
                  color:
                    statusInvoices?.[invoice.status] === "PAID"
                      ? "green"
                      : statusInvoices?.[invoice.status] === "PENDING"
                      ? "goldenrod"
                      : "red",
                  textTransform: "capitalize",
                }}
              >
                {statusInvoices?.[invoice.status].toLowerCase()}
              </td>
              <td onClick={()=>routeChange(invoice?.id)} className="link-td">
                View
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoicesTable;
