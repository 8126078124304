import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";
import "./boxTextNameCompany.scss"
import { BoxTextNameCompanyModal } from "./BoxTextNameCompanyModal";

interface INameCompanyProps {
    newClientRegistration: INewClientRegistration;
    setNewClientRegistration: React.Dispatch<React.SetStateAction<INewClientRegistration>>;
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

 
const BoxTextNameCompany = (nameCompanyProps:INameCompanyProps) => {
    const maxLength = 50;

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = nameCompanyProps.signUpErrors.companyInformationErrors.companyName.getValidationErrors(e.target.value);
        setError(error);
    }

    const setError = (error: string) => {
        nameCompanyProps.setSignUpErrors(prevState => ({
            ...prevState,
            companyInformationErrors: {
                ...prevState.companyInformationErrors,
                companyName: {
                    ...prevState.companyInformationErrors.companyName,
                    message: error
                }
            }
        }));
    }

    const setValue = (value: string)=>{
        nameCompanyProps.setNewClientRegistration({
            ...nameCompanyProps.newClientRegistration,
            companyInformation: {
                ...nameCompanyProps.newClientRegistration.companyInformation,
                companyName: value
            }       
        });
    }

    return (
        <div className="mb-3">
            <div  className="nameCompany">
            <input 
                type="text"
                value={nameCompanyProps.newClientRegistration.companyInformation.companyName}
                className={`form-control ${nameCompanyProps.signUpErrors.companyInformationErrors.companyName.message ? 'input-error' : ''}`} 
                placeholder="Company Name/Full Legal Name"
                maxLength={maxLength}
                onBlur={handleOnBlur}
                onChange={(e)=>{
                    setValue(e.target.value)
                    setError("");
                }}/>   
            <BoxTextNameCompanyModal/>
            </div>
            <p className="errorMessage">{nameCompanyProps.signUpErrors.companyInformationErrors.companyName.message} </p>
        </div>
    );
};
export default BoxTextNameCompany;
