import { useContext } from 'react';
import ClientsHeader from './ClientsHeader';
import './client.scss';
import { Outlet } from "react-router-dom";
import { SidebarContext } from '../../context/SidebarContextProvider';

const Client = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext); 

  return (
    <div className={` ${isSidebarOpen ? "client  client__close " : "client" }`}>
        <ClientsHeader/>
        <Outlet />
    </div>
  )
};

export default Client;
