import { useEffect, useState } from "react";
import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ICountryDto } from "../../../../models/country/ICountryDto";

interface ICountryProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    countries: ICountryDto[]
}

const Country = (countryProps: ICountryProps) => {
    const [colorFirstView, setColorFirstView] = useState("");
    const [selectedValue, setSelectedValue] = useState(
        countryProps.newClientRegistration.contactDetails.country || '0'
      );

      useEffect(() => {
        if (countryProps.newClientRegistration.contactDetails.country) {
          setSelectedValue(countryProps.newClientRegistration.contactDetails.country);
        }
      }, [countryProps.newClientRegistration.contactDetails.country]);

    return (
        <div className="mb-3 twoInputInOneRow-input" >
            <div  className="">
                <select
                    value={selectedValue}
                    className={`form-select ${colorFirstView === "" ? "color-default-option-selected" : ""}`}
                    disabled={false}
                    onChange={(e) => {
                        setColorFirstView(e.target.value);
                        countryProps.setNewClientRegistration({
                            ...countryProps.newClientRegistration,
                            contactDetails: {
                                ...countryProps.newClientRegistration.contactDetails,
                                country: e.target.value
                            }
                        });
                    }}>
                    <option value="0" disabled>Country</option>
                    {countryProps.countries.map((country)=>
                        <option key={country.id} value={country.id} >{country.name}</option>
                    )}
                </select>
            </div>
        </div>
    );
};
export default Country;