import { useState } from "react";
import { Modal } from "react-bootstrap";
import { QuestionCircle } from "../../../icons/QuestionCircle";

export const SelectNotifyAttorneyModal = () => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleShow = () => {
        setShowModal(true);
      };
      const handleClose = () => {
        setShowModal(false);
      };
    return (
<div>
    <div onClick={() => handleShow()} >
        <QuestionCircle />
    </div>
    <Modal show={showModal} onHide={handleClose} >
        <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">Notify Your Attorney of a Lawsuit?</h1>
        </div>
        <div className="smodal-body">
            If you wish your attorney to also be notified when you receive a lawsuit, you can list your attorney here. We won’t bother them with regular mail we receive. We will scan the lawsuit and send both of you an email notification.
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
        </div> 
    </Modal>
</div>)};

      
    