import INewClientRegistration from "../../../models/client/newRegisterAgent/INewClientRegistration";
import { _postClientInformation } from "../../../APIs/client/ClientApi";
import { _mapToNewClientPayload } from "../../../services/client/ClientServices";
import { IAffinipayPaymentToken } from "../../../models/affinipayPayment/affinipayPaymentToken/IAffinipayPaymentToken";
import { ISignUpErrors } from "../validation/ISignUpErrors";
import AffinipayPaymentToken from "../../../models/affinipayPayment/affinipayPaymentToken/AffinipayPaymentToken";
import PaymentInformation from "../paymenyInformation/PaymentInformation";
import OrderSummary from "../orderSummary/OrderSummary";
import { useEffect, useState } from "react";
import {
  IAffiniPayState,
  IAffinipayPaymentInformation,
} from "../../../models/affinipayPayment/aaffinipayPayment/IAffinipayPayment";
import { initAffiniPay } from "../paymenyInformation/cardNumber/InitAffiniPay";
import PaymentInfromationModel from "../../../models/client/newRegisterAgent/paymentInformation/PaymentInformation";
import ApiError from "../../../models/error/ApiError";
import { IPaymentInformationError } from "../paymenyInformation/validation/IPaymentInformationError";
import IPaymentInformation from "../../../models/client/newRegisterAgent/paymentInformation/IPaymentInformation";
import DifferentBillingAddress from "../differentBillingAddress/DifferentBillingAddress";
import { ICountryDto } from "../../../models/country/ICountryDto";
import { Console } from "console";

interface IPaymentAndOrder {
  newClientRegistration: INewClientRegistration;
  setNewClientRegistration: React.Dispatch<
    React.SetStateAction<INewClientRegistration>
  >;
  changeStep: Function;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  handleCheckboxChange: (event: any) => void;
  setAffinipayPaymentDetailsToken: React.Dispatch<
    React.SetStateAction<IAffinipayPaymentToken>
  >;
  signUpErrors: ISignUpErrors;
  setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
  affinipayPaymentDetailsToken: IAffinipayPaymentToken;
  countries: ICountryDto[];
}

const PaymentAndOrder = (paymentAndOrder: IPaymentAndOrder) => {
  const [hostedFields, setHostedFields] =
    useState<IAffinipayPaymentInformation>();
  const [affiniPayCardError, setAffiniPayCardError] = useState<string>("");
  const [affiniPayCvvError, setAffiniPayCvvError] = useState<string>("");
  const [affiniPayGeneratingTokenError, setAffiniPayGeneratingTokenError] =
    useState<string>("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [areRequiredFieldsUndefined, setAreRequiredFieldsUndefined] =
    useState<boolean>(true);
  const [areTouchedFieldsWithErrors, setAreTouchedFieldsWithErrors] =
    useState<boolean>(true);
    const [hasDifferentBillingErrors, setHasDifferentBillingErrors] =
    useState<boolean>(true);

  useEffect(() => {
    let areRequiredFieldsUndefined = evaluateRequireUndefinedFields(
      paymentAndOrder.signUpErrors.paymentInformationError,
      paymentAndOrder.newClientRegistration.paymentInformation
    );
    setAreRequiredFieldsUndefined(areRequiredFieldsUndefined);
  }, [
    paymentAndOrder.newClientRegistration.paymentInformation,
    paymentAndOrder.signUpErrors.paymentInformationError
  ]);

  useEffect(() => {
    let areTouchedFieldsWithErrors = evaluateTouchedFieldsWithErrors(
      paymentAndOrder.signUpErrors.paymentInformationError
    );
    setAreTouchedFieldsWithErrors(areTouchedFieldsWithErrors);
  }, [paymentAndOrder.signUpErrors.paymentInformationError]);

  useEffect(() => {
    initAffiniPay(setHostedFields, hostedFieldsCallBack);
  }, []);

  const hostedFieldsCallBack = function (state: IAffiniPayState) {
    var cardError = state.fields[0].error;
    setAffiniPayCardError(cardError);

    var cvvError = state.fields[1].error;
    setAffiniPayCvvError(cvvError);
  };

  const getAffiniPayToken = async () => {
    setErrorMessage("");
    setSubmitDisabled(true);
    if (!hostedFields || !hostedFields.getState()) {
      console.error("Hosted fields not ready");
      return;
    }
    try {
      let affinipayPaymentToken: AffinipayPaymentToken =
        await hostedFields.getPaymentToken({
          exp_year:
            20 +
            paymentAndOrder.newClientRegistration.paymentInformation.expiration.split(
              "/"
            )[1],
          exp_month:
            paymentAndOrder.newClientRegistration.paymentInformation.expiration.split(
              "/"
            )[0],
          postal_code:
            paymentAndOrder.newClientRegistration.paymentInformation.postalCode,
          name: `${paymentAndOrder.newClientRegistration.paymentInformation.firstName} ${paymentAndOrder.newClientRegistration.paymentInformation.lastName}`,
          address1: `${
            paymentAndOrder.newClientRegistration.differentBillingAddress
              .address
              ? paymentAndOrder.newClientRegistration.differentBillingAddress
                  .address
              : paymentAndOrder.newClientRegistration.contactDetails.address
          }`,
        });
      paymentAndOrder.setAffinipayPaymentDetailsToken(affinipayPaymentToken);
    } catch (error: any) {
      setAffiniPayGeneratingTokenError({ error: error.message }.error);
    }
  };

  // when the token generation fails, we show the message and we reset everything when the timeout is triggered
  useEffect(() => {
    if (
      affiniPayGeneratingTokenError &&
      affiniPayGeneratingTokenError.length > 0
    ) {
      setTimeout(() => {
        hostedFields?.clearSavedPaymentMethod();
        setAffiniPayGeneratingTokenError("");
        resetPaymentInformation();
        setSubmitDisabled(false);
      }, 2500);
    }
  }, [affiniPayGeneratingTokenError]);

  useEffect(() => {
    paymentAndOrder.affinipayPaymentDetailsToken.id ? saveInformation() : null;
  }, [paymentAndOrder.affinipayPaymentDetailsToken]);

  const resetPaymentInformation = () => {
    paymentAndOrder.setNewClientRegistration({
      ...paymentAndOrder.newClientRegistration,
      paymentInformation: new PaymentInfromationModel(),
    });
  };

  const saveInformation = async () => {
    try {
      const newClientPayload = _mapToNewClientPayload(
        paymentAndOrder.newClientRegistration,
        paymentAndOrder.affinipayPaymentDetailsToken
      );
      await _postClientInformation(newClientPayload);
      setShowSuccessMessage(true);
      paymentAndOrder.changeStep("next");
    } catch (error) {
      const typedError = error as ApiError;
      setErrorMessage(typedError.details);
    } finally {
      setSubmitDisabled(false);
    }
  };
  return (
    <div className="display-flex-cel-block basic-scroll-hide">
       <div className="form">
      <PaymentInformation
        newClientRegistration={paymentAndOrder.newClientRegistration}
        setNewClientRegistration={paymentAndOrder.setNewClientRegistration}
        isChecked={paymentAndOrder.isChecked}
        setIsChecked={paymentAndOrder.setIsChecked}
        handleCheckboxChange={paymentAndOrder.handleCheckboxChange}
        setAffinipayPaymentDetailsToken={
          paymentAndOrder.setAffinipayPaymentDetailsToken
        }
        signUpErrors={paymentAndOrder.signUpErrors}
        setSignUpErrors={paymentAndOrder.setSignUpErrors}
        affiniPayCardError={affiniPayCardError}
        affiniPayCvvError={affiniPayCvvError}
        changeStep={paymentAndOrder.changeStep}
      />
      {paymentAndOrder.isChecked && (
        <DifferentBillingAddress
          newClientRegistration={paymentAndOrder.newClientRegistration}
          setNewClientRegistration={paymentAndOrder.setNewClientRegistration}
          countries={paymentAndOrder.countries}
          signUpErrors={paymentAndOrder.signUpErrors}
          setSignUpErrors={paymentAndOrder.setSignUpErrors}
          changeStep={paymentAndOrder.changeStep}
          setIsChecked={paymentAndOrder.setIsChecked}
          setBillingErrors={setHasDifferentBillingErrors}
        />
      )}
      </div>
   <OrderSummary
        newClientRegistration={paymentAndOrder.newClientRegistration}
        setNewClientRegistration={paymentAndOrder.setNewClientRegistration}
        changeStep={paymentAndOrder.changeStep}
        submitDisabled={submitDisabled}
        errorMessage={errorMessage}
        showSuccessMessage={showSuccessMessage}
        getAffiniPayToken={() => getAffiniPayToken()}
        affiniPayGeneratingTokenError={affiniPayGeneratingTokenError}
        affiniPayCardError={affiniPayCardError}
        affiniPayCvvError={affiniPayCvvError}
        areTouchedFieldsWithErrors={areTouchedFieldsWithErrors}
        areRequiredFieldsUndefined={areRequiredFieldsUndefined}
        hasDifferentBillingErrors={paymentAndOrder.isChecked? hasDifferentBillingErrors:false}

        />
      
    </div>
  );
};
export default PaymentAndOrder;

const evaluateRequireUndefinedFields = (
  errors: IPaymentInformationError,
  contactDetails: IPaymentInformation
): boolean => {
  let areRequiredFieldsUndefined = false;
  const keys = Object.keys(contactDetails) as (keyof typeof contactDetails)[];
  var isAnyRequireUndefinedField = keys.find(
    (key) =>
      (contactDetails[key] === undefined ||
        contactDetails[key]!.toString().length === 0) &&
      errors[key].required === true
  );
  areRequiredFieldsUndefined = isAnyRequireUndefinedField !== undefined;
  return areRequiredFieldsUndefined;
};

const evaluateTouchedFieldsWithErrors = (
  errors: IPaymentInformationError
): boolean => {
  let areTouchedFieldsWithErrors = false;
  let errorFields = Object.values(errors);
  let erroredFields = errorFields.filter((errorField) =>
    errorField.hasOwnProperty("message")
  );
  var isAnyFieldsWithErrors = erroredFields.find(
    (error) => error.message?.length > 0
  );
  areTouchedFieldsWithErrors = isAnyFieldsWithErrors !== undefined;
  return areTouchedFieldsWithErrors;
};
