import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";

interface SelectedBusinessAddressServiceProps {
  newClientRegistration:INewClientRegistration
}

const SelectedRegistrationOptionalService = (selectedBusinessAddressService :SelectedBusinessAddressServiceProps) => {
    return (
        <div >
        {selectedBusinessAddressService.newClientRegistration.companyInformation.serviceSubscriptions && selectedBusinessAddressService.newClientRegistration.companyInformation.serviceSubscriptions.length > 0 ? (
          <div className="mb-3 selectedServices">
            <label>{selectedBusinessAddressService.newClientRegistration.companyInformation.serviceSubscriptions[0].servicePackageName}</label>
            <b>${selectedBusinessAddressService.newClientRegistration.companyInformation.serviceSubscriptions[0].servicePackageAmount}</b>
          </div>
        ) : null}
      </div>
    );
};
export default SelectedRegistrationOptionalService;