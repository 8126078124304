import axios from "axios";
import { ICountryDto } from "../../models/country/ICountryDto";

const baseUrl = "country"

async function _getCountries(): Promise<ICountryDto[]> {
 let response = await axios.get(baseUrl)
  return response.data;
}
 
export {_getCountries};
