import React, { useEffect } from 'react';
import { IServicesPackageDto } from '../../../../../models/servicesPackage/IServicesPackageDto';
import { IService } from '../../../../../models/service/IService';

interface TotalAddonPackagesProps {
    setTotalAmount: React.Dispatch<React.SetStateAction<number>>;
    totalAmount: number;
    selectedServicesAndPackages: {selectedService:IService,selectedPackage:IServicesPackageDto}[];
}

const TotalAddonPackages = (totalAddonPackagesProps: TotalAddonPackagesProps) => {

    useEffect(() => {
        const totalAmount = totalAddonPackagesProps.selectedServicesAndPackages.reduce(
          (accumulator, serviceAndPackage) => accumulator + serviceAndPackage.selectedPackage.amount,
          0
        );
        totalAddonPackagesProps.setTotalAmount(totalAmount);
      }, [totalAddonPackagesProps.selectedServicesAndPackages]);
      
    return (
        <div className="mt-2 total">
            <label className="totalText">Total</label>
            <div className="totalValue">${totalAddonPackagesProps.totalAmount}</div>
        </div>
    );
};

export default TotalAddonPackages;