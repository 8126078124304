import './logoHeader.scss';
import logoImage from './floridaAgents.png';  

const LogoHeader = () => {
  return (
    <div className="logoHeader">
      <img src={logoImage} alt="logoImage" className="companyLogo"/>
    </div>
  );
};

export default LogoHeader;