import { useState } from "react";
import { Modal } from "react-bootstrap";
import { QuestionCircle } from "../../../icons/QuestionCircle";

export const BoxTextNameCompanyModal = () => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleShow = () => {
        setShowModal(true);
      };
      const handleClose = () => {
        setShowModal(false);
      };

return (   
<div>
    <div onClick={() => handleShow()} >
    <QuestionCircle />
    </div>
    <Modal show={showModal} onHide={handleClose} >
        <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">Company Name</h1>
        </div>
        <div className="smodal-body">
            The specific name you have chosen for your company, including the business identifier (LLC, Inc., etc.)
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
        </div> 
    </Modal>
</div>)};
