import { IErroredField, IRequiredField } from "../../validation/IErroredAndRequiredField";

export interface IPaymentInformationError {
    firstName: IRequiredField & IErroredField;
    lastName: IRequiredField & IErroredField;
    expiration: IRequiredField & IErroredField;
    postalCode: IRequiredField & IErroredField;
}

const paymentInformationError: IPaymentInformationError = {
    firstName: {
        required: true,
        getValidationErrors: (value:string): string => {
            let errorMessages = [];
            if (value.length <= 0) {
                errorMessages.push("first name is required");
            }
            if (!/^[A-Za-z\s]+$/.test(value)) {
                errorMessages.push("only letters and spaces");
            }
            if (/ {2,}/.test(value)) {
                errorMessages.push("Avoid more than one consecutive spaces.");
            }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
    lastName: { 
        required: true,
        getValidationErrors: (value:string): string => {
            let errorMessages = [];
            if (value.length <= 0) {
                errorMessages.push("last name is required");
            }
            if (!/^[A-Za-z\s]+$/.test(value)) {
                errorMessages.push("only letters and spaces");
            }
            if (/ {2,}/.test(value)) {
                errorMessages.push("Avoid more than one consecutive spaces.");
            }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
    expiration: { 
        required: true,
        getValidationErrors: (value:string): string => {
            let errorMessages = [];
            if (value.length <= 0) {
                errorMessages.push("Expiration date is required");
            }
            const datePattern = /^(0[1-9]|1[0-2])\/(\d{2})$/;
            if (!datePattern.test(value)) {
                errorMessages.push("Invalid expiration date. Valid format: MM/YY");
            }
            const [month, year] = value.split('/').map(Number);
            const currentYear = new Date().getFullYear() % 100; 
            if (year < currentYear) {
                errorMessages.push("Expiration date should be a future date.");
            }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
    postalCode: { 
        required: true,
        getValidationErrors: (value:string): string => {
            let errorMessages = [];
            if (value.length <= 0) {
                errorMessages.push("Postal is required");
            }
            if (/ {2,}/.test(value)) {
                errorMessages.push("Avoid more than one consecutive spaces.");
            }
            let joinedErrors = "";
            if (errorMessages.length > 0) {
                joinedErrors = errorMessages.join(', ');
            } 
            return joinedErrors;
        },
        message: ""
    },
}
export default paymentInformationError

