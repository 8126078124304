import IPaymentInformation from "../../../../../models/client/newRegisterAgent/paymentInformation/IPaymentInformation";
import { IService } from "../../../../../models/service/IService";
import { IServicesPackageDto } from "../../../../../models/servicesPackage/IServicesPackageDto";
import paymentInformationError, {
  IPaymentInformationError,
} from "../../../../signUp/paymenyInformation/validation/IPaymentInformationError";
import AddonCardNumber from "./addonPaymentForm/AddonCardNumber";
import AddonExpiration from "./addonPaymentForm/AddonExpiration";
import AddonFirstName from "./addonPaymentForm/AddonFirstName";
import AddonLastName from "./addonPaymentForm/AddonLastName";
import AddonSecurityCode from "./addonPaymentForm/AddonSecurityCode";
import { useEffect, useState } from "react";
import "./addonPaymentCard.scss";
import AddonPostalCode from "./addonPaymentForm/AddonPostalCode";
import visa from "../../../../../Assets/images/visa.png";
import mastercard from "../../../../../Assets/images/mastercard.png";
import americanexpress from "../../../../../Assets/images/americanexpress.png";
import discover from "../../../../../Assets/images/discover.png";


interface AddonPaymentCardProps {
  selectedServicesAndPackages: {
    selectedService: IService;
    selectedPackage: IServicesPackageDto;
  }[];
  affiniPayCardError: string;
  affiniPayCvvError: string;
  paymentInformation: IPaymentInformation;
  setPaymentInformation: React.Dispatch<
    React.SetStateAction<IPaymentInformation>
  >;
  setAreTouchedFieldsWithErrors: React.Dispatch<React.SetStateAction<boolean>>;
  setAreRequiredFieldsUndefined: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddonPaymentCard = (addonPaymentCardProps: AddonPaymentCardProps) => {
  const [addonsErrors, setAddonsErrors] = useState<IPaymentInformationError>(
    paymentInformationError
  );

  useEffect(() => {
    let areRequiredFieldsUndefined = evaluateRequireUndefinedFields(
      addonsErrors,
      addonPaymentCardProps.paymentInformation
    );
    addonPaymentCardProps.setAreRequiredFieldsUndefined(
      areRequiredFieldsUndefined
    );
  }, [addonsErrors]);

  useEffect(() => {
    let areTouchedFieldsWithErrors =
      evaluateTouchedFieldsWithErrors(addonsErrors);
    addonPaymentCardProps.setAreTouchedFieldsWithErrors(
      areTouchedFieldsWithErrors
    );
  }, [addonsErrors]);

  return (
    <div className="card-template">
      <div className="small-titles">Payment</div>
      <label className="form__subTitle">
        We accept all major credit cards.
        <br />
        Please double-check your order.
        <br />
        All fees paid pass this stage are NON-REFUNDABLE.
      </label>
      <div className="creditCardsImg">
        <img src={visa} alt="visa" />
        <img src={mastercard} alt="mastercard" />
        <img src={americanexpress} alt="americanexpress" />
        <img src={discover} alt="discover" className="discover" />
      </div>
      <form >
        <div className="form__twoInputInOneRow">
          <AddonFirstName
            addonsErrors={addonsErrors}
            setAddonsErrors={setAddonsErrors}
            setPaymentInformation={addonPaymentCardProps.setPaymentInformation}
            paymentInformation={addonPaymentCardProps.paymentInformation}
          />
          <AddonLastName
            addonsErrors={addonsErrors}
            setAddonsErrors={setAddonsErrors}
            setPaymentInformation={addonPaymentCardProps.setPaymentInformation}
            paymentInformation={addonPaymentCardProps.paymentInformation}
          />
        </div>
        <div className="form__twoInputInOneRow ">
          <AddonCardNumber
            affiniPayCardError={addonPaymentCardProps.affiniPayCardError}
          />
          <AddonSecurityCode
            affiniPayCvvError={addonPaymentCardProps.affiniPayCvvError}
          />
        </div>
        <div className="form__twoInputInOneRow ">
          <AddonExpiration
            addonsErrors={addonsErrors}
            setAddonsErrors={setAddonsErrors}
            setPaymentInformation={addonPaymentCardProps.setPaymentInformation}
            paymentInformation={addonPaymentCardProps.paymentInformation}
          />
          <AddonPostalCode
            addonsErrors={addonsErrors}
            setAddonsErrors={setAddonsErrors}
            setPaymentInformation={addonPaymentCardProps.setPaymentInformation}
            paymentInformation={addonPaymentCardProps.paymentInformation}
          />
        </div>
      </form>
    </div>
  );
};
export default AddonPaymentCard;

const evaluateRequireUndefinedFields = (
  errors: IPaymentInformationError,
  paymentInformation: IPaymentInformation
): boolean => {
  let areRequiredFieldsUndefined = false;
  const keys = Object.keys(
    paymentInformation
  ) as (keyof typeof paymentInformation)[];
  var isAnyRequireUndefinedField = keys.find(
    (key) =>
      (paymentInformation[key] === undefined ||
        paymentInformation[key]!.toString().length === 0) &&
      errors[key].required === true
  );
  areRequiredFieldsUndefined = isAnyRequireUndefinedField !== undefined;
  return areRequiredFieldsUndefined;
};

const evaluateTouchedFieldsWithErrors = (
  errors: IPaymentInformationError
): boolean => {
  let areTouchedFieldsWithErrors = false;
  let errorFields = Object.values(errors);
  let erroredFields = errorFields.filter((errorField) =>
    errorField.hasOwnProperty("message")
  );
  var isAnyFieldsWithErrors = erroredFields.find(
    (error) => error.message?.length > 0
  );
  areTouchedFieldsWithErrors = isAnyFieldsWithErrors !== undefined;
  return areTouchedFieldsWithErrors;
};

