import { useEffect, useState } from "react";
import INewClientRegistration from "../../../models/client/newRegisterAgent/INewClientRegistration";
import Email from "./email/Email";
import Password from "./password/Password";
import singUpErrors, { ISignUpErrors } from "../validation/ISignUpErrors";
import { IAcountSetupErrors } from "./validations/AcountSetupErrors";
import IAcountSetup from "../../../models/client/newRegisterAgent/acountSetup/IAcountSetup";
import { isUserDefined } from "../../../APIs/user/userApi";
import PasswordValidationCopy from "./password/PasswordValidationCopy";

interface ISetupProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    changeStep: Function
    signUpErrors: ISignUpErrors
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>
}

const AcountSetup = (setupProps: ISetupProps) => {
    const [areRequiredFieldsUndefined, setAreRequiredFieldsUndefined] = useState<boolean>(true);
    const [areTouchedFieldsWithErrors, setAreTouchedFieldsWithErrors] = useState<boolean>(true);
    const userAlreadyExistsMessage =  "There is a user already defined for this email, please try a different one";

    const handleStateClick = async (goTo:string) => {
        let areTouchedFieldsWithErrors  = evaluateTouchedFieldsWithErrors(setupProps.signUpErrors.acountSetupErrors)
        let userAlreadyExists = await isUserDefined(setupProps.newClientRegistration.acountSetup.email);
        if(userAlreadyExists){
            setEmailError(userAlreadyExistsMessage);
        }
        else{
            ////the following conditional is necessary because in some browsers when we work with passwords an event is activated and the validations are passed
            areTouchedFieldsWithErrors?
            null
            :
            setupProps.changeStep(goTo)
        }
    }

    const setEmailError = (error: string) => {
        setupProps.setSignUpErrors(prevState => ({
            ...prevState,
            acountSetupErrors: {
                ...prevState.acountSetupErrors,
                email: {
                    ...prevState.acountSetupErrors.email,
                    message: error
                }
            }
        }));
    }

    const setPasswordError = (error: string) => {
        setupProps.setSignUpErrors(prevState => ({
            ...prevState,
            acountSetupErrors: {
                ...prevState.acountSetupErrors,
                password: {
                    ...prevState.acountSetupErrors.password,
                    message: error
                }
            }
        }));
    }

    const setConfirmPasswordError = (error: string) => {
        setupProps.setSignUpErrors(prevState => ({
            ...prevState,
            acountSetupErrors: {
                ...prevState.acountSetupErrors,
                confirmPassword: {
                    ...prevState.acountSetupErrors.confirmPassword,
                    message: error
                }
            }
        }));
    }

    useEffect(() => {
        let areRequiredFieldsUndefined  = evaluateRequireUndefinedFields(setupProps.signUpErrors.acountSetupErrors, setupProps.newClientRegistration.acountSetup)
        setAreRequiredFieldsUndefined(areRequiredFieldsUndefined)
    }, [setupProps.newClientRegistration.acountSetup, setupProps.signUpErrors.acountSetupErrors]);

    useEffect(() => {
        let areTouchedFieldsWithErrors  = evaluateTouchedFieldsWithErrors(setupProps.signUpErrors.acountSetupErrors)
        setAreTouchedFieldsWithErrors(areTouchedFieldsWithErrors)
    }, [setupProps.signUpErrors.acountSetupErrors]);
    
    return (
        <div className="form">
            <div className="form__title">Account Setup</div>  
            <label  className="form__subTitle">Please make sure you use the right email</label>
            <form>
                <Email 
                    newClientRegistration={setupProps.newClientRegistration} 
                    setNewClientRegistration={setupProps.setNewClientRegistration}
                    signUpErrors={setupProps.signUpErrors}
                    setSignUpErrors={setupProps.setSignUpErrors}
                    setEmailError={setEmailError}
                />
                <Password 
                    newClientRegistration={setupProps.newClientRegistration} 
                    setNewClientRegistration={setupProps.setNewClientRegistration}
                    signUpErrors={setupProps.signUpErrors}
                    setSignUpErrors={setupProps.setSignUpErrors}
                    setPasswordError={setPasswordError}
                />
                <PasswordValidationCopy 
                    setConfirmPasswordError={setConfirmPasswordError}
                    signUpErrors={setupProps.signUpErrors}
                    newClientRegistration={setupProps.newClientRegistration} 
                    setNewClientRegistration={setupProps.setNewClientRegistration}
                />
            </form>
            <div className="form__stageButtons">
                <button type="button" className="greyButton" onClick={()=>{setupProps.changeStep("back")}}>Back</button>
                <button 
                    type="button" 
                    className={`primaryButton w-30 h-37${ areTouchedFieldsWithErrors || areRequiredFieldsUndefined ? 'disabled' : ''}`}
                    disabled={areTouchedFieldsWithErrors || areRequiredFieldsUndefined}               
                    onClick={()=>{handleStateClick("next")}}>
                        Next
                </button>
            </div>            
        </div>
    );
};

const evaluateRequireUndefinedFields = (errors: IAcountSetupErrors, companyInformation: IAcountSetup): boolean => {
    let areRequiredFieldsUndefined = false;
    const keys = Object.keys(companyInformation) as (keyof typeof companyInformation)[];
    var isAnyRequireUndefinedField = keys.find(key => (companyInformation[key] === undefined || (companyInformation[key]!.toString().length === 0)) && errors[key].required === true);
    areRequiredFieldsUndefined = isAnyRequireUndefinedField !== undefined;
    return areRequiredFieldsUndefined;
};
 
const evaluateTouchedFieldsWithErrors = (errors: IAcountSetupErrors): boolean => {
    let areTouchedFieldsWithErrors = false;
    let errorFields = Object.values(errors);
    let erroredFields = errorFields.filter(errorField => errorField.hasOwnProperty("message"));
    var isAnyFieldsWithErrors =  erroredFields.find(error => error.message?.length > 0);
    areTouchedFieldsWithErrors = isAnyFieldsWithErrors !== undefined;
    return areTouchedFieldsWithErrors;
};

export default AcountSetup;