import { useEffect, useState } from "react";
import { IClientDto } from "../../../models/client/newRegisterAgent/IClientDto";
import Pagination from "./Pagination";
import { MdMarkunreadMailbox } from "react-icons/md";
import MailboxModal from "./clientDashboard/clientProfileCard/MailboxModal";

interface ClientsTableProps {
  clients: IClientDto[];
  setSClients: React.Dispatch<React.SetStateAction<IClientDto[]>>;
  setSelectedClient: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const ClientsTable = (clientsTableProps: ClientsTableProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [clientId, setClientId] = useState(0);
  const [currentPageContent, setCurrentPageContent] = useState<IClientDto[]>(
    []
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedmailBox, setSelectedmailBox] = useState<
    number | null | undefined
  >(null);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const totalPages = clientsTableProps.clients.length / 20;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setCurrentPageContent(
      clientsTableProps.clients.slice((page - 1) * 20, (page - 1) * 20 + 20)
    );
  };
  useEffect(() => {
    handlePageChange(1);
  }, [clientsTableProps.clients]);

  useEffect(() => {
    if(selectedmailBox){
      currentPageContent.filter((a) => a.id == clientId);
      const updatedData = currentPageContent.map((item) => {
        if (item.id === clientId) {
          return { ...item, mailboxNumber: selectedmailBox };
        }
        return item;
      });
  
      setCurrentPageContent(updatedData);
    }
    
  }, [selectedmailBox]);

  const handleSelectedClientClick = (clientId: number) => {
    clientsTableProps.setSelectedClient(clientId);
  };
  const handleOpenModal = (clientId: number) => {
    setClientId(clientId);
    setShowModal(true);
  };

  return (
    <>
      <div className="table-responsive-md">
        <table className="table align-middle table-hover">
          <thead className="table-head sticky-top">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Company Name</th>
              <th scope="col">Mailbox Number</th>
              <th scope="col">Service Package</th>
              <th scope="col">Signup Date</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {currentPageContent.map((client, index) => (
              <tr key={client.id}>
                <td onClick={() => handleSelectedClientClick(client.id)}>
                  {`${client.firstName} ${client.lastName}`}
                  <tr className="home-table-mail">{client.users[0].email}</tr>
                </td>
                <td>{client.companyName}</td>
                <td>
                  {client?.hasMailbox && !(client?.mailboxNumber && client?.mailboxNumber != 0 ) ? (
                    <a
                      onClick={() => handleOpenModal(client?.id)}
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Assign Mailbox"
                      className="messageBoxIcon btn link"
                      style={{ color: "#0d6efd", float: "left" }}
                    >
                      <MdMarkunreadMailbox />
                    </a>
                  ) : (
                    client?.hasMailbox && (<span style={{ float: "left" }} className="messageBoxIcon">
                      <MdMarkunreadMailbox /> {client?.mailboxNumber}{" "}
                    </span>)
                  )}{" "}
                </td>
                <td>{client.clientService?.service?.servicesPackages[0]?.name}</td>
                <td>
                {
                client?.clientService?.serviceOptInDate?
                new Date(client?.clientService?.serviceOptInDate!).toLocaleDateString("en-US", {
                  dateStyle: "short",
                }):""}
                </td>
                <td style={{ color: client.users[0].active ? "green" : "red" }}>
                  {client.users[0].active ? "Active" : "Inactive"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <MailboxModal
        setMailboxNumber={setSelectedmailBox}
        clientId={clientId}
        showModal={showModal}
        handleClose={handleCloseModal}
      />
    </>
  );
};

export default ClientsTable;
