import { useEffect, useState } from "react";
import { IClientInvoiceDto } from "../../../models/clientInvoice/IClientInvoiceDto";
import InvoicesTable from "../../../components/clients/invoices/InvoicesTable";
import { Button } from "react-bootstrap";
import { FaDollarSign, FaDownload, FaThumbsUp } from "react-icons/fa6";
import InvoicePaymentCards from "../../../components/clients/invoices/InvoicePaymentCards";
import { _downlaodInvoiceList } from "../../../APIs/client/ClientApi";

const ClientInvoices = () => {
  const [invoices, setInvoices] = useState<IClientInvoiceDto[]>([]);
  const [showPayment, setShowPayment] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<string[]>([]);
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const onDownloadClick = async () => {
    setIsDownloading(true);
    await _downlaodInvoiceList(selectedInvoice);
    setIsDownloading(false);
  };

  return (
    <div className="clientDocuments">
      <div className="providerInformation__state">
        {showPayment ? "Invoice Details" : "Invoices"}
      </div>
      {showPayment ? null : (
        <div>
          <div className="components-and-search-button-card">
            <div className={`computer-management-top-buttons`}>
              <Button
                disabled={isPaid || selectedInvoice.length == 0}
                onClick={() => setShowPayment(true)}
              >
                <FaDollarSign />
                Pay Invoice
              </Button>
              <Button
                onClick={onDownloadClick}
                disabled={selectedInvoice.length == 0 || isDownloading}
              >
                {isDownloading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <FaDownload />
                )}{" "}
                Download
              </Button>
            </div>
          </div>
        </div>
      )}

      {showPayment ? (
        <InvoicePaymentCards selectedInvoiceIds={selectedInvoice} />
      ) : (
        <InvoicesTable
          invoices={invoices}
          setInvoices={setInvoices}
          setSelectedInvoice={setSelectedInvoice}
          setIsPaid={setIsPaid}
        />
      )}
    </div>
  );
};
export default ClientInvoices;
