import { useEffect, useState } from "react";
import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import "./businessAddressAndPaidMail.scss"
import { IService } from "../../../../models/service/IService";
import { _getServicesByCategory } from "../../../../APIs/servicesCategory/ServicesCategory";
import IServiceSubscriptions from "../../../../models/client/newRegisterAgent/companyInformation/serviceSubscriptions/IServiceSubscriptions";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface IChangingRegisteredAgentsProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const BusinessAddressAndPaidMail = (changingRegisteredAgentsProps:IChangingRegisteredAgentsProps) => {
    const [servicesCategory, setServicesCategory] = useState<IService[]>([]); 
    const ourAddressServicesCategory = "CLIENT_REGISTRATION_OUR_ADDRESS" 

  useEffect(() => {
    initOurAddressServices()  
    }, []);

    const initOurAddressServices = async () => {  
        const servicesCategory = await _getServicesByCategory(ourAddressServicesCategory);
        console.log(servicesCategory)
        setServicesCategory(servicesCategory);
    };

    const addToClientRegistration = (selectedService: IServiceSubscriptions[] | undefined) => {
        changingRegisteredAgentsProps.setNewClientRegistration({
            ...changingRegisteredAgentsProps.newClientRegistration,
            companyInformation: {
                ...changingRegisteredAgentsProps.newClientRegistration.companyInformation,
                serviceSubscriptions: selectedService,
            },
        });
    };
    const setError = (error: string) => {
        changingRegisteredAgentsProps.setSignUpErrors(prevState => ({
            ...prevState,
            companyInformationErrors: {
                ...prevState.companyInformationErrors,
                serviceSubscriptions: {
                    ...prevState.companyInformationErrors.serviceSubscriptions,
                    message: error
                }
            }
        }));
    }
    const handleOptionSelect = (selectedService: IService, useAgentAddress: boolean ) => {
    const selectedClientService: IServiceSubscriptions[] = [{
        active: selectedService.active,
        servicePackageId:selectedService.servicesPackages[0].id,
        serviceId: selectedService.id,
        serviceName: selectedService.name,
        servicePackageName: selectedService.servicesPackages[0].name,
        serviceDescription: selectedService.description,
        servicePackageDescription : selectedService.servicesPackages[0].description,
        servicePackageAmount : selectedService.servicesPackages[0].amount,
        useAgentAddress : useAgentAddress,
        }]
        addToClientRegistration(selectedClientService)
    }

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = changingRegisteredAgentsProps.signUpErrors.companyInformationErrors.serviceSubscriptions.getValidationErrors(e.target.id);
        setError(error);
    }

    return (
        <div className="mb-3 businessAddressAndPaidMail" >
            <div><b>Our Florida Address</b></div>
            <div className="form-check">
                {servicesCategory.length>0 && 
                    servicesCategory?.map((service) => (
                    <div 
                        className="form-check" 
                        key={service.id}>
                        <input
                            className="form-check-input"
                            id="ourFloridaAddressOptions"
                            type="radio"
                            name="radioIdentity"
                            onChange={handleOnBlur}
                            checked={changingRegisteredAgentsProps?.newClientRegistration?.companyInformation?.serviceSubscriptions && changingRegisteredAgentsProps?.newClientRegistration?.companyInformation?.serviceSubscriptions[0]?.serviceId === service?.id ? true : false}  
                            onClick={() => {
                                handleOptionSelect(service, true);
                            }} 

                        />
                       <label>
                            {service.servicesPackages ? 
                            `${service.description} - $${service.servicesPackages[0].amount}`
                            : ""}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default BusinessAddressAndPaidMail;