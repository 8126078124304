import { IClientInvoicePymentSummary } from "../../../models/clientInvoice/IClientInvoicePymentSummary";

interface InvoiceSummaryCardProps {
  ClientInvoicePaymentSummarys: IClientInvoicePymentSummary[];
  showLoadingButton: boolean;
}

const InvoiceSummaryCard = (
  invoiceSummaryCardProps: InvoiceSummaryCardProps
) => {

  return (
    <div>
      {invoiceSummaryCardProps?.ClientInvoicePaymentSummarys?.map((invoice) => (
        <div key={invoice.clientInvoice.id}>
          <h4>Invoice : {invoice.clientInvoice.id}</h4>

          <table className="table align-middle table-hover">
            <thead className="table-head sticky-top">
              <tr>
                <th scope="col">Service</th>
                <th scope="col">Billing Cycle</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {invoice.clientInvoiceDetails.map((detail) => (
                <tr key={detail?.id}>
                  <td>{detail?.service?.name}</td>
                  <td>
                    {new Date(detail?.serviceFromDate!).toLocaleDateString(
                      "en-US",
                      {
                        dateStyle: "short",
                      }
                    )}{" "}
                    -{" "}
                    {new Date(detail?.serviceToDate!).toLocaleDateString(
                      "en-US",
                      {
                        dateStyle: "short",
                      }
                    )}
                  </td>
                  <td>$ {detail?.servicePackage?.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default InvoiceSummaryCard;
