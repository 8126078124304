const UsaStates = () =>
{
    return [
        { "id": "AL", "state": "Alabama" },
        { "id": "AK", "state": "Alaska" },
        { "id": "AZ", "state": "Arizona" },
        { "id": "AR", "state": "Arkansas" },
        { "id": "CA", "state": "California" },
        { "id": "CO", "state": "Colorado" },
        { "id": "CT", "state": "Connecticut" },
        { "id": "DE", "state": "Delaware" },
        { "id": "FL", "state": "Florida" },
        { "id": "GA", "state": "Georgia" },
        { "id": "HI", "state": "Hawaii" },
        { "id": "ID", "state": "Idaho" },
        { "id": "IL", "state": "Illinois" },
        { "id": "IN", "state": "Indiana" },
        { "id": "IA", "state": "Iowa" },
        { "id": "KS", "state": "Kansas" },
        { "id": "KY", "state": "Kentucky" },
        { "id": "LA", "state": "Louisiana" },
        { "id": "ME", "state": "Maine" },
        { "id": "MD", "state": "Maryland" },
        { "id": "MA", "state": "Massachusetts" },
        { "id": "MI", "state": "Michigan" },
        { "id": "MN", "state": "Minnesota" },
        { "id": "MS", "state": "Mississippi" },
        { "id": "MO", "state": "Missouri" },
        { "id": "MT", "state": "Montana" },
        { "id": "NE", "state": "Nebraska" },
        { "id": "NV", "state": "Nevada" },
        { "id": "NH", "state": "New Hampshire" },
        { "id": "NJ", "state": "New Jersey" },
        { "id": "NM", "state": "New Mexico" },
        { "id": "NY", "state": "New York" },
        { "id": "NC", "state": "North Carolina" },
        { "id": "ND", "state": "North Dakota" },
        { "id": "OH", "state": "Ohio" },
        { "id": "OK", "state": "Oklahoma" },
        { "id": "OR", "state": "Oregon" },
        { "id": "PA", "state": "Pennsylvania" },
        { "id": "RI", "state": "Rhode Island" },
        { "id": "SC", "state": "South Carolina" },
        { "id": "SD", "state": "South Dakota" },
        { "id": "TN", "state": "Tennessee" },
        { "id": "TX", "state": "Texas" },
        { "id": "UT", "state": "Utah" },
        { "id": "VT", "state": "Vermont" },
        { "id": "VA", "state": "Virginia" },
        { "id": "WA", "state": "Washington" },
        { "id": "WV", "state": "West Virginia" },
        { "id": "WI", "state": "Wisconsin" },
        { "id": "WY", "state": "Wyoming" }
    ]
};  export default UsaStates


