import IAuthenticationService from "./IAuthenticationService";
import {_getSession} from "../session/SessionService";

// we do this in case we want to have the clase instatieted somewhere
class AuthenticationService implements IAuthenticationService {
    isUserAuthenticated(): boolean {
        return _isUserAuthenticated();
    }
}

// we do this so we can import only this function without needing to instantiate a class for method consumption.
function _isUserAuthenticated(): boolean {
    let session = _getSession();
    let isUserAuthenticated = session !==undefined && session!==null && session.length > 0;
    return isUserAuthenticated;
}

export { AuthenticationService, _isUserAuthenticated }