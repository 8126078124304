import IPaymentInformation from "../../../../../../models/client/newRegisterAgent/paymentInformation/IPaymentInformation";
import { IPaymentInformationError } from "../../../../../signUp/paymenyInformation/validation/IPaymentInformationError";

interface AddonExpirationProps {
    addonsErrors: IPaymentInformationError;
    setAddonsErrors: React.Dispatch<React.SetStateAction<IPaymentInformationError>>;
    setPaymentInformation:React.Dispatch<React.SetStateAction<IPaymentInformation>>
    paymentInformation:IPaymentInformation
}

const AddonExpiration = (addonExpirationProps:AddonExpirationProps) => {
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        var error = addonExpirationProps.addonsErrors.expiration.getValidationErrors(e.target.value);
        addonExpirationProps.setAddonsErrors(prevState => ({
                    ...prevState,
                    expiration: {
                        ...prevState.expiration,
                        message: error
                    }
                }));
        }
        const setError = (error: string) => {
            addonExpirationProps.setAddonsErrors(prevState => ({
                ...prevState,
                    expiration: {
                        ...prevState.expiration,
                        message: error
                     }
            }));
        }

    return (
        <div className="twoInputInOneRow-input addon-heigth">
            <input 
                    value={addonExpirationProps.paymentInformation.expiration}
                    type="text"
                    className={`addon-form-control ${addonExpirationProps.addonsErrors.expiration.message ? 'input-error' : ''}`} 
                    placeholder="Expiration" 
                    maxLength={50}
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                        addonExpirationProps.setPaymentInformation({
                                ...addonExpirationProps.paymentInformation,
                                expiration: e.target.value
                    });
                    setError("");
                }}/>   
            <div className="addon-errorMessage">{addonExpirationProps.addonsErrors.expiration.message} </div> 
        </div>
    );
};
export default AddonExpiration;