import { useContext, useState } from "react";
import { IService } from "../../../models/service/IService";
import { IServicesPackageDto } from "../../../models/servicesPackage/IServicesPackageDto";
import TotalAddonPackages from "../addon/paymentCards/addonSummary/TotalAddonPackages";
import { IClientInvoicePymentSummary } from "../../../models/clientInvoice/IClientInvoicePymentSummary";
import TotalInvoicePackages from "./TotalInvoicePackages";
import "./invoicePayment.scss"

interface AddonTotalAmountCardProps {
  getAffiniPayToken: (e: React.MouseEvent<HTMLButtonElement>) => void; 
  affiniPayGeneratingTokenError: string
  affiniPayCardError:string
  affiniPayCvvError:string
  areTouchedFieldsWithErrors:boolean
  isPaid:boolean
  areRequiredFieldsUndefined:boolean
  showLoadingButton:boolean
  ClientInvoicePaymentSummarys: IClientInvoicePymentSummary[];

  }

const InvoiceTotalAmountCard = (addonTotalAmountCardProps: AddonTotalAmountCardProps) => {
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const successMessage = "Your order was successfully processed, enjoy our services!"
  
  return(
    <div className="payment__sumamary card-basic-style form ">
      <form className="flex-column small-text">
        Billed to:
        <strong className="sentence-case">{addonTotalAmountCardProps?.ClientInvoicePaymentSummarys[0]?.client?.companyName}</strong>
 <div className="sentence-case">      {addonTotalAmountCardProps?.ClientInvoicePaymentSummarys[0]?.client?.firstName + " " + addonTotalAmountCardProps?.ClientInvoicePaymentSummarys[0]?.client?.lastName}  </div>
 <div className="sentence-case">    {addonTotalAmountCardProps?.ClientInvoicePaymentSummarys[0]?.client?.address}   </div>
 <div className="sentence-case">    {addonTotalAmountCardProps?.ClientInvoicePaymentSummarys[0]?.client?.city}   </div>
 <div className="sentence-case">    {addonTotalAmountCardProps?.ClientInvoicePaymentSummarys[0]?.client?.stateProvince + ", " + addonTotalAmountCardProps?.ClientInvoicePaymentSummarys[0]?.client?.zipcode}   </div>
        <TotalInvoicePackages totalAmount={addonTotalAmountCardProps?.ClientInvoicePaymentSummarys[0]?.total} setTotalAmount={setTotalAmount} />
                  
      </form>
      <div className="submitStageButtons d-flex">
        {addonTotalAmountCardProps.showLoadingButton ? 
          <button className="primaryButton  basic-font w-150px h-100" type="button" disabled>
            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <span role="status">Processing...</span>
          </button>
          :
          <button
              type="button"
              className={`primaryButton w-fit-35 h-37 ${
                addonTotalAmountCardProps.areRequiredFieldsUndefined ||
                addonTotalAmountCardProps.areTouchedFieldsWithErrors ||
                addonTotalAmountCardProps.affiniPayCardError.length > 0 ||
                addonTotalAmountCardProps.affiniPayCvvError.length > 0 ||
                addonTotalAmountCardProps.affiniPayGeneratingTokenError.length > 0 }
                ? 'disabled' : ''}`}
              onClick={(e) => {
                addonTotalAmountCardProps.getAffiniPayToken(e);
              }}
              disabled={
                addonTotalAmountCardProps.areRequiredFieldsUndefined ||
                addonTotalAmountCardProps.areTouchedFieldsWithErrors ||
                addonTotalAmountCardProps.affiniPayCardError.length > 0 ||
                addonTotalAmountCardProps.affiniPayCvvError.length > 0 ||  
                addonTotalAmountCardProps.affiniPayGeneratingTokenError.length > 0 ||
                addonTotalAmountCardProps.isPaid} 
              >
              Pay
          </button>
        }
        <p className="errorMessage">{addonTotalAmountCardProps.affiniPayGeneratingTokenError}</p>
        {showSuccessMessage && (
          <div className="successMessage">{successMessage}</div>
        )}
         {addonTotalAmountCardProps.isPaid && (
          <div className="successMessage">{"Payment processed successfully"}</div>
        )}    
      </div> 
    </div>
  )
}

export default InvoiceTotalAmountCard;