import axios from "axios";
import { IAgentMailboxDto } from "../../models/agentMailbox/IAgentMailboxDto";

const baseUrl = "mailbox";

async function _getAvailableMailBoxes(): Promise<IAgentMailboxDto[]> {
  let response = await axios.get(`${baseUrl}/getAvailableMailBoxes`);
  return response.data;
}

export { _getAvailableMailBoxes };
