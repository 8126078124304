import PrivateRoute from "./components/routeGuards/PrivateRoute";
import Layout from "./pages/layout/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Provider from "./pages/providers/Provider";
import Client from "./pages/clients/Client";
import ErrorPage from "./pages/error/ErrorPage";
import AgentInvoices from "./pages/providers/invoices/AgentInvoices";
import ClientInvoices from "./pages/clients/invoices/ClientInvoices";
import ProviderHome from "./pages/providers/home/ProviderClients";
import ClientHome from "./pages/clients/home/ClientHome";
import Support from "./pages/clients/support/Support";
import NotFound from "./pages/notFound/NotFound";
import ProviderRoutes from "./components/routeGuards/ProviderRoutes";
import ClientRoutes from "./components/routeGuards/ClientRoutes";
import axiosInit from "./utils/axios";
import RoleRoutes from "./components/routeGuards/RoleRoutes";
import SignUp from "./pages/signUp/SignUp";
import LogIn from "./pages/login/Login";
import ClientDocuments from "./pages/clients/documents/ClientDocuments";
import Addons from "./pages/clients/addons/Addons";
import Documents from "./pages/providers/documents/Documents";
import ProviderClients from "./pages/providers/home/ProviderClients";
import ClientInvoiceDetail from "./pages/clients/invoices/ClientInvoiceDetail";
import PasswordReset from "./pages/login/PasswordReset";

function App() {
  axiosInit();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="signUp" element={<SignUp />} />
        <Route path="login" element={<LogIn />} />
        <Route path="reset/:token" element={<PasswordReset />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route
            path="provider"
            element={
              <ProviderRoutes>
                <Provider />
              </ProviderRoutes>
            }
          >
            <Route index element={<ProviderClients />} />
            <Route path="home" element={<ProviderClients />} />
            <Route path="clients" element={<ProviderClients />} />
            <Route path="clients/:id" element={<ProviderClients />} />
            <Route path="invoices/:id" element={<ClientInvoiceDetail />} />
            <Route path="invoices" element={
              <RoleRoutes rolesAllowed={["not-fully-implemented-yet"]}>
                <AgentInvoices />
              </RoleRoutes>
            } />
            <Route path="Documents" element={
              <RoleRoutes rolesAllowed={["not-fully-implemented-yet"]}>
                <Documents />
              </RoleRoutes>
            } />
          </Route>
          <Route
            path="client"
            element={
              <ClientRoutes>
                <Client />
              </ClientRoutes>
            }
          >
            <Route index element={<ClientHome />} />
            <Route path="home" element={<ClientHome />} />

            {/* <Route path="invoices" element={
              <RoleRoutes rolesAllowed={["not-fully-implemented-yet"]}>
                <ClientInvoices />
              </RoleRoutes>
            } /> */}
            <Route path="documents" element={<ClientDocuments />} />
            <Route path="addons" element={<Addons />} />
            <Route path="invoices" element={<ClientInvoices />} />
            <Route path="invoices/:id" element={<ClientInvoiceDetail />} />
            <Route path="support" element={<Support />} />
          </Route>
          <Route path="*" element={<NotFound />} />
          <Route path="error" element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;
