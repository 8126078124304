import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface ICityProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
}

const City = (cityProps :ICityProps) => {
    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const error = cityProps.signUpErrors.differentBillingAddressError.city.getValidationErrors(e.target.value);
            cityProps.setSignUpErrors(prevState => ({
                ...prevState,
                differentBillingAddressError: {
                    ...prevState.differentBillingAddressError,
                    city: {
                        ...prevState.differentBillingAddressError.city,
                        message: error
                    }
                }
            }));
    }
    const setError = (error: string) => {
        cityProps.setSignUpErrors(prevState => ({
            ...prevState,
            differentBillingAddressError: {
                ...prevState.differentBillingAddressError,
                city: {
                    ...prevState.differentBillingAddressError.city,
                    message: error
                }
            }
        }));
    }
    return (
        <div className="mb-3 city">
            <input 
                value={cityProps.newClientRegistration.differentBillingAddress.city}
                type="text"
                placeholder="City"
                className={`form-control ${cityProps.signUpErrors.differentBillingAddressError.city.message ? 'input-error' : ''}`} 
                maxLength={50}
                onBlur={handleOnBlur}
                onChange={(e) => {
                    cityProps.setNewClientRegistration({
                        ...cityProps.newClientRegistration,
                        differentBillingAddress: {
                            ...cityProps.newClientRegistration.differentBillingAddress,
                            city: e.target.value
                    }
                });
                setError("");
            }}/> 
            <p className="errorMessage">{cityProps.signUpErrors.differentBillingAddressError.city.message} </p>     
        </div>
    );
};
export default City;