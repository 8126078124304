const creditCardFieldConfig = {
  selector: "#my_credit_card_field_id",
  input: {
    type: "credit_card_number",
    placeholder: "Credit Card",
    css: {
      "::placeholder": {
        color: 'light-gray',
      },
      height: '20px'
    },
  },
};

const cvvFieldConfig = {
  selector: "#my_cvv_field_id",
  input: {
    type: "cvv",
    placeholder: "CVV",
    css: {
      "::placeholder": {
        color: 'light-gray',
      },
      height: '20px',
    },
  },
};

 const hostedFieldsConfiguration = {
    publicKey: "m_DTk6G7i-QAyBx9dcTNg2TQ", 
    fields: [
      creditCardFieldConfig,
      cvvFieldConfig,
    ]
  }

export function initAffiniPay( setHostedFields, hostedFieldsCallBack ) {
  const fieldsInstance = window.AffiniPay.HostedFields.initializeFields(
    hostedFieldsConfiguration,
    hostedFieldsCallBack
  );
  setHostedFields(fieldsInstance);
}
