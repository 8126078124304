import { useEffect, useState } from "react";
import { _getServicesByCategory } from "../../../APIs/servicesCategory/ServicesCategory";
import { IService } from "../../../models/service/IService";
import "./addons.scss"
import PackagesCards from "../../../components/clients/addon/PackagesCards";
import ServicesCards from "../../../components/clients/addon/ServicesCards";
import PaymentCards from "../../../components/clients/addon/paymentCards/PaymentCards";
import { useSearchParams } from "react-router-dom";

const Addons = () => {
  const ourAddonServicesCategory = "ADDON" 
  const [services, setServices] = useState<IService[]>([]); 
  const [selectedCardIds, setSelectedCardIds] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedPackagesCardIds, setSelectedPackagesCardIds] = useState<{serviceId:number,packageId:number}[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get('type');
   
  useEffect(() => {
    initAddonsServices()  
  }, []);

  const initAddonsServices = async () => {  
      let services = await _getServicesByCategory(ourAddonServicesCategory);
      setServices(services);

      if(type && type?.length>0){
        if(type=='doc'){
          let service = services.filter(a=>a.name == "Buy Documents")[0];
          setSelectedCardIds([service.id]);
          setCurrentPage(1);
        }
      }
  };

  const handleCardClick = (serviceId: number) => {
    const isSelected = selectedCardIds.includes(serviceId);
    if (isSelected) {
      setSelectedCardIds(selectedCardIds.filter(id => id !== serviceId));
    } else {
      setSelectedCardIds([...selectedCardIds, serviceId]);
    }
  };

function handleNextClick(step: string) {
  if (step === "next") {
    setCurrentPage(currentPage + 1);
  } else if (step === "back") {
    if (currentPage - 1 == 0) {
      setSelectedPackagesCardIds([]);
    }
    setCurrentPage(currentPage - 1);
  }
} 

    return (
      <div className="client-addons">
        <div className="client-addons__body basic-scroll ">
          {currentPage === 0 ? 
             services
             .map((service)=>   
               <ServicesCards  
                 key={service.id}
                 services={service}
                 selectedCardIds={selectedCardIds} 
                 handleCardClick={handleCardClick}
               />
             )
            :currentPage === 1 ?
              services.filter((service) => selectedCardIds.includes(service.id))
              .map((selectedService, index)=> 
                  <PackagesCards 
                    key={index} 
                    selectedService={selectedService}
                    setSelectedPackagesCardIds={setSelectedPackagesCardIds} 
                    selectedPackagesCardIds={selectedPackagesCardIds} 
                  />
              )            
            :
              <PaymentCards selectedPackagesCardIds={selectedPackagesCardIds} services={services}/>    
          }
        </div>
        <div className="client-addons__footer">
          <button 
            className={`primaryButton  w-8rem h-37  ${currentPage===2? "visibilityHidden":""}`} 
            disabled={
              currentPage===2 ||
              currentPage===0 && selectedCardIds.length===0 ||
              currentPage===1 && selectedPackagesCardIds.length !== selectedCardIds.length} 
            onClick={()=>handleNextClick("next")}>Next
          </button>  
          {!(type && type?.length>0 && currentPage===1) && <button 
            className={`primaryButton w-8rem h-37  ${currentPage===0? "visibilityHidden":""}`}
            onClick={()=>handleNextClick("back")}>Back
          </button>  }  
             
        </div>
      </div>
        
    );
};

export default Addons;