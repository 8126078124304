import INewClientRegistration from "../../../../models/client/newRegisterAgent/INewClientRegistration";
import { ISignUpErrors } from "../../validation/ISignUpErrors";

interface StateProvinceTextBoxProps {
    newClientRegistration:INewClientRegistration
    setNewClientRegistration:React.Dispatch<React.SetStateAction<INewClientRegistration>>
    signUpErrors: ISignUpErrors;
    setSignUpErrors: React.Dispatch<React.SetStateAction<ISignUpErrors>>;
    setStateProvinceTextBoxError: (error: string) => void
}

const StateProvinceTextBox = (stateProvinceTextBoxProps: StateProvinceTextBoxProps) => {
    const maxLength = 50;
    const updateError = (value: string) => {
        const error = stateProvinceTextBoxProps.signUpErrors.contactDetailsErrors.stateProvince.getValidationErrors(value);
        stateProvinceTextBoxProps.setStateProvinceTextBoxError(error);
    }



    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        updateError(e.target.value);
    }
    
    const handleOnChange = (e: React.FocusEvent<HTMLInputElement>) => {
            if(e.target.value.length<=maxLength){
                stateProvinceTextBoxProps.setNewClientRegistration({
                    ...stateProvinceTextBoxProps.newClientRegistration,
                    contactDetails: {
                        ...stateProvinceTextBoxProps.newClientRegistration.contactDetails,
                        stateProvince: e.target.value
                    }
                });
                stateProvinceTextBoxProps.setStateProvinceTextBoxError('');
            }
    }
    
    return (
        <div className="mb-3 twoInputInOneRow-input">
            <input 
                value={stateProvinceTextBoxProps.newClientRegistration.contactDetails.stateProvince}
                type="text"
                className={`form-control ${stateProvinceTextBoxProps.signUpErrors.contactDetailsErrors.stateProvince.message ? 'input-error' : ''}`} 
                placeholder="State / Province"
                maxLength={maxLength}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
            />  
            <p className="errorMessage">{stateProvinceTextBoxProps.signUpErrors.contactDetailsErrors.stateProvince.message} </p> 
        </div>
    );
};
export default StateProvinceTextBox;